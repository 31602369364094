import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import * as actionTypes from "./actionTypes";

const initGetProducts = () => {
  return {
    type: actionTypes.INIT_GET_PRODUCTS,
    loading: true,
  };
};

const successGetProducts = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCTS,
    loading: false,
    productData: data.product.data,
    shopData: data.shop.data,
    pagination: {
      pages_count: data.last_page,
      page: data.current_page,
    },
  };
};

const failGetProducts = (error) => {
  return {
    type: actionTypes.FAIL_GET_PRODUCTS,
    loading: false,
    error: error,
  };
};

export const getProducts = () => {
  return (dispatch, getState) => {
    const { products } = getState();
    const { sorting, search, page } = products;
    const url = `search?search=${search ?? ""}&sorting=${sorting}&page=${page}`;
    //console.log(url);
    dispatch(initGetProducts());
    defaultAxios
      .get(url)
      .then((response) => dispatch(successGetProducts(response.data.data)))
      .catch((err) => catchError(err));
  };
};

//add param
export const addParam = (paramName, currentCategory, currentSubCategory, value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_PRODUCTS_PARAM,
      key: paramName,
      value: value,
    });
    if (currentCategory === null) {
      dispatch(getProducts());
    } else if (currentSubCategory !== null) {
      dispatch(getProductsBySubCategory(currentSubCategory));
    } else {
      dispatch(getProductsByCategory(currentCategory));
    }
  };
};

//products by category:
export const getProductsByCategory = (id) => {
  return (dispatch, getState) => {
    const { products } = getState();
    const { sorting, search, page } = products;
    const url = `search?search=${search ?? ""}&sorting=${sorting}&page=${page}&category_id=${id}`;
    //console.log(url);
    dispatch(initGetProducts());
    defaultAxios
      .get(url)
      .then((response) => dispatch(successGetProducts(response.data.data)))
      .catch((err) => catchError(err));
  };
};

export const getProductsBySubCategory = (id) => {
  return (dispatch, getState) => {
    const { products } = getState();
    const { sorting, search, page } = products;
    const url = `search?search=${
      search ?? ""
    }&sorting=${sorting}&page=${page}&sub_category_id=${id}`;
    //console.log(url);
    dispatch(initGetProducts());
    defaultAxios
      .get(url)
      .then((response) => dispatch(successGetProducts(response.data.data)))
      .catch((err) => catchError(err));
  };
};
//Single product:

const initGetProduct = () => {
  return {
    type: actionTypes.INIT_GET_PRODUCT,
    loading_product: false,
  };
};

const successGetProduct = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_PRODUCT,
    loading_product: true,
    product: data,
  };
};

const failGetProduct = (error) => {
  return {
    type: actionTypes.FAIL_GET_PRODUCT,
    loading_product: true,
    error_product: error,
  };
};

export const getProduct = (id) => {
  return (dispatch) => {
    dispatch(initGetProduct());
    defaultAxios
      .get("product/view/" + id)
      .then((response) => dispatch(successGetProduct(response.data.data)))
      .catch((err) => catchError(err));
  };
};
