import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";

import { editPreloved, deletePreloved } from "../../redux/actions/index";

export default function PrelovedModal({
  show,
  onHide,
  modalConfig,
  dispatch,
  addToast,
}) {
  const { mode } = modalConfig;

  const formik = useFormik({
    initialValues: {
      status: modalConfig.status === 1 ? true : false,
      lock_item: modalConfig.lock_item === 1 ? true : false,
      price: modalConfig.price,
    },
    validationSchema: Yup.object({
      status: Yup.boolean(),
      lock_item: Yup.boolean(),
      price: Yup.number().required().positive(),
    }),
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      const data = {
        ...values,
        id: modalConfig.id,
      };
      dispatch(editPreloved(data, addToast));
      onHide();
    },
  });

  let title = "Edit",
    centered = false,
    body = formik ? (
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Status"
              defaultChecked={formik.values.status}
              value={formik.values.status}
              {...formik.getFieldProps("status")}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Check
              type="switch"
              id="custom-switch-lock-item"
              label="Item locked"
              defaultChecked={formik.values.lock_item}
              value={formik.values.lock_item}
              {...formik.getFieldProps("lock_item")}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Label>
              Price <span style={{ color: "red" }}>*</span>
            </Form.Label>
            <Form.Control
              type="number"
              name="price"
              value={formik.values.price}
              isValid={formik.touched.price && !formik.errors.price}
              isInvalid={formik.touched.price && formik.errors.price}
              {...formik.getFieldProps("price")}
            />
            <Form.Control.Feedback type="invalid">
              price required
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
      </Form>
    ) : null;

  if (mode === "delete") {
    title = "Delete";
    body = <p>Confirm Delete product? </p>;
    centered = true;
  }
  return mode ? (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered={centered}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title} Product
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onHide()} variant="outline-success">
          Close
        </Button>
        <Button
          variant={mode === "delete" ? "danger" : "success"}
          onClick={() => {
            if (mode === "edit") {
              formik.handleSubmit();
            } else if (mode === "delete") {
              dispatch(deletePreloved(modalConfig.id));
              onHide();
            }
          }}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}
