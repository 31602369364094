import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAUcz9VvtnWH1mpdj_E0fvPTejuPKFdQKs",
  authDomain: "pcari-71145.firebaseapp.com",
  databaseURL: "https://pcari-71145.firebaseio.com",
  projectId: "pcari-71145",
  storageBucket: "pcari-71145.appspot.com",
  messagingSenderId: "568647603184",
  appId: "1:568647603184:web:867515724f317ed52e4335",
  measurementId: "G-JRJF59FSKJ",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const app = firebase.app();
const auth = firebase.auth();
const db = firebase.firestore();
const now = firebase.firestore.Timestamp.now();
const storage = firebase.storage();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const twitterProvider = new firebase.auth.TwitterAuthProvider();
const windowsProvider = new firebase.auth.OAuthProvider("microsoft.com");
const emailProvider = new firebase.auth.EmailAuthProvider();
const phoneProvider = new firebase.auth.PhoneAuthProvider();

const createCredential = (email, password) => {
  return firebase.auth.EmailAuthProvider.credential(email, password);
};

const createPhoneCredential = (verificationId, verificationCode) => {
  return firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
};

const checkLinkedWithEmail = async (email) => {
  const signInMethods = await auth.fetchSignInMethodsForEmail(email);

  if (signInMethods.length > 0 && !signInMethods.includes("password")) {
    return false;
  } else if (signInMethods.length == 0) {
    return false;
  } else {
    return true;
  }
};

export {
  auth,
  db,
  now,
  storage,
  googleProvider,
  facebookProvider,
  twitterProvider,
  windowsProvider,
  emailProvider,
  phoneProvider,
  createCredential,
  createPhoneCredential,
  checkLinkedWithEmail,
};
