import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Transition } from "react-transition-group";

import { getShopProducts } from "../../redux/actions";

const Sidebar = ({ categories, setProductList, vendorId }) => {
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(false);
  const duration = 500;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <div className="sidebar">
      <div className="widget">
        <h5 className="widget__title">Categories</h5>
        {categories.length > 0 ? (
          <ul className="widget__categories">
            <li>
              <button
                onClick={(e) => {
                  setProductList("all");
                }}
              >
                {/* <IoIosArrowForward /> */}
                <span className="categories-name">All</span>
                {/* <span className="categories-num">({countItems(products)})</span> */}
              </button>
            </li>
            {categories &&
              categories.map((category, key) => {
                return key < 6 ? (
                  <li key={key}>
                    <button
                      onClick={(e) => {
                        if (vendorId) {
                          setProductList(category.id.toString());
                          dispatch(getShopProducts(vendorId, category.id));
                        }
                      }}
                    >
                      {/* <IoIosArrowForward /> */}
                      <span className="categories-name">{category.name}</span>
                      {/* <span className="categories-num">({category.id})</span> */}
                    </button>
                  </li>
                ) : (
                  <Transition
                    in={expand}
                    timeout={duration}
                    key={key}
                    unmountOnExit
                  >
                    {(state) => (
                      <li
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state],
                        }}
                      >
                        <button
                        // onClick={(e) => {
                        //   dispatch(getProductsByCategory(category.id))
                        // }}
                        >
                          {/* <IoIosArrowForward /> */}
                          <span className="categories-name">
                            {category.name}
                          </span>
                          {/* <span className="categories-num">({category.id})</span> */}
                        </button>
                      </li>
                    )}
                  </Transition>
                );
              })}
            {categories.length > 5 && (
              <Button
                className="text-white"
                size="sm"
                onClick={() => setExpand(!expand)}
              >
                {!expand ? "more" : "less"}
              </Button>
            )}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

export default Sidebar;
