import { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { Modal, Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { getProductCartQuantity } from "../../../lib/product";
import { ProductRating } from "../../Product";
import { BsShield } from "react-icons/bs";
import { AiOutlineReload } from "react-icons/ai";
import { GiSwapBag } from "react-icons/gi";
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoGoogleplus,
  IoLogoYoutube,
  IoLogoInstagram,
} from "react-icons/io";

import ProductVariant from "./ProductVariants";
import { buyPreloved } from "../../../redux/actions/index";

const ProductModal = (props) => {
  const {
    product,
    discountedPrice,
    productPrice,
    cartitems,
    wishlistitem,
    compareitem,
    addtocart,
    addtowishlist,
    deletefromwishlist,
    addtocompare,
    deletefromcompare,
    addtoast,
    addCartItem,
    user,
  } = props;

  const router = useRouter();

  const dispatch = useDispatch();

  const [selectedProductColor, setSelectedProductColor] = useState(
    product.variation ? product.variation[0].color : ""
  );
  const [selectedProductSize, setSelectedProductSize] = useState(
    product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(
    product.variation ? product.variation[0].size[0].stock : product.stock
  );
  const [quantityCount, setQuantityCount] = useState(1);

  const [configurableId, setConfigurableId] = useState(null);

  const productCartQty = getProductCartQuantity(
    cartitems,
    product,
    selectedProductColor,
    selectedProductSize
  );

  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  const inCart = cartitems.filter((item) => item.product_id === product.id).length > 0;

  //

  const [attributeIds, setAttributeIds] = useState(null);

  useEffect(() => {
    if (product.type === "configurable" && attributeIds) {
      const getStockIds = () => {
        const attributeIdsArr = [];

        Object.values(attributeIds).forEach((val) => attributeIdsArr.push(val));

        let result = {};

        product.configurable.map((varaint) => {
          const varAttriIds = [];

          varaint.attribute.forEach((varAttri) => varAttriIds.push(varAttri.attribute_id));

          const filteredArray = attributeIdsArr.filter((value) => varAttriIds.includes(value));

          if (filteredArray.length === attributeIdsArr.length)
            result = {
              product_configurable_id: varaint.id,
              product_id: product.id,
              stock: varaint.stock,
            };
        });
        return result;
      };
      const { product_configurable_id, stock, product_id } = getStockIds();
      //console.log(product_configurable_id, stock, product_id);

      setProductStock(stock);
      setQuantityCount(1);
      setConfigurableId(product_configurable_id);
    }
  }, [attributeIds]);

  // effect for swiper slider synchronize
  useEffect(() => {
    if (
      gallerySwiper !== null &&
      gallerySwiper.controller &&
      thumbnailSwiper !== null &&
      thumbnailSwiper.controller
    ) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  // swiper slider settings
  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: 4,
    loop: true,
    effect: "fade",
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: 4,
    loopedSlides: 4,
    touchRatio: 0.2,
    freeMode: true,
    loop: true,
    slideToClickedSlide: true,
  };

  return (
    <Modal show={props.show} onHide={props.onHide} className="product-quickview" centered>
      <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
          <Col lg={6}>
            <div className="product-quickview__large-image-wrapper">
              <Swiper {...gallerySwiperParams}>
                {product.image &&
                  product.image.map((single, key) => {
                    return (
                      <div key={key}>
                        <div className="single-image">
                          <img src={single.path} className="img-fluid" alt="" />
                        </div>
                      </div>
                    );
                  })}
              </Swiper>
            </div>
            <div className="product-quickview__small-image-wrapper">
              <Swiper {...thumbnailSwiperParams}>
                {product.image &&
                  product.image.map((image, i) => {
                    return (
                      <div key={i}>
                        <div className="single-image">
                          <img src={image.path} className="img-fluid" alt="" />
                        </div>
                      </div>
                    );
                  })}
              </Swiper>
            </div>
          </Col>
          <Col lg={6}>
            <div className="product-quickview__content">
              <h2 className="product-quickview__title space-mb--10">{product.name}</h2>
              <div className="product-quickview__price-rating-wrapper space-mb--10">
                <div className="product-quickview__price d-flex-align-items-center">
                  {product.discount ? (
                    <Fragment>
                      <span className="price">RM {discountedPrice}</span>
                      <del>RM {productPrice}</del>
                      <span className="on-sale">{product.discount}% Off</span>
                    </Fragment>
                  ) : (
                    <span className="price">RM {productPrice}</span>
                  )}
                </div>
                {product.rating && product.rating > 0 ? (
                  <div className="product-quickview__rating-wrap">
                    <div className="product-quickview__rating">
                      <ProductRating ratingValue={product.rating} />
                      <span>({product.rating})</span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="product-quickview__description space-mb--20">
                <p
                  dangerouslySetInnerHTML={{
                    __html: product.short_description,
                  }}
                />
              </div>

              <div className="product-quickview__sort-info space-mb--20">
                <ul>
                  <li>
                    <BsShield /> 1 Year Brand Warranty
                  </li>
                  <li>
                    <AiOutlineReload /> 30 Days Return Policy
                  </li>
                  <li>
                    <GiSwapBag /> Cash on Delivery available
                  </li>
                </ul>
              </div>

              {product.type === "configurable" && product.attribute_group && (
                <ProductVariant
                  product={product}
                  attributeIds={attributeIds}
                  setAttributeIds={setAttributeIds}
                />
              )}

              <hr />
              {product.affiliateLink ? (
                <div className="product-quickview__quality">
                  <div className="product-quickview__cart btn-hover">
                    <a
                      href={product.affiliateLink}
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-fill-out btn-addtocart">
                      Buy Now
                    </a>
                  </div>
                </div>
              ) : product.preloved ? (
                <div className="product-quickview__quality">
                  <div className="product-quickview__cart btn-hover">
                    <a
                      className="btn btn-fill-out btn-addtocart"
                      onClick={(event) => {
                        event.preventDefault();
                        const data = {
                          amount: +product.price,
                          preloved_orders_id: product.id,
                          seller_id: product.customer_id,
                        };
                        dispatch(
                          buyPreloved((to) => {
                            router.push(to);
                          }, data)
                        );
                      }}>
                      Buy Now
                    </a>
                  </div>
                </div>
              ) : product.type === "configurable" && !product.attribute_group ? (
                <div className="product-quickview__quality">
                  <div className="product-quickview__cart btn-hover">
                    <a
                      className="btn btn-fill-out btn-addtocart"
                      onClick={(event) => {
                        event.preventDefault();
                        router.push(`shop/product/${product.id}`);
                      }}>
                      Product details
                    </a>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className="product-quickview__button-wrapper d-flex align-items-center">
                    <div className="product-quickview__quantity">
                      <div className="cart-plus-minus">
                        <button
                          onClick={() =>
                            setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
                          }
                          className="qtybutton">
                          -
                        </button>
                        <input
                          className="cart-plus-minus-box"
                          type="text"
                          value={quantityCount}
                          readOnly
                        />
                        <button
                          onClick={() =>
                            setQuantityCount(
                              quantityCount < productStock - productCartQty
                                ? quantityCount + 1
                                : quantityCount
                            )
                          }
                          className="qtybutton">
                          +
                        </button>
                      </div>
                    </div>
                    {productStock && productStock > 0 && product.type === "simple" ? (
                      <button
                        onClick={() => {
                          if (!user) {
                            return router.replace("/user/login");
                          }
                          const data = {
                            product_id: product.id,

                            quantity: quantityCount,
                          };

                          if (inCart) {
                            const productCart = cartitems.filter(
                              (item) => item.product_id === product.id
                            )[0];

                            data.quantity = quantityCount + productCart.quantity;
                          }

                          addCartItem(data, addtoast);
                        }}
                        className={`btn btn-fill-out btn-addtocart ${inCart ? "active" : ""}`}
                        disabled={productCartQty >= productStock}>
                        <i className="icon-basket-loaded" /> Add To Cart
                      </button>
                    ) : //productStock && productStock > 0 &&
                    product.type === "configurable" ? (
                      <button
                        className="btn btn-fill-out btn-addtocart"
                        onClick={() => {
                          if (!user) {
                            return router.replace("/user/login");
                          }
                          const data = {
                            product_configurable_id: configurableId,
                            product_id: product.id,

                            quantity: quantityCount,
                          };
                          if (inCart) {
                            const productCart = cartitems.filter(
                              (item) => item.product_id === product.id
                            )[0];
                            if (configurableId === productCart.product_configurable_id) {
                              data.quantity = quantityCount + productCart.quantity;
                            } else {
                              addCartItem({
                                product_id: productCart.product_id,
                                product_configurable_id: productCart.product_configurable_id,
                                vendor_id: productCart.vendor_id,
                                quantity: 0,
                              });
                            }
                            addCartItem(data, addtoast);
                          } else addCartItem(data, addtoast);

                          props.onHide(true);
                        }}>
                        Add to Cart
                      </button>
                    ) : (
                      <button disabled className="btn btn-fill-out btn-addtocart">
                        Out of stock
                      </button>
                    )}

                    <button
                      className={`product-quickview__compare ${
                        compareitem !== undefined ? "active" : ""
                      }`}
                      title={compareitem !== undefined ? "Added to compare" : "Add to compare"}
                      onClick={
                        compareitem !== undefined
                          ? () => deletefromcompare(product, addtoast)
                          : () => addtocompare(product, addtoast)
                      }>
                      <i className="icon-shuffle" />
                    </button>

                    <button
                      className={`product-quickview__wishlist ${
                        wishlistitem !== undefined ? "active" : ""
                      }`}
                      title={wishlistitem !== undefined ? "Added to wishlist" : "Add to wishlist"}
                      onClick={
                        wishlistitem !== undefined
                          ? () => deletefromwishlist(wishlistitem.id, addtoast)
                          : () => addtowishlist(product.id, addtoast)
                      }>
                      <i className="icon-heart" />
                    </button>
                  </div>
                </Fragment>
              )}
              <hr />
              <ul className="product-quickview__product-meta">
                {/* <li>
                  SKU: <span>{product.sku}</span>
                </li> */}
                {/* <li>
                  Category:
                  {product.category &&
                    product.category.map((item, index, arr) => {
                      return (
                        <Link
                          href="/shop/grid-left-sidebar"
                          as={"/shop/grid-left-sidebar"}
                          key={index}
                        >
                          <a>{item + (index !== arr.length - 1 ? ", " : "")}</a>
                        </Link>
                      );
                    })}
                </li>
                <li>
                  Tags:
                  {product.tag &&
                    product.tag.map((item, index, arr) => {
                      return (
                        <Link
                          href="/shop/grid-left-sidebar"
                          as={"/shop/grid-left-sidebar"}
                          key={index}
                        >
                          <a>{item + (index !== arr.length - 1 ? ", " : "")}</a>
                        </Link>
                      );
                    })}
                </li> */}
              </ul>
              <div className="product-quickview__product-share space-mt--15">
                <span>Share:</span>
                <ul className="social-icons">
                  <li>
                    <a href="#">
                      <IoLogoFacebook />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IoLogoTwitter />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IoLogoGoogleplus />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IoLogoYoutube />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <IoLogoInstagram />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ProductModal;
