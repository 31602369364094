import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Transition } from "react-transition-group";
import { getCategories, getProducts } from "../../redux/actions";
import { useRouter } from "next/router";
import { FaChevronDown, FaChevronLeft, FaChevronRight, FaChevronUp } from "react-icons/fa";
import { useTranslation } from "next-i18next";

const Sidebar = ({
  products,
  getSortParams,
  setGridType,
  setCategoryId,
  categoryId,
  subCategoryId,
  setSubCategoryId,
}) => {
  const categories = useSelector((state) => state.categoryData.data);
  const router = useRouter();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(router.query.id < 6 ? false : true);
  const [selectedCategory, setSelectedCategory] = useState(categoryId ?? null);
  const [selectedExpandCategory, setSelectedExpandCategory] = useState(categoryId ?? null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(subCategoryId ?? null);
  const { t } = useTranslation("common");
  const duration = 500;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };
  useEffect(() => {
    if (categories.length === 0) dispatch(getCategories());
  }, [categories]);

  useEffect(() => {
    if (router.isReady) {
      if (router.query.id) {
        setSelectedCategory(router.query.id);
      } else {
        setSelectedCategory(null);
      }
    }
  }, [router.isReady]);

  return (
    <div className="sidebar">
      <div className="widget">
        <h5 className="widget__title">{t("Categories")}</h5>
        {categories.length > 0 ? (
          <ul className="widget__categories">
            <li>
              <button
                onClick={(e) => {
                  setSelectedCategory(null);
                  setGridType("all");
                  setCategoryId(null);
                  dispatch(getProducts());
                  router.replace("/shop/products");
                }}>
                <span
                  className={`categories-name ${
                    selectedCategory === null ? "text-primary fw-bold" : ""
                  }`}>
                  {t("All")}
                </span>
              </button>
            </li>
            {categories &&
              categories.map((category, key) => {
                return key < 6 ? (
                  <li key={key}>
                    <div className="d-flex">
                      <button
                        onClick={(e) => {
                          setSelectedCategory(category.id);
                          setGridType("category");
                          setCategoryId(category.id);
                        }}>
                        <span
                          className={`categories-name ${
                            category.id === parseInt(selectedCategory) ? "text-primary fw-bold" : ""
                          }`}>
                          {t(category.name)}
                        </span>
                      </button>
                      {category.sub_category &&
                        (category.id === selectedExpandCategory ? (
                          <FaChevronUp
                            style={{ cursor: "pointer" }}
                            className={`categories-name ${
                              category.id === parseInt(selectedExpandCategory)
                                ? "text-primary fw-bold"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedExpandCategory(null);
                              setSubCategoryId(null);
                            }}
                          />
                        ) : (
                          <FaChevronDown
                            style={{ cursor: "pointer" }}
                            className={`categories-name ${
                              category.id === parseInt(selectedExpandCategory)
                                ? "text-primary fw-bold"
                                : ""
                            }`}
                            onClick={() => {
                              setSelectedExpandCategory(category.id);
                            }}
                          />
                        ))}
                    </div>

                    {category.sub_category && (
                      <Transition
                        in={category.id === selectedExpandCategory}
                        timeout={duration}
                        unmountOnExit>
                        {(subState) => (
                          <ul
                            style={{
                              ...defaultStyle,
                              ...transitionStyles[subState],
                            }}
                            className="mt-2">
                            {category.sub_category.map((sub, i) => (
                              <li key={i}>
                                <button
                                  onClick={(e) => {
                                    setSelectedSubCategory(sub.id);
                                    setGridType("sub-category");
                                    setSubCategoryId(sub.id);
                                  }}>
                                  <span
                                    className={`categories-name ${
                                      sub.id === parseInt(selectedSubCategory)
                                        ? "text-primary fw-bold"
                                        : ""
                                    } ms-3`}>
                                    {t(sub.name)}
                                  </span>
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </Transition>
                    )}
                  </li>
                ) : (
                  <Transition in={expand} timeout={duration} key={key} unmountOnExit>
                    {(state) => (
                      <li
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state],
                        }}>
                        <div className="d-flex">
                          <button
                            onClick={(e) => {
                              setSelectedCategory(category.id);
                              setGridType("category");
                              setCategoryId(category.id);
                            }}>
                            <span
                              className={`categories-name ${
                                category.id === parseInt(selectedCategory)
                                  ? "text-primary fw-bold"
                                  : ""
                              }`}>
                              {t(category.name)}
                            </span>
                          </button>
                          {category.sub_category &&
                            (category.id === selectedExpandCategory ? (
                              <FaChevronUp
                                style={{ cursor: "pointer" }}
                                className={`categories-name ${
                                  category.id === parseInt(selectedExpandCategory)
                                    ? "text-primary fw-bold"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedExpandCategory(null);
                                  setSubCategoryId(null);
                                }}
                              />
                            ) : (
                              <FaChevronDown
                                style={{ cursor: "pointer" }}
                                className={`categories-name ${
                                  category.id === parseInt(selectedExpandCategory)
                                    ? "text-primary fw-bold"
                                    : ""
                                }`}
                                onClick={() => {
                                  setSelectedExpandCategory(category.id);
                                }}
                              />
                            ))}
                        </div>

                        {category.sub_category && (
                          <Transition
                            in={category.id === selectedExpandCategory}
                            timeout={duration}
                            unmountOnExit>
                            {(subState) => (
                              <ul
                                style={{
                                  ...defaultStyle,
                                  ...transitionStyles[subState],
                                }}
                                className="mt-2">
                                {category.sub_category.map((sub, i) => (
                                  <li key={i}>
                                    <button
                                      onClick={(e) => {
                                        setSelectedSubCategory(sub.id);
                                        setGridType("sub-category");
                                        setSubCategoryId(sub.id);
                                      }}>
                                      <span
                                        className={`categories-name ${
                                          sub.id === parseInt(selectedSubCategory)
                                            ? "text-primary fw-bold"
                                            : ""
                                        } ms-3`}>
                                        {t(sub.name)}
                                      </span>
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </Transition>
                        )}
                      </li>
                    )}
                  </Transition>
                );
              })}
            <Button className="text-white" size="sm" onClick={() => setExpand(!expand)}>
              {!expand ? t("more") : t("less")}
            </Button>
          </ul>
        ) : (
          t("No categories found")
        )}
      </div>
    </div>
  );
};

export default Sidebar;
