import { getToken } from "../utils";
import * as actionTypes from "../actionTypes";
import { defaultAxios } from "../../../utils";
import { catchError, getTranslation } from "../../../utils/helpers";
import axios from "axios";

const initGetOrder = () => {
  return {
    type: actionTypes.INIT_GET_ORDERS,
    orders_loading: true,
    orders_data: [],
    orders_error: null,
  };
};

const succesGetOrder = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_ORDERS,
    orders_loading: false,
    orders_data: data,
    orders_error: null,
  };
};

const failGetOrder = (error) => {
  return {
    type: actionTypes.FAIL_GET_ORDERS,
    orders_loading: false,
    orders_error: error,
  };
};

const initGetOrderDetail = () => {
  return {
    type: actionTypes.INIT_GET_ORDER_DETAIL,
    orderDetailLoading: true,
    orderDetail: null,
    orderDetailError: null,
  };
};

const successGetOrderDetail = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_ORDER_DETAIL,
    orderDetailLoading: false,
    orderDetail: data,
    orderDetailError: null,
  };
};

const failGetOrderDetail = (error) => {
  return {
    type: actionTypes.FAIL_GET_ORDER_DETAIL,
    orderDetailLoading: false,
    orderDetailError: error,
  };
};

export const getOrders = (status = "pay") => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetOrder());

    if (status == "pay") {
      defaultAxios
        .get(`sales/invoice`, config)
        .then((response) => {
          //console.log(response.data.data);
          dispatch(succesGetOrder(response.data.data));
        })
        .catch((err) => {
          catchError(err);
          dispatch(failGetOrder(err.message));
        });
    } else {
      axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL.replace("v1", "v2");
      axios
        .get(`sales/orders?status=${status}&per_page=100`, config)
        .then((response) => {
          //console.log(response.data.data);
          dispatch(succesGetOrder(response.data.data.data));
        })
        .catch((err) => {
          catchError(err);
          dispatch(failGetOrder(err.message));
        });
    }
  };
};

export const getOrderDetail = (status, id) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetOrderDetail());

    defaultAxios
      .get(`sales/${status}/${id}`, config)
      .then((response) => {
        //console.log(response.data.data);
        dispatch(successGetOrderDetail(response.data.data));
      })
      .catch((err) => {
        catchError(err);
        dispatch(failGetOrderDetail(err.message));
      });
  };
};

export const getOrderDetailV2 = (id) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetOrderDetail());

    axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL.replace("v1", "v2");
    axios
      .get(`sales/orders/${id}`, config)
      .then((response) => {
        //console.log(response.data.data);
        dispatch(successGetOrderDetail(response.data.data));
      })
      .catch((err) => {
        catchError(err);
        dispatch(failGetOrderDetail(err.message));
      });
  };
};

export const postReview = (data, addToast, detailToggle, setMessageModal) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));
  return (dispatch) => {
    defaultAxios
      .post("rating", data, config)
      .then((resonse) => {
        //console.log(resonse.data);
        addToast(getTranslation("Review submitted"), {
          appearance: "success",
          autoDismiss: true,
        });
        dispatch(getOrders("complete"));
        detailToggle(undefined, "tab");
          setMessageModal({
            show: true,
            message: "Thank you for your review",
            action: () => {
              setMessageModal(false);
            },
          });
      })
      // .then(() => {
      //   const postData = {
      //     _method: "PUT",
      //     sales_order_id: data.sales_order_id,
      //   };
      //   defaultAxios
      //     .post("sales/index/update/order", postData, config)
      //     .then((response) => addToast("completed", { appearance: "success", autoDismiss: true }))
      //     .catch((err) =>
      //       addToast(err.response.data.messages, {
      //         appearance: "success",
      //         autoDismiss: true,
      //       })
      //     );
      // })
      .catch((err) => {
        catchError(err);
        addToast(err.response.data.messages, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
};

export const cancelOrder = (data, orderDetailType, addToast, detailToggle, setMessageModal) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  // UNPAID ORDER
  if (orderDetailType === "pay") {
    let invoiceData = {
      id: [],
    };

    invoiceData["id"].push(data.sales_order_id);
    config.data = invoiceData;
    return (dispatch) => {
      defaultAxios
        .delete("sales/invoice", config)
        .then((response) => {
          dispatch(getOrders(orderDetailType));
          detailToggle(undefined, "tab");
          addToast("Order Canceled", {
            appearance: "success",
            autoDismiss: "true",
          });
          setMessageModal({
            show: true,
            message: "Your order has been cancelled.Refund will be transferred in 3-7 Working Days",
            action: () => {
              setMessageModal(false);
            },
          });
        })
        .catch((err) => {
          addToast("Order Canceled", {
            appearance: "error",
            autoDismiss: "true",
          });
          catchError(err);
        });
    };
  }

  // PAID ORDER
  return (dispatch) => {
    defaultAxios
      .post("sales/index/cancel", formData, config)
      .then((response) => {
        dispatch(getOrders(orderDetailType));
        detailToggle(undefined, "tab");
        addToast("Order Canceled", {
          appearance: "success",
          autoDismiss: "true",
        });
        setMessageModal({
          show: true,
          message: "Your order has been cancelled.Refund will be transferred in 3-7 Working Days",
          action: () => {
            setMessageModal(false);
          },
        });
      })
      .catch((err) => {
        addToast("Order Canceled", {
          appearance: "error",
          autoDismiss: "true",
        });
        catchError(err);
      });
  };
};
export const refundOrder = (data, orderDetailType, addToast, detailToggle, setMessageModal) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  return (dispatch) => {
    defaultAxios
      .post("sales/index/refund", formData, config)
      .then((response) => {
        dispatch(getOrders(orderDetailType));
        detailToggle(undefined, "tab");
        addToast("Refund Requested", {
          appearance: "success",
          autoDismiss: "true",
        });
        setMessageModal({
          show: true,
          message:
            "You may raise a return/refund request directly with our customer support via email",
          action: () => {
            setMessageModal(false);
          },
        });
      })
      .catch((err) => {
        addToast("Refund Requested", {
          appearance: "error",
          autoDismiss: "true",
        });
        catchError(err);
      });
  };
};
