import { getToken } from "./utils";
import * as actionTypes from "./actionTypes";
import { strapiAxios } from "../../utils";
import { catchError } from "../../utils/helpers";

const initGetTermConditions = () => {
  return {
    type: actionTypes.INIT_GET_TERM_CONDITIONS,
    loading: true,
  };
};

const successGetTermConditions = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_TERM_CONDITIONS,
    loading: false,
    termConditions: data,
  };
};

const failGetTermConditions = (error) => {
  return {
    type: actionTypes.FAIL_GET_TERM_CONDITIONS,
    loading: false,
    error: error,
  };
};

export const getTermConditions = () => {
  return (dispatch) => {
    dispatch(initGetTermConditions());

    strapiAxios
      .post("/graphql", {
        query: `query {
          term_conditions : termConditions(locale : "${global.localStorage?.lang == "ms" ? "ms-MY" : "en"}") {
            id
            title
            content
            created_at
            updated_at
          }
        }`,
      })
      .then((response) => {
        if (response.data.data.term_conditions)
          dispatch(successGetTermConditions(response.data.data.term_conditions));
      })
      .catch((err) => {
        dispatch(failGetTermConditions(err.message));
        catchError(err);
      });
  };
};
