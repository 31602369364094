import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal, Container, Row, Col, Button, Form } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createChat, sendMessage } from "../../redux/actions";

const ChatModal = ({ showChat, showChatHandler, receiverEmail, receiverUID }) => {
  const dispatch = useDispatch();
  const { chatChannel } = useSelector((state) => state.streamChatData);
  const formik = useFormik({
    initialValues: {
      email: receiverEmail ?? "",
      message: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required("Email is required"),
      message: Yup.string().min(1, "Message cannot be empty").required("Message is required"),
    }),
    onSubmit: async (values) => {
      await chatChannel.create();
      await chatChannel.sendMessage({
        text: values.message,
      });
      showChatHandler();
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, []);

  useEffect(() => {
    dispatch(createChat(receiverUID, "shopping"));
  }, [receiverUID]);

  return (
    <Modal
      show={showChat}
      onHide={() => {
        formik.resetForm();
        showChatHandler();
      }}
      centered
      className="chat-box">
      <Modal.Body className="px-5 py-5" style={{ borderRadius: "26px" }}>
        <Container className="position-relative">
          <AiOutlineClose
            onClick={() => {
              formik.resetForm();
              showChatHandler();
            }}
            style={{
              position: "absolute",
              top: "-52px",
              right: "-20px",
              cursor: "pointer",
            }}></AiOutlineClose>
          <Row>
            <Col>
              <h5>Start a new chat</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form noValidate onSubmit={formik.handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    isValid={formik.touched.email && !formik.errors.email}
                    isInvalid={formik.touched.email && formik.errors.email}
                    {...formik.getFieldProps("email")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name="message"
                    placeholder="Write your message here..."
                    isValid={formik.touched.message && !formik.errors.message}
                    isInvalid={formik.touched.message && formik.errors.message}
                    {...formik.getFieldProps("message")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.message}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="d-grid">
                  <Button variant="primary text-white" type="submit">
                    Send Message
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ChatModal;
