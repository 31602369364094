import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getProductCartQuantity } from "../../lib/product";
import { ProductRating } from "../Product";
import ProductVariant from "../ProductThumb/elements/ProductVariants";
import { buyPreloved, prelovedCheckout, getDetails, getAddress } from "../../redux/actions/index";
import parse from "html-react-parser";
import { FaHeart } from "react-icons/fa";
import { GiNuclearPlant } from "react-icons/gi";
import { Button, Spinner } from "react-bootstrap";
import { AddressModal } from "..";
import { getToken } from "../../redux/actions/utils";
import { useTranslation } from "next-i18next";

const ProductDescription = ({
  product,
  productPrice,
  discountedPrice,
  cartItems,
  wishlistItem,
  addToast,
  addToWishlist,
  deleteFromWishlist,
  productContentButtonStyleClass,
  addCartItem,
  user,
  setThumbnailId,
}) => {
  const { cart_items: itemInCart } = useSelector((state) => state.cartData);
  const { address_shipping } = useSelector((state) => state.profile);
  const { prelovedCheckoutData, errorPrelovedCheckout, loadingPrelovedCheckout } = useSelector(
    (state) => state.preloved
  );
  const router = useRouter();
  const { t } = useTranslation("common");

  const dispatch = useDispatch();

  const [productStock, setProductStock] = useState(
    product.configurable
      ? product.configurable.length > 0
        ? product.configurable[0].stock
        : product.stock
      : product.stock
  );
  const [quantityCount, setQuantityCount] = useState(1);

  const [configurableId, setConfigurableId] = useState(
    product.configurable
      ? product.configurable.length > 0
        ? product.configurable[0].id
        : null
      : null
  );

  const [inCart, setInCart] = useState(
    cartItems.filter((item) => item.product_id === product.id).length > 0
  );

  const [productCartQty, setProductCartQty] = useState(
    getProductCartQuantity(
      cartItems,
      product,
      product.configurable
        ? product.configurable.length > 0
          ? product.configurable[0].id
          : null
        : null
    )
  );

  const [attributeIds, setAttributeIds] = useState(null);

  const [priceProduct, setPriceProduct] = useState(productPrice);

  const [showAddress, setShowAddress] = useState(false);

  const showAddressModal = () => {
    setShowAddress(!showAddress);
  };

  useEffect(() => {
    const user = getToken();
    if (user) {
      dispatch(getAddress());
    }
  }, []);

  useEffect(() => {
    if (prelovedCheckoutData == null && errorPrelovedCheckout != null) {
      addToast(errorPrelovedCheckout, {
        autoDismiss: true,
        appearance: "error",
      });
    }

    if (prelovedCheckoutData != null) {
      router.push("/shop/preloved/checkout");
    }
  }, [errorPrelovedCheckout, prelovedCheckoutData]);

  useEffect(() => {
    if (attributeIds) {
      const getStockIds = () => {
        const attributeIdsArr = [];

        Object.values(attributeIds).forEach((val) => attributeIdsArr.push(val));

        let result = {};

        product.configurable.map((variant) => {
          const varAttriIds = [];

          variant.attribute.forEach((varAttri) => varAttriIds.push(varAttri.attribute_id));

          const filteredArray = attributeIdsArr.filter((value) => varAttriIds.includes(value));

          if (filteredArray.length === attributeIdsArr.length)
            result = {
              product_configurable_id: variant.id,
              product_id: product.id,
              stock: variant.stock,
              price: variant.price,
            };
        });
        return result;
      };
      const { product_configurable_id, stock, product_id, price } = getStockIds();
      //console.log(product_configurable_id, stock, product_id, price);

      setProductStock(stock);
      setQuantityCount(1);
      setConfigurableId(product_configurable_id);
      setThumbnailId(product_configurable_id);
      setPriceProduct(price);
      setProductCartQty(getProductCartQuantity(cartItems, product, product_configurable_id));
    }
  }, [attributeIds]);

  useEffect(() => {
    if (configurableId) {
      setInCart(
        itemInCart.filter((item) => item.product_configurable_id === configurableId).length > 0
      );
      setProductCartQty(getProductCartQuantity(cartItems, product, configurableId));
    } else {
      setInCart(itemInCart.filter((item) => item.product_id === product.id).length > 0);
      setProductCartQty(getProductCartQuantity(cartItems, product, null));
    }
  }, [itemInCart]);

  return (
    <div className="product-content">
      <h2 className="product-content__title space-mb--10">{product.name}</h2>
      <div className="product-content__price-rating-wrapper space-mb--10">
        <div className="product-content__price d-flex-align-items-center">
          {product.discount ? (
            <Fragment>
              <span className="price">RM {discountedPrice}</span>
              <del>RM {product.productPrice}</del>
              <span className="on-sale">{product.discount}% Off</span>
            </Fragment>
          ) : (
            <span className="price">RM {priceProduct}</span>
          )}
        </div>
        {product.rating && product.rating > 0 ? (
          <div className="product-content__rating-wrap">
            <div className="product-content__rating">
              <ProductRating ratingValue={product.rating} />
              <span>({product.rating})</span>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="product-content__description space-mb--20">
        {parse(product.short_description ?? product.description)}
      </div>

      {/* <div className="product-content__sort-info space-mb--20">
        <ul>
          <li>
            <BsShield /> 1 Year Brand Warranty
          </li>
          <li>
            <AiOutlineReload /> 30 Days Return Policy
          </li>
          <li>
            <GiSwapBag /> Cash on Delivery available
          </li>
        </ul>
      </div> */}

      {product.type === "configurable" && (
        <ProductVariant
          product={product}
          attributeIds={attributeIds}
          setAttributeIds={setAttributeIds}
        />
      )}

      <hr />
      {product.preloved ? (
        <div className="product-content__quality">
          <div className="product-content__cart btn-hover">
            <Button
              className="btn btn-fill-out text-white"
              disabled={loadingPrelovedCheckout}
              onClick={() => {
                if (address_shipping.length == 0) {
                  showAddressModal(true);
                } else {
                  dispatch(prelovedCheckout(product.id, 74));
                }
              }}>
              {loadingPrelovedCheckout == true ? (
                <>
                  <Spinner
                    as="span"
                    animation="circle"
                    size="sm"
                    role="border"
                    aria-hidden="true"
                  />
                  &nbsp;{t("Checking out...")}
                </>
              ) : (
                "Checkout"
              )}
            </Button>
          </div>
        </div>
      ) : (
        <Fragment>
          <div
            className={`${
              productContentButtonStyleClass
                ? productContentButtonStyleClass
                : "product-content__button-wrapper d-flex align-items-center"
            }`}>
            <div className="product-content__quantity">
              <div className="cart-plus-minus">
                <button
                  onClick={() => setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)}
                  className="qtybutton">
                  -
                </button>
                <input className="cart-plus-minus-box" type="text" value={quantityCount} readOnly />
                <button
                  onClick={() =>
                    setQuantityCount(
                      quantityCount < productStock - productCartQty
                        ? quantityCount + 1
                        : quantityCount
                    )
                  }
                  className="qtybutton">
                  +
                </button>
              </div>
            </div>
            {productStock && productStock > 0 && product.type === "simple" ? (
              <button
                onClick={() => {
                  if (!user) {
                    return router.replace("/user/login");
                  }
                  const data = {
                    product_id: product.id,

                    quantity: quantityCount,
                  };
                  //console.log(inCart);

                  if (inCart) {
                    const productCart = cartItems.filter(
                      (item) => item.product_id === product.id
                    )[0];

                    data.quantity = quantityCount + productCart.quantity;
                  }

                  if (data.quantity > productStock) {
                    addToast(t("You have reached the max amount of this item you can buy."), {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  } else {
                    addCartItem(data, addToast);
                  }
                }}
                className={`btn btn-fill-out btn-addtocart ${inCart ? "active" : ""}`}
                disabled={productCartQty >= productStock}>
                <i className="icon-basket-loaded" /> {t("Add To Cart")}
              </button>
            ) : productStock && productStock > 0 && product.type === "configurable" ? (
              <button
                className="btn btn-fill-out btn-addtocart"
                onClick={() => {
                  if (!user) {
                    return router.replace("/user/login");
                  }
                  const data = {
                    product_configurable_id: configurableId,
                    product_id: product.id,

                    quantity: quantityCount,
                  };

                  if (inCart && configurableId) {
                    const productCart = cartItems.filter(
                      (item) => item.product_configurable_id === configurableId
                    )[0];
                    if (productCart) {
                      if (configurableId === productCart.product_configurable_id) {
                        data.quantity = quantityCount + productCart.quantity;
                      }
                    }

                    if (data.quantity > productStock) {
                      addToast(t("You have reached the max amount of this item you can buy."), {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    } else {
                      addCartItem(data, addToast);
                    }
                  } else {
                    addCartItem(data, addToast);
                  }
                }}>
                {t("Add To Cart")}
              </button>
            ) : (
              <button className="btn btn-fill-out btn-addtocart" disabled>
                {t("Out of stock")}
              </button>
            )}

            {/* <button
              className={`product-content__compare ${compareItem !== undefined ? "active" : ""
                }`}
              title={
                compareItem !== undefined
                  ? "Added to compare"
                  : "Add to compare"
              }
              onClick={
                compareItem !== undefined
                  ? () => deleteFromCompare(product, addToast)
                  : () => addToCompare(product, addToast)
              }
            >
              <i className="icon-shuffle" />
            </button> */}

            <button
              className={`product-content__wishlist ${wishlistItem !== undefined ? "active" : ""}`}
              title={wishlistItem !== undefined ? "Added to wishlist" : "Add to wishlist"}
              onClick={
                wishlistItem !== undefined
                  ? () => deleteFromWishlist(wishlistItem.id, addToast)
                  : () => addToWishlist(product.id, addToast)
              }>
              {wishlistItem !== undefined ? (
                <FaHeart className="text-primary" />
              ) : (
                <i className="icon-heart" />
              )}
            </button>
          </div>
        </Fragment>
      )}
      <hr />
      {!product.preloved && (
        <ul className="product-content__product-meta">
          {/* <li>
            SKU: <span>{product.sku}</span>
          </li> */}
          <li>
            {t("Stock")}
            {": "}
            <span>
              {productStock} {t("remaining")}
            </span>
          </li>
          {/* <li>
            Category:
            {product.category &&
              product.category.map((item, index, arr) => {
                return (
                  <Link
                    href="/shop/grid-left-sidebar"
                    as={"/shop/grid-left-sidebar"}
                    key={index}
                  >
                    <a>{item + (index !== arr.length - 1 ? ", " : "")}</a>
                  </Link>
                );
              })}
          </li>
          <li>
            Tags:
            {product.tag &&
              product.tag.map((item, index, arr) => {
                return (
                  <Link
                    href="/shop/grid-left-sidebar"
                    as={"/shop/grid-left-sidebar"}
                    key={index}
                  >
                    <a>{item + (index !== arr.length - 1 ? ", " : "")}</a>
                  </Link>
                );
              })}
          </li> */}
        </ul>
      )}
      {/* <div className="product-content__product-share space-mt--15">
        <span>Share:</span>
        <ul className="social-icons">
          <li>
            <a href="#">
              <IoLogoFacebook />
            </a>
          </li>
          <li>
            <a href="#">
              <IoLogoTwitter />
            </a>
          </li>
          <li>
            <a href="#">
              <IoLogoGoogleplus />
            </a>
          </li>
          <li>
            <a href="#">
              <IoLogoYoutube />
            </a>
          </li>
          <li>
            <a href="#">
              <IoLogoInstagram />
            </a>
          </li>
        </ul>
      </div> */}
      <AddressModal
        show={showAddress}
        handleClose={showAddressModal}
        mode={"add-from-outside"}
        type={"shipping"}
        address_shipping={address_shipping}
      />
    </div>
  );
};

export default ProductDescription;
