import { Fragment } from "react";
import Link from "next/link";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { IoIosClose } from "react-icons/io";
import { getDiscountPrice } from "../../../lib/product";
import { addCartItem } from "../../../redux/actions/index";
import { useTranslation } from "next-i18next";

const MiniCart = ({ cartItems, deleteFromCart, addCartItem }) => {
  const { t } = useTranslation("common");

  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  return (
    <Fragment>
      {cartItems.length > 0 ? (
        <div className="cart-box">
          <ul className="cart-list">
            {cartItems.map((vendor, key) => {
              // const discountedPrice = getDiscountPrice(
              //   product.price,
              //   product.discount
              // ).toFixed(2);
              return (
                <div key={key}>
                  {vendor.cart.map((product) => {
                    cartTotalPrice += product.price * product.quantity;
                    return (
                      <li key={product.id}>
                        <button
                          className="item-remove"
                          onClick={() =>
                            addCartItem(
                              {
                                product_id: product.product_id,
                                quantity: 0,
                              },
                              addToast,
                              "remove"
                            )
                          }>
                          <IoIosClose />
                        </button>
                        <div className="single-item">
                          {/* <div className="single-item__image">
                            <Link
                              href={`/shop/product/[id]?id=${product.id}`}
                              as={"/shop/product/" + product.id}>
                              <a>
                                {product.thumbnail && (
                                  <img src={product.thumbnail} alt="product" />
                                  ) }
                              </a>
                            </Link>
                          </div> */}
                          <div className="single-item__content">
                            <Link
                              href={`/shop/product/[id]?id=${product.product_id}`}
                              as={"/shop/product/" + product.product_id}>
                              <a>{product.name}</a>
                            </Link>
                            <span className="cart-quantity">
                              {" "}
                              {product.quantity} x{" "}
                              <span className="cart-amount">
                                {" "}
                                <span className="price-symbol">RM</span>
                              </span>
                              {product.price}
                            </span>
                            {product.variation && (
                              <p
                                style={{ fontSize: "10px" }}
                                className="badge rounded-pill bg-primary">
                                {product.variation}
                              </p>
                            )}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </div>
              );
            })}
          </ul>
          <div className="cart-footer">
            <p className="cart-total">
              <strong>{t('Subtotal')}:</strong>{" "}
              <span className="cart-price">
                {" "}
                <span className="price-symbol">RM</span>
              </span>
              {cartTotalPrice ? cartTotalPrice.toFixed(2) : 0.0}
            </p>
            <div className="cart-buttons">
              <Link href="/products/cart">
                <a className="btn btn-fill-line view-cart">{t("View Cart")}</a>
              </Link>
              {/* <Link href="/products/checkout">
                <a className="btn btn-fill-out checkout">Checkout</a>
              </Link> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCartItem: (item, addToast, type) => dispatch(addCartItem(item, addToast, type)),

    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    },
  };
};

export default connect(null, mapDispatchToProps)(MiniCart);
