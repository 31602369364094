import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { addPreloved, editPreloved } from "../../redux/actions";
import dynamic from "next/dynamic";
const ReactQuill = dynamic(() => import("react-quill"), { ssr: false });
import "react-quill/dist/quill.snow.css";
import { AiFillMinusCircle } from "react-icons/ai";
import { useTranslation } from "next-i18next";

export default function AddPreloved({ goBack, editData }) {
  const [thumbnail, setThumbnail] = useState(null);
  const [images, setImages] = useState([
    { id: "image1", file: null },
    { id: "image2", file: null },
    { id: "image3", file: null },
    { id: "image4", file: null },
    { id: "image5", file: null },
  ]);
  //const [shortDescription, setShortDescription] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { t } = useTranslation('common');

  const formik = useFormik({
    initialValues: {
      status: true,
      name: "",
      //short_description: "",
      description: "",
      price: "",
      sold: false,
      weight: "",
      thumbnail: {},
      images: [],
    },
    validationSchema: Yup.object({
      status: Yup.boolean(),
      name: Yup.string()
        // .max(15, 'Must be 15 characters or less')
        .required("Required"),
      //short_description: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      price: Yup.number().required().positive(),
      // sold: Yup.number()
      //   .required()
      //   .moreThan(-1)
      //   .integer('sold must ba an integer'),
      sold: Yup.boolean(),
      weight: Yup.number().required().positive(),
    }),
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      //console.log(values);
      if (editData) {
        const data = { ...values, id: editData.id };
        dispatch(editPreloved(data, addToast));
        resetForm();
        setThumbnail({});
        setImages([
          { id: "image1", file: null },
          { id: "image2", file: null },
          { id: "image3", file: null },
          { id: "image4", file: null },
          { id: "image5", file: null },
        ]);
        goBack(true);
        window.scrollTo({ top: 100, behavior: "smooth" });
      } else {
        if (!values.thumbnail.type) {
          setThumbnail({ src: null, err: "image required" });
        } else {
          dispatch(addPreloved(values, addToast));
          resetForm();
          setThumbnail({});
          setImages([
            { id: "image1", file: null },
            { id: "image2", file: null },
            { id: "image3", file: null },
            { id: "image4", file: null },
            { id: "image5", file: null },
          ]);
          goBack(true);
          window.scrollTo({ top: 100, behavior: "smooth" });
        }
      }
    },
  });

  const removeImage = (key) => {
    let currentImgArray = images;
    let currentImgUpdate = { ...currentImgArray[key] };
    currentImgUpdate = {
      ...currentImgUpdate,
      file: null,
    };
    currentImgArray[key] = currentImgUpdate;
    if (key === 0) {
      setThumbnail({ src: null, err: "image required" });
      formik.setValues((values) => {
        return { ...values, thumbnail: {} };
      });
    }
    setImages([...currentImgArray]);
    formik.setValues((values) => {
      const formImages = values.images;
      const index = formImages.indexOf(key);
      if (index > -1) {
        formImages.splice(index, 1);
      }
      return { ...values, images: formImages };
    });
  };

  const handleShortDescription = (event) => {
    formik.setValues((values) => {
      return {
        ...values,
        short_description: event,
      };
    });
    setShortDescription(event);
  };

  const handleDescription = (event) => {
    formik.setValues((values) => {
      return {
        ...values,
        description: event,
      };
    });
    setDescription(event);
  };

  useEffect(() => {
    if (editData) {
      console.log(editData.status == 1);
      //setShortDescription(editData.description);
      setDescription(editData.description);
      setThumbnail({
        src: editData.thumbnail,
        err: null,
      });

      const tempImages = images;
      for (let x = 0; x < editData.image.length; x++) {
        tempImages[x].file = editData.image[x].path;
      }

      setImages([...tempImages]);

      formik.setValues({
        status: editData.status == 1 ? true : false,
        name: editData.name,
        //short_description: editData.description,
        description: editData.description,
        price: editData.price,
        sold: editData.sold == 1 ? true : false,
        weight: editData.weight,
      });
    }
  }, [editData]);

  return (
    <Form noValidate onSubmit={formik.handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Status"
            defaultChecked={formik.values.status}
            {...formik.getFieldProps("status")}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>
            {t("Name")} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formik.values.name}
            isValid={formik.touched.name && !formik.errors.name}
            isInvalid={formik.touched.name && formik.errors.name}
            {...formik.getFieldProps("name")}
          />
          <Form.Control.Feedback type="invalid">name required</Form.Control.Feedback>
        </Form.Group>
      </Row>
      {/* <Row className="mb-3">
        <Form.Group as={Col} md="8">
          <Form.Label>
            Short description <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <ReactQuill
            theme="snow"
            className={
              formik.touched.short_description && !formik.errors.short_description
                ? "border border-success"
                : formik.touched.short_description && formik.errors.short_description
                ? "border border-danger"
                : ""
            }
            defaultValue={shortDescription}
            onChange={handleShortDescription}
          />
          <Form.Control.Feedback type="invalid">short description required</Form.Control.Feedback>
        </Form.Group>
      </Row> */}
      <Row className="mb-3">
        <Form.Group as={Col} md="8">
          <Form.Label>
            {t("Description")} <span style={{ color: "red" }}>*</span>
          </Form.Label>

          <ReactQuill
            theme="snow"
            className={
              formik.touched.description && !formik.errors.description
                ? "border border-success"
                : formik.touched.description && formik.errors.description
                ? "border border-danger"
                : ""
            }
            defaultValue={description}
            onChange={handleDescription}
          />
          <Form.Control.Feedback type="invalid">description required</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>
            {t("Price")} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            name="price"
            value={formik.values.price}
            isValid={formik.touched.price && !formik.errors.price}
            isInvalid={formik.touched.price && formik.errors.price}
            {...formik.getFieldProps("price")}
          />
          <Form.Control.Feedback type="invalid">price required</Form.Control.Feedback>
        </Form.Group>
      </Row>
      {/* <Row className="mb-3">
        <Form.Group as={Col} md="4" >
          <Form.Label>Sold <span style={{ color: 'red' }}>*</span></Form.Label>
          <Form.Control
            type="number"
            name="sold"
            value={formik.values.sold}
            isValid={formik.touched.sold && !formik.errors.sold}
            isInvalid={formik.touched.sold && formik.errors.sold}
            {...formik.getFieldProps('sold')}
          />
          <Form.Control.Feedback type="invalid">sold required</Form.Control.Feedback>
        </Form.Group>
      </Row> */}
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Check
            type="switch"
            id="custom-switch"
            label="Sold"
            defaultChecked={formik.values.sold}
            {...formik.getFieldProps("sold")}
          />
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>
            {t("Weight")} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          <Form.Control
            type="number"
            name="weight"
            value={formik.values.weight}
            isValid={formik.touched.weight && !formik.errors.weight}
            isInvalid={formik.touched.weight && formik.errors.weight}
            {...formik.getFieldProps("weight")}
          />
          <Form.Control.Feedback type="invalid">weight required</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col}>
          <Form.Label>
            {t("Images")} <span style={{ color: "red" }}>*</span>
          </Form.Label>
          {/* <br />

          <Form.Label
            htmlFor="image"
            className={
              thumbnail && thumbnail.src
                ? "btn btn-outline-success"
                : thumbnail && thumbnail.err
                ? "btn btn-outline-danger"
                : "btn btn-outline-secondary"
            }>
            Upload
          </Form.Label> */}
          <div className="d-flex">
            {images.map((image, index) => (
              <div className="position-relative" key={image.id}>
                {image.file && editData === null && (
                  <div
                    className="position-absolute"
                    style={{ top: "0px", right: "4px", cursor: "pointer" }}
                    onClick={(event) => removeImage(index)}>
                    <AiFillMinusCircle className="text-danger mx-2 my-2" size={20} />
                  </div>
                )}
                <Form.Label
                  htmlFor={image.id}
                  className={
                    thumbnail && thumbnail.src
                      ? "border border-success mx-2 p-3 d-flex justify-content-center"
                      : thumbnail && thumbnail.err
                      ? "border border-danger mx-2 p-3 d-flex justify-content-center"
                      : "border border-secondary mx-2 p-3 d-flex justify-content-center"
                  }
                  style={{ width: "120px", height: "120px", cursor: "pointer" }}>
                  <img
                    src={image.file ? image.file : "/assets/images/add-photo.png"}
                    className="img-fluid"
                  />
                </Form.Label>
              </div>
            ))}
          </div>
          {images.map((image, index) => (
            <Form.Control
              key={image.id}
              type="file"
              name={image.id}
              id={image.id}
              style={{ display: "none" }}
              isValid={thumbnail ? thumbnail.src : null}
              isInvalid={thumbnail ? thumbnail.err : null}
              onChange={(event) => {
                if (event.target.files[0]) {
                  const file = event.target.files[0];

                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  const imgTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
                  const validType = imgTypes.some((type) => file.type === type);
                  if (file.size < 6 * Math.pow(10, 6) && validType) {
                    reader.onload = () => {
                      const tempImages = images;
                      const tempImage = image;
                      tempImage.file = reader.result;

                      tempImages[index] = tempImage;
                      //console.log(tempImages);
                      setImages([...tempImages]);
                      if (index === 0) {
                        setThumbnail({
                          src: reader.result,
                          err: null,
                        });
                      }
                    };

                    formik.setValues((values) => {
                      const formImages = values.images;
                      if (formImages[index]) {
                        formImages[index] = file;
                      } else {
                        formImages.push(file);
                      }

                      return {
                        ...values,
                        thumbnail: formImages[0],
                        images: formImages,
                      };
                    });
                  } else {
                    setThumbnail({ src: null, err: "image required" });
                    setImages([
                      { id: "image1", file: null },
                      { id: "image2", file: null },
                      { id: "image3", file: null },
                      { id: "image4", file: null },
                      { id: "image5", file: null },
                    ]);
                    formik.setValues((values) => {
                      return { ...values, thumbnail: {}, images: [] };
                    });
                  }
                }
              }}
              disabled={editData !== null}
            />
          ))}
          {/* <Form.Control
            type="file"
            name="image"
            id="image"
            multiple
            style={{ display: "none" }}
            isValid={thumbnail ? thumbnail.src : null}
            isInvalid={thumbnail ? thumbnail.err : null}
            onChange={(event) => {
              const files = event.target.files;

              if (files.length > 0) {
                const validImages = [];
                for (let x = 0; x < files.length; x++) {
                  const reader = new FileReader();
                  reader.readAsDataURL(files[x]);
                  const imgTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
                  const validType = imgTypes.some((type) => files[x].type === type);
                  if (files[x].size < 6 * Math.pow(10, 6) && validType) {
                    validImages.push(files[x]);
                    if (x === 0) {
                      reader.onload = () => {
                        setThumbnail({
                          src: reader.result,
                          err: null,
                        });
                        setImages([reader.result]);
                      };
                      formik.setValues((values) => {
                        return {
                          ...values,
                          thumbnail: files[x],
                        };
                      });
                    } else {
                      reader.onload = () => {
                        setImages((prevState) => {
                          return [...prevState, reader.result];
                        });
                      };
                    }
                  } else {
                    setImages([]);
                    formik.setValues((values) => {
                      return { ...values, images: [] };
                    });
                  }
                }

                formik.setValues((values) => {
                  return {
                    ...values,
                    thumbnail: validImages[0],
                    images: [...validImages],
                  };
                });
              } else {
                setThumbnail({ src: null, err: "image required" });
                setImages([]);
                formik.setValues((values) => {
                  return { ...values, thumbnail: {}, images: [] };
                });
              }
            }}
          /> */}
          <Form.Control.Feedback type="invalid">
            {thumbnail ? thumbnail.err : null}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row>
        <Col>
          <Button type="submit" className="text-white">
            {t("Submit")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
