import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Card, Row, Col, Button, Badge } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
import { postAddress } from "../../redux/actions/index";
import { useTranslation } from "next-i18next";

const AddressCard = ({
  toggleModal,
  setMode,
  address,
  setAddresData,
  setAddressType,
  type,
}) => {
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { t } = useTranslation('common');

  return (
    <Card className="address-card mb-3">
      <Card.Body>
        <Row>
          <Col lg={8}>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Name")}
              </Col>
              <Col className="fw-bold">
                {address.name}
                {address.default ? (
                  <Badge variant="primary" className="ms-2">
                    {t("Default")}
                  </Badge>
                ) : null}

                <Badge variant="primary" className="ms-2 text-capitalize">
                  {t(type)}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Phone")}
              </Col>
              <Col>{address.phone}</Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Address")}
              </Col>
              <Col>
                {`${address.line_1}, ${address.line_2} ${address.line_3 ?? ""}`}{" "}
                <br />
                {`${address.postcode}, ${address.city},${address.state}`}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col>
                <a
                  onClick={() => {
                    setAddressType(type);
                    setAddresData({ ...address });
                    setMode();
                    toggleModal();
                  }}
                  className="check-btn sqr-btn me-3"
                >
                  <u>{t("Edit")}</u>
                </a>
                <a href="#" className="check-btn sqr-btn me-3">
                  <u
                    onClick={(event) => {
                      event.preventDefault();
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <Card className="shadow">
                              <Card.Body>
                                <h3 className="fw-bold">{t("Delete")}</h3>
                                <p>{t("Delete this address?")}</p>
                                <Button
                                  variant="light"
                                  size="sm"
                                  onClick={onClose}
                                >
                                  {t("No")}
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  className="ms-2"
                                  onClick={() => {
                                    const data = {
                                      _method: "DELETE",
                                      id: address.id,
                                      type: type,
                                    };
                                    dispatch(postAddress(data, addToast));

                                    onClose();
                                  }}
                                >
                                  {t("Yes")}
                                </Button>
                              </Card.Body>
                            </Card>
                          );
                        },
                      });
                    }}
                  >
                    {t("Delete")}
                  </u>
                </a>
                {type !== "billing" && (
                  <a href="#" className="check-btn sqr-btn">
                    <u
                      onClick={(event) => {
                        event.preventDefault();
                        const data = {
                          name: address.name,
                          phone: address.phone,
                          line_1: address.line_1,
                          line_2: address.line_2,
                          line_3: address.line_3,
                          postcode: address.postcode,
                          city: address.city,
                          state: address.state,
                          country: address.country,
                          type: "billing",
                        };
                        dispatch(postAddress(data, addToast));
                      }}
                    >
                      {t("Set as billing")}
                    </u>
                  </a>
                )}
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                {type === "shipping" && !address.default && (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      const data = {
                        _method: "PUT",
                        id: address.id,
                        default: 1,
                        name: address.name,
                        phone: address.phone,
                        line_1: address.line_1,
                        line_2: address.line_2,
                        line_3: address.line_3,
                        postcode: address.postcode,
                        city: address.city,
                        state: address.state,
                        country: address.country,
                        type: type,
                      };
                      dispatch(postAddress(data, addToast));
                    }}
                  >
                    {t("Set As Default")}
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddressCard;
