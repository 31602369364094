import classes from "./category.module.css";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import { getCategories, addParam } from "../../redux/actions/index";
import Swiper from "react-id-swiper";
import { useTranslation } from "next-i18next";

export default function Category() {
  const { t } = useTranslation("common");

  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState(null);
  const categories = useSelector((state) => state.categoryData.data);
  const router = useRouter();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    slidesPerView: 8,
    slidesPerColumn: 2,
    slidesPerGroup: 8,
    pagination: {
      el: ".swiper-slide.swiper-slide-custom",
      clickable: true,
    },
    wrapperClass: classes.category,
    slideClass: classes.slider,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1024: {
        slidesPerView: 7,
        slidesPerGroup: 7,
      },
      769: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      576: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
      320: {
        slidesPerView: 4,
        slidesPerGroup: 4,
      },
    },
  };
  return (
    <Container className="space-pb--r70">
      <Row>
        <Col className="position-relative">
          <Swiper {...params} shouldSwiperUpdate getSwiper={setSwiper}>
            {categories.map((cate) => (
              <div
                key={cate.id}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(addParam("search", null, null, ""));
                  router.push({
                    pathname: "/shop/products",
                    query: { id: cate.id },
                  });
                }}>
                <div
                  style={{
                    width: "5rem",
                    height: "5rem",
                    backgroundColor: "rgba(199, 236, 231, 1)",
                    borderRadius: "100%",
                  }}
                  className="d-flex flex-row justify-content-center align-items-center">
                  <img src={cate.icon} alt="cate" className="img-fluid" />
                </div>
                <p key={cate.id} className="mt-3">
                  {" "}
                  {t(cate.name)}
                </p>
              </div>
            ))}
          </Swiper>
        </Col>
      </Row>
    </Container>
  );
}
