import { Row, Col, Card, Badge } from "react-bootstrap";
import { useTranslation } from "next-i18next";

const AddressCardCheckout = ({
  address,
  selected,
  setSelectedAddress,
  toggleModal,
  setMode,
  setAddresData,
  setAddressType,
  type,
}) => {
  const { t } = useTranslation('common');

  return (
    <Card
      className={`address-card mb-3 ${selected == true ? "border-primary" : ""}`}
      onClick={() => {
        setSelectedAddress(address.id);
      }}>
      <Card.Body>
        <Row>
          <Col lg={10}>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Name")}
              </Col>
              <Col className="fw-bold">{address?.name || "-"}</Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Phone")}
              </Col>
              <Col>{address?.phone || "-"}</Col>
            </Row>
            <Row>
              <Col xs={4} sm={4} lg={2} className="text-right">
                {t("Address")}
              </Col>
              <Col>
                {`${address?.line_1}, ${address?.line_2} ${address?.line_3 ?? ""}`} <br />
                {`${address?.postcode}, ${address?.city},${address?.state}`}
              </Col>
            </Row>
          </Col>
          <Col className>
            <a
              onClick={() => {
                setAddressType(type);
                setAddresData({ ...address });
                setMode();
                toggleModal();
              }}
              className="check-btn sqr-btn me-3">
              <u>{t("Edit")}</u>
            </a>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AddressCardCheckout;
