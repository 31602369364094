import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import Link from "next/link";
import { BsChevronLeft } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import { useTranslation } from "next-i18next";

import "react-vertical-timeline-component/style.min.css";
import { cleanTimelineDate } from "../../utils";
import { getOrderDetailV2 } from "../../redux/actions";

const OrderDetail = ({ detailToggle, detailsItem, orderDetailType }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const { orderDetail, orderDetailLoading, orderDetailError } = useSelector(
    (state) => state.profile
  );

  let totalPrice = 0;

  const formattedAddress_1 = orderDetail
    ? orderDetail.shipping_address_full
      ? `${orderDetail.shipping_address_full.line_1 ?? ""}, ${
          orderDetail.shipping_address_full.line_2 ?? ""
        } ${
          orderDetail.shipping_address_full.line_3
            ? ", " + orderDetail.shipping_address_full.line_3
            : ""
        }`
      : t("Address have been deleted.")
    : t("Loading..");
  const formattedAddress_2 = orderDetail
    ? orderDetail.shipping_address_full
      ? `${orderDetail.shipping_address_full.postcode ?? ""}, ${
          orderDetail.shipping_address_full.city ?? ""
        }, ${orderDetail.shipping_address_full.state ?? ""}, ${
          orderDetail.shipping_address_full.country ?? ""
        }`
      : ""
    : t("Loading..");

  useEffect(() => {
    if (!detailsItem) detailToggle(undefined, "tab");
  }, [detailsItem]);

  return (
    <>
      <Card.Header>
        <Row>
          <Col>
            <a
              onClick={() => {
                detailToggle(undefined, "tab");
              }}
              className="d-flex align-items-center">
              <BsChevronLeft></BsChevronLeft> {t("Back")}
            </a>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        {orderDetailLoading == true && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Spinner animation="border" className="text-primary" />
              <p
                style={{
                  textAlign: "center !important",
                }}>
                {t("Loading..")}
              </p>
            </Col>
          </Row>
        )}

        {orderDetail && (
          <>
            <Row>
              <Col>
                <VerticalTimeline>
                  {orderDetail.timeline.map((event, key) => {
                    return (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{ borderTop: "7px solid  #32baa5" }}
                        iconStyle={{ background: "#32baa5", color: "#fff" }}
                        icon={<FaCheckCircle />}
                        date={cleanTimelineDate(event.date)}
                        key={key}>
                        <h5 className="vertical-timeline-element-title">{t(event.title)}</h5>
                      </VerticalTimelineElement>
                    );
                  })}
                </VerticalTimeline>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr></hr>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col></Col>
              {/* <Col>
            <Button className="float-right" variant={"outline-primary"} size={"sm"}>
              Cancel Order
            </Button>
          </Col> */}
            </Row>
            <Row>
              <Col>
                <div className="letter-line"></div>
              </Col>
            </Row>
            <Row className="py-3">
              <Col>
                <h5>{t("Delivery Address")}</h5>
                <p>
                  {formattedAddress_1}
                  <br />
                  {formattedAddress_2}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="letter-line"></div>
              </Col>
            </Row>
            <Row className="pt-3 pb-3">
              <Col>
                <p className="fw-bold m-0">{t("Parcel Status")}</p>
                <p className="float-right text-capitalize p-0">{orderDetail.parcel_status}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <hr></hr>
              </Col>
            </Row>
            <Row className="pb-4">
              <Col lg={4}>
                {" "}
                <p className="fw-bold">{t("Order No")}</p>
                {orderDetail.order_no}
              </Col>
              {orderDetail.shipping_vendor_name ? (
                <Col className="border-left d-flex justify-content-end">{`${
                  t("Shipped By") + " " + orderDetail.shipping_vendor_name
                }`}</Col>
              ) : (
                <></>
              )}
            </Row>
            <Row className="order-header bg--grey pt-3">
              <Col lg={12} sm={12} xs={12}>
                {orderDetail.vendor_name}
                <Link href={"/seller/shop-products?seller=" + orderDetail.vendor_id} passHref>
                  <Button variant="outline-primary" size="sm" className="ms-2">
                    {t("View Shop")}
                  </Button>
                </Link>
                {orderDetailType === "ship" && (
                  <Button
                    variant="outline-danger"
                    size="sm"
                    className="ms-2 px-4 py-2"
                    onClick={() => {
                      detailToggle(detailsItem, "cancel");
                    }}>
                    Cancel Order
                  </Button>
                )}
                {orderDetailType === "rate" && (
                  <button
                    className="ms-2 btn btn-sm btn-yellow--four px-4 py-2"
                    onClick={() => {
                      dispatch(getOrderDetailV2(detailsItem.id));
                      detailToggle(detailsItem, "rate_list");
                    }}>
                    To Rate
                  </button>
                  // <>
                  //   <Button
                  //     variant="outline-danger"
                  //     size="sm"
                  //     className="ms-2 px-4 py-2"
                  //     onClick={() => {
                  //       detailToggle(detailsItem, "refund");
                  //     }}>
                  //     Refund
                  //   </Button>

                  // </>
                )}
              </Col>
              {/* <Col
            lg={6}
            sm={12}
            xs={12}
            className="d-flex justify-content-end text-uppercase text-primary">
            To Ship
          </Col> */}
            </Row>
            <Row className="bg--grey">
              <Col>
                <hr></hr>
              </Col>
            </Row>
            <Row className="py-2 bg--grey">
              {orderDetail.item.map((product) => {
                totalPrice = totalPrice + +product.subtotal;
                return (
                  <Col xs="12" key={product.product_id}>
                    <div className="d-flex">
                      <div className="flex-shrink-0">
                        <img
                          width={64}
                          height={64}
                          className="me-3"
                          src={product.product_thumbnail}
                          alt="Generic placeholder"
                        />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h6 className="fw-bold">{product.product_name}</h6>
                        <Row>
                          <Col>
                            <p>
                              {product.product_variance && (
                                <span className="badge rounded-pill pt-2 bg-primary">
                                  {product.product_variance}
                                </span>
                              )}
                              {product.product_variance && <br />}x {product.product_quantity}
                            </p>
                          </Col>
                          <Col className="d-flex justify-content-end text-primary">
                            <p>RM {parseFloat(product.product_price).toFixed(2)}</p>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <Row className="bg--grey">
              <Col>
                <Table bordered>
                  <tbody>
                    <tr>
                      <td colSpan="5" className="text-right">
                        {t("Sub-Total")}
                      </td>
                      <td className="text-right" width="20%">
                        RM {parseFloat(orderDetail.merchandise_subtotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        {t("Shipping Cost")}
                      </td>
                      <td className="text-right" width="20%">
                        RM {parseFloat(orderDetail.shipping_subtotal).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="5" className="text-right">
                        {t("Total")}
                      </td>
                      <td className="text-right fw-bold text-primary" width="20%">
                        RM {parseFloat(orderDetail.total_payment).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </>
  );
};

export default OrderDetail;
