import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  Modal,
  Nav,
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { FaEye, FaEyeSlash, FaFacebook, FaGoogle, FaTwitter, FaWindows } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { useToasts } from "react-toast-notifications";
import { useRouter } from "next/router";
import { startAuth, googleAuth, facebookAuth, clearError } from "../../redux/actions";
import { getTranslation } from "../../utils/helpers";

const Authentication = ({ showAuth, showAuthHandler, authMode }) => {
  const { loadingAuth, user, error } = useSelector((state) => state.authData);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(getTranslation("Must be a valid email address"))
        .max(100, getTranslation("Email must be less than 100 characters"))
        .required(getTranslation("Email is required")),
      password: Yup.string()
        .max(100, getTranslation("Password must be less than 100 characters"))
        .required(getTranslation("Password is required")),
    }),
    onSubmit: (values) => {
      dispatch(
        startAuth(values.email, values.password, "signIn", rememberMe, addToast, () =>
          router.reload()
        )
      );
      formik.resetForm();
    },
  });

  const registerFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(getTranslation("Must be a valid email address"))
        .max(100, "Email must be less than 100 characters")
        .required(getTranslation("Email is required")),
      password: Yup.string()
        .max(100, "Password must be less than 100 characters")
        .required(getTranslation("Password is required")),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required(getTranslation("Password is required")),
    }),
    onSubmit: (values) => {
      dispatch(startAuth(values.email, values.password, "signUp", true, addToast));
      registerFormik.resetForm();
    },
  });

  const resetFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Must be a valid email address")
        .max(100, "Email must be less than 100 characters")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      dispatch(startAuth(values.email, values.password, authMode, false, addToast));
      resetFormik.resetForm();
    },
  });

  // const loadFbLoginApi = () => {
  //   window.fbAsyncInit = function () {
  //     window.FB.init({
  //       appId: "384123833379279",
  //       cookie: true,
  //       xfbml: true,
  //       version: "v12.0",
  //     });

  //     window.FB.AppEvents.logPageView();
  //   };

  //   (function (d, s, id) {
  //     var js,
  //       fjs = d.getElementsByTagName(s)[0];
  //     if (d.getElementById(id)) {
  //       return;
  //     }
  //     js = d.createElement(s);
  //     js.id = id;
  //     js.src = "https://connect.facebook.net/en_US/sdk.js";
  //     fjs.parentNode.insertBefore(js, fjs);
  //   })(document, "script", "facebook-jssdk");
  // };

  // const statusChangeCallback = (response) => {
  //   if (response.status === "connected") {
  //     this.testAPI();
  //   } else if (response.status === "not_authorized") {
  //     console.log("Please log into this app.");
  //   } else {
  //     console.log("Please log into this facebook.");
  //   }
  // };

  // const checkLoginState = () => {
  //   window.FB.getLoginStatus(function (response) {
  //     statusChangeCallback(response);
  //   });
  // };

  // const handleFBLogin = () => {
  //   window.FB.login(checkLoginState());
  // };

  // useEffect(() => {
  //   loadFbLoginApi();
  // }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        dispatch(clearError());
      }, 9000);
    }

    if (user != null) {
      showAuthHandler();
    }
  }, [user]);

  return (
    <Modal show={showAuth} onHide={showAuthHandler} centered className="login">
      <Modal.Body className="px-5 py-5" style={{ borderRadius: "26px" }}>
        <Container className="position-relative">
          <AiOutlineClose
            onClick={showAuthHandler}
            style={{
              position: "absolute",
              top: "-52px",
              right: "-20px",
              cursor: "pointer",
            }}></AiOutlineClose>
          <Row className="my-4">
            <Col>
              <img
                src="/assets/images/pcari_logo.png"
                alt="PCARI LOGO"
                width="40%"
                style={{ margin: "2% 30%" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col>
                  {authMode == "signIn" ? (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder={getTranslation("Username / Email Address")}
                          isValid={formik.touched.email && !formik.errors.email}
                          isInvalid={formik.touched.email && formik.errors.email}
                          {...formik.getFieldProps("email")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <InputGroup>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={getTranslation("Password")}
                            isValid={formik.touched.password && !formik.errors.password}
                            isInvalid={formik.touched.password && formik.errors.password}
                            {...formik.getFieldProps("password")}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>

                        <Form.Control.Feedback type="invalid">
                          {formik.errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className={`my-3 d-flex justify-content-between`}>
                        <div>
                          <input
                            type="checkbox"
                            name="keep"
                            id="keep"
                            checked={rememberMe}
                            value={rememberMe}
                            className="form-check-input me-2"
                            onChange={(event) => setRememberMe(!rememberMe)}
                          />
                          <label htmlFor="keep"></label>
                          <small>{getTranslation("Remember me?")}</small>
                        </div>
                        <div
                          onClick={() => {
                            showAuthHandler("passwordReset", true);
                          }}>
                          <small>{getTranslation("Forgot password?")}</small>
                        </div>
                      </div>
                      <div className="d-grid">
                        <Button variant="primary text-white" type="submit">
                          {getTranslation("LOG IN")}
                        </Button>
                      </div>
                      <Form.Group>
                        <p className="text-center mt-3">
                          {getTranslation("Don’t have an account?")}{" "}
                          <a
                            onClick={() => {
                              showAuthHandler("signUp", true);
                              formik.resetForm();
                            }}
                            className="text-primary">
                            {getTranslation("Create a free account")}
                          </a>
                        </p>
                      </Form.Group>
                    </Form>
                  ) : authMode === "signUp" ? (
                    <Form noValidate onSubmit={registerFormik.handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{getTranslation("Email address")}</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder={getTranslation("Enter email")}
                          isValid={registerFormik.touched.email && !registerFormik.errors.email}
                          isInvalid={registerFormik.touched.email && registerFormik.errors.email}
                          {...registerFormik.getFieldProps("email")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {registerFormik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>{getTranslation("Password")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder={getTranslation("Password")}
                            isValid={
                              registerFormik.touched.password && !registerFormik.errors.password
                            }
                            isInvalid={
                              registerFormik.touched.password && registerFormik.errors.password
                            }
                            {...registerFormik.getFieldProps("password")}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {registerFormik.errors.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>{getTranslation("Confirm Password")}</Form.Label>
                        <InputGroup>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="confirmPassword"
                            placeholder={getTranslation("Confirm Password")}
                            isValid={
                              registerFormik.touched.confirmPassword &&
                              !registerFormik.errors.confirmPassword
                            }
                            isInvalid={
                              registerFormik.touched.confirmPassword &&
                              registerFormik.errors.confirmPassword
                            }
                            {...registerFormik.getFieldProps("confirmPassword")}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              setShowPassword(!showPassword);
                            }}>
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">
                          {registerFormik.errors.confirmPassword}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary text-white" type="submit">
                          {getTranslation("REGISTER")}
                        </Button>
                      </div>
                      <Form.Group>
                        <p className="text-center mt-3">
                          {getTranslation("Already have an account?")}{" "}
                          <a
                            onClick={() => {
                              showAuthHandler("signIn", true);
                              registerFormik.resetForm();
                            }}
                            className="text-primary">
                            {getTranslation("Sign in here")}
                          </a>
                        </p>
                      </Form.Group>
                    </Form>
                  ) : (
                    <Form noValidate onSubmit={resetFormik.handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{getTranslation("Email address")}</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder={getTranslation("Enter email")}
                          isValid={resetFormik.touched.email && !resetFormik.errors.email}
                          isInvalid={resetFormik.touched.email && resetFormik.errors.email}
                          {...resetFormik.getFieldProps("email")}
                        />
                        <Form.Control.Feedback type="invalid">
                          {registerFormik.errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <div className="d-grid">
                        <Button variant="primary text-white" type="submit">
                          {getTranslation("SEND EMAIL")}
                        </Button>
                      </div>
                      <Form.Group>
                        <p className="text-center mt-3">
                          <a
                            onClick={() => {
                              showAuthHandler("signIn", true);
                              resetFormik.resetForm();
                            }}
                            className="text-primary">
                            {getTranslation("Back to login")}
                          </a>
                        </p>
                      </Form.Group>
                    </Form>
                  )}
                </Col>
              </Row>
              <Row className="justify-content-center">
                {loadingAuth == true ? <Spinner animation="border" variant="primary" /> : null}
                {error ? (
                  <small style={{ color: "red" }} className="text-center text-capitalize">
                    {error}
                  </small>
                ) : null}
              </Row>
              <div className="or-devider mx-auto">
                <span className="font-size-3 line-height-reset text-capitalize">
                  {getTranslation("or")}
                </span>
              </div>
              <Row>
                <Col className="d-flex justify-content-center">
                  {getTranslation("Sign In With:")}
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-center">
                  <FaFacebook
                    size={40}
                    color={"#A4A1A1"}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(facebookAuth(addToast, () => router.reload()));
                    }}></FaFacebook>
                  {/* <FaTwitter
                    size={40}
                    color={"#A4A1A1"}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(twitterAuth(addToast));
                    }}></FaTwitter> */}
                  <FaGoogle
                    size={40}
                    color={"#A4A1A1"}
                    className="me-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(googleAuth(addToast, () => router.reload()));
                    }}></FaGoogle>
                  {/* <FaWindows
                    size={40}
                    color={"#A4A1A1"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(windowsAuth(addToast));
                    }}></FaWindows> */}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default Authentication;
