import { getToken } from "./utils";
import * as actionTypes from "./actionTypes";
import { defaultAxios } from "../../utils";
import { catchError, getTranslation } from "../../utils/helpers";

//get cart

const initGetWishlist = () => {
  return {
    type: actionTypes.INIT_GET_WISHLIST,
    loading_wishlst: true,
  };
};

const successGetWishlist = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_WISHLIST,
    loading_wishlst: false,
    wishlist_data: data.data,
    error_wishlist: null,
  };
};

const failGetWishlist = (error) => {
  return {
    type: actionTypes.FAIL_GET_WISHLIST,
    loading_wishlst: false,
    wishlist_data: [],
    error_wishlist: error,
  };
};

export const getWishlist = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetWishlist());
    defaultAxios
      .get("wishlist", config)
      .then((response) => dispatch(successGetWishlist(response.data.data)))
      .catch((err) => {
        dispatch(failGetWishlist(err.message));
        catchError(err);
      });
  };
};
//delete
export const deleteWishlistItem = (id, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);
  return (dispatch) => {
    dispatch(initGetWishlist());
    defaultAxios
      .post("wishlist", formData, config)
      .then((response) => {
        addToast(getTranslation("Item removed from wishlist"), {
          appearance: "error",
          autoDismiss: true,
        });
        dispatch(getWishlist());
      })
      .catch((err) => catchError(err));
    //dispatch(failGetWishlist(err.message))
  };
};
//Add
export const addWishlistItem = (id, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();
  formData.append("product_id", id);
  return (dispatch) => {
    dispatch(initGetWishlist());
    defaultAxios
      .post("wishlist", formData, config)
      .then((response) => {
        if (response.data.status == "failed") {
          addToast(getTranslation("Please login first"), {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          addToast(getTranslation("Item added to wishlist"), {
            appearance: "success",
            autoDismiss: true,
          });
          dispatch(getWishlist());
        }
      })
      .catch((err) => {
        dispatch(failGetWishlist(err.message));
        console.log(err)
        if (err?.response?.status === 401) {
          addToast(getTranslation("Please login first"), {
            appearance: "error",
            autoDismiss: true,
          });
        }
        catchError(err);
      });
  };
};
