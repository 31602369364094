import { useEffect, useState } from "react";
import { Alert, Row, Col, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "next-i18next";


import { ProductGridListWrapper } from "../../components";
import { defaultAxios, catchError } from "../../utils";

const ShopProducts = ({
  products,
  layout,
  location,
  currentPage,
  gridType,
  currentCategory,
  currentSubCategory,
}) => {
  const { search, sorting } = useSelector((state) => state.products);
  const [productList, setProductList] = useState(products);
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(currentPage ?? 1);
  const { t } = useTranslation('common');

  const getMoreProduct = async () => {
    const res = await defaultAxios
      .get(
        gridType === "all"
          ? `search?search=${search ?? ""}&sorting=${sorting}&page=` + (parseInt(current) + 1)
          : gridType === "preloved"
          ? `preloved?search=${search ?? ""}&sorting=${sorting}&page=` + (parseInt(current) + 1)
          : `search?${
              currentSubCategory
                ? `sub_category_id=${currentSubCategory}`
                : `category_id=${currentCategory}`
            }&sorting=${sorting}&page=` +
            (parseInt(current) + 1)
      )
      .catch((e) => catchError(e));

    const newProducts = res?.data?.data?.product?.data;

    if (newProducts == null || newProducts == "undefined" || newProducts?.length === 0) {
      setHasMore(false);
    } else {
      setProductList((product) => [...product, ...newProducts]);
      setHasMore(true);
    }

    setCurrent(current + 1);
  };

  useEffect(() => {
    async function checkHasMore() {
      const res = await defaultAxios
        .get(
          gridType === "all"
            ? `search?search=${search ?? ""}&sorting=${sorting}&page=` + current
            : gridType === "preloved"
            ? `preloved?search=${search ?? ""}&sorting=${sorting}&page=` + current
            : `search?${
                currentSubCategory
                  ? `sub_category_id=${currentSubCategory}`
                  : `category_id=${currentCategory}`
              }&sorting=${sorting}&page=` + current
        )
        .catch((e) => catchError(e));

      const gotNextPage = res.data.data.product.next_page_url;
      //console.log(gotNextPage);
      if (gotNextPage == null) {
        setHasMore(false);
      }
    }

    checkHasMore();
  }, []);

  return (
    <div className="shop-products">
      <InfiniteScroll
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          paddingRight: "3px",
          paddingLeft: "3px",
        }}
        dataLength={productList.length}
        next={getMoreProduct}
        hasMore={hasMore}
        loader={
          <Row className="my-3">
            <Col className="text-center">
              <Spinner animation="border" className="text-primary" />
            </Col>
          </Row>
        }
        endMessage={
          <Row className="mt-2">
            <Col className="text-center">
              <h6>{t("End of the list")}</h6>
            </Col>
          </Row>
        }>
        <Row className={`${layout} gx-3 gy-3`}>
          {products.length == 0 ? (
            <Col className="d-flex flex-column align-items-center justify-content-center">
              <img
                src="/assets/images/no_products.png"
                alt="no products"
                className="my-3 img-fluid"
              />
              <Alert variant={"info"}>
                Ops. Seems like this category is empty. Please come back later.
              </Alert>
            </Col>
          ) : (
            <ProductGridListWrapper products={productList} layout={layout} location={location} />
          )}
        </Row>
      </InfiniteScroll>
    </div>
  );
};

export default ShopProducts;
