import { Fragment, useEffect } from "react";

export default function Variants({ product, setAttributeIds, attributeIds }) {
  const options = {};
  product.attribute_group.map((el) => {
    if (options[el.attribute_group_id])
      options[el.attribute_group_id] = [
        ...options[el.attribute_group_id],
        {
          group: el.group,
          attribute_id: el.attribute_id,
          attribute_label: el.attribute_label,
        },
      ];
    else
      options[el.attribute_group_id] = [
        {
          group: el.group,
          attribute_id: el.attribute_id,
          attribute_label: el.attribute_label,
        },
      ];
  });

  //console.log(product.attribute_group)
  //console.log(options)

  useEffect(() => {
    const initAttrIds = {};
    Object.entries(options).map(([key, val]) => {
      initAttrIds[key] = val[0].attribute_id;
    });
    setAttributeIds((prevState) => {
      return {
        ...initAttrIds,
      };
    });
  }, []);

  return attributeIds
    ? Object.entries(options).map(([key, val]) => {
        return (
          <div key={key} className="product-quickview__size-color my-3">
            <div className="product-quickview__size space-mb--20">
              <div className="product-quickview__size__title fw-bold">{val[0].group}</div>
              <div className="product-quickview__size__content">
                {val.map((option) => (
                  <Fragment key={option.attribute_id}>
                    <input
                      key={option.attribute_id}
                      name={key}
                      type="radio"
                      defaultChecked={attributeIds[key] === option.attribute_id}
                      value={option.attribute_label}
                      id={option.attribute_id}
                      onChange={(event) => {
                        setAttributeIds((prevState) => {
                          prevState[key] = option.attribute_id;
                          return { ...prevState };
                        });
                      }}
                    />
                    <label htmlFor={option.attribute_id} className="px-2 shadow-sm rounded">
                      {option.attribute_label}
                    </label>
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        );
      })
    : null;
}
