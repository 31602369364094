import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { postAddress } from "../../redux/actions/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import { phoneRegex } from "../../redux/actions/utils";
import stateData from "./state.json";
import areaData from "./area.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { findPostcode } from "malaysia-postcodes";
import { useTranslation } from "next-i18next";

const AddressModal = ({ show, handleClose, mode, editAddressData, type, address_shipping }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const [addressState, setAddressState] = useState({
    name: "",
    phone: "",
    line_1: "",
    line_2: "",
    line_3: "",
    postcode: "",
    city: "",
    state: "",
    country: "Malaysia",
    default: 0,
    type: type != "" ? type : "shipping",
  });

  const [selectedState, setSelectedState] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);

  const formik = useFormik({
    initialValues: addressState,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required."),
      phone: Yup.string()
        .matches(phoneRegex, "Please enter valid phone number")
        .required("Phone is required."),
      line_1: Yup.string().required("Field is required."),
      line_2: Yup.string().required("Field is required."),
      postcode: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(5, "Must be exactly 5 digits")
        .max(5, "Must be exactly 5 digits")
        .required("Postcode is required."),
      city: Yup.string().required("City is required."),
      state: Yup.string().required("State is required."),
      country: Yup.string().required("Country is required."),
    }),
    onSubmit: (values) => {
      if (addressState.type === "shipping" && address_shipping.length === 0) {
        addressState.default = 1;
      }

      formik.resetForm();
      dispatch(postAddress(addressState, addToast));
      resetState();
      handleClose();
    },
  });

  const resetState = () => {
    setAddressState({
      name: "",
      phone: "",
      line_1: "",
      line_2: "",
      line_3: "",
      postcode: "",
      city: "",
      state: "",
      country: "Malaysia",
      default: 0,
      type: type != "" ? type : "shipping",
    });
    setSelectedState(null);
    setSelectedArea(null);
  };

  useEffect(() => {
    if (editAddressData && type !== "") {
      setAddressState({
        _method: "PUT",
        id: editAddressData.id,
        default: editAddressData.default ?? 0,
        name: editAddressData.name ?? "",
        phone: editAddressData.phone ?? "",
        line_1: editAddressData.line_1 ?? "",
        line_2: editAddressData.line_2 ?? "",
        line_3: editAddressData.line_3 ?? "",
        postcode: editAddressData.postcode ?? "",
        city: editAddressData.city ?? "",
        state: editAddressData.state ?? "",
        country: editAddressData.country ?? "",
        type: type,
      });

      for (const [key, value] of Object.entries(editAddressData)) {
        formik.setFieldValue(key, value);
      }

      //console.log(stateData.find((state) => state.name.toLowerCase().trim() === editAddressData.state.toLowerCase().trim()).id);
      setSelectedState(
        stateData.find(
          (state) => state.name.toLowerCase().trim() === editAddressData.state.toLowerCase().trim()
        ).id ?? ""
      );
      setSelectedArea(
        areaData.find(
          (area) => area.name.toLowerCase().trim() === editAddressData.city.toLowerCase().trim()
        ).id ?? ""
      );
    } else if (!editAddressData && type !== "") {
      setAddressState({
        name: "",
        phone: "",
        line_1: "",
        line_2: "",
        line_3: "",
        postcode: "",
        city: "",
        state: "",
        country: "",
        default: 0,
        type: type,
      });

      for (const [key, value] of Object.entries(addressState)) {
        if (key === "default") {
          formik.setFieldValue(key, 0);
        } else if (key === "type") {
          formik.setFieldValue(key, type);
        } else {
          formik.setFieldValue(key, value);
        }
      }

      setSelectedState(null);
      setSelectedArea(null);
    }
  }, [editAddressData, type]);

  // useEffect(() => {
  //   if (type !== '') setAddressState({
  //     name: '',
  //     phone: '',
  //     line_1: '',
  //     line_2: '',
  //     line_3: '',
  //     postcode: '',
  //     city: '',
  //     state: '',
  //     country: '',
  //     default: 0,
  //     type: type
  //   })
  // }, [type])

  const changeHandler = ({ value, name }) => {
    if (name === "state" || name === "city") {
      let selectedName = "";
      if (name === "state") {
        setSelectedState(value);
        formik.setFieldValue("city", "");
        setSelectedArea(null);
        //console.log(stateData.find((state) => parseInt(state.id) === parseInt(value)))
        selectedName = stateData.find((state) => parseInt(state.id) === parseInt(value)).name;
      } else if (name === "city") {
        setSelectedArea(value);
        selectedName = areaData.find((area) => parseInt(area.id) === parseInt(value)).name;
      }

      setAddressState((prevState) => {
        return {
          ...prevState,
          city: "",
          [name]: selectedName,
        };
      });
      //console.log(value);
      formik.setFieldValue(name, selectedName);
    } else {
      if (name === "postcode") {
        const location = findPostcode(value);
        if (location.found) {
          console.clear();
          console.log({location});
          setSelectedState(
            stateData.find(
              (state) => state.name.toLowerCase().trim() === location.state.toLowerCase().trim()
            )?.id ?? ""
          );
          setSelectedArea(
            areaData.find(
              (area) => area.name.toLowerCase().trim() === location.city.toLowerCase().trim()
            )?.id ?? ""
          );

          setAddressState((prevState) => {
            return {
              ...prevState,
              state: location.state,
              city: location.city,
              country: "Malaysia",
            };
          });
          //console.log(value);
          formik.setFieldValue("state", location.state);
          formik.setFieldValue("city", location.city);
          formik.setFieldValue("country", "Malaysia");
        }
      }

      setAddressState((prevState) => {
        return {
          ...prevState,
          [name]: value,
        };
      });
      //console.log(value);
      formik.setFieldValue(name, value);
    }
  };

  useEffect(() => {
    formik.resetForm();
    resetState();
    //console.log(addressState);
  }, []);

  return (
    <Modal
      show={show}
      onHide={() => {
        formik.resetForm();
        resetState();
        handleClose();
      }}>
      <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
        <Modal.Header closeButton>
          <Modal.Title>
            {mode == "add" || mode === "add-from-outside" ? t("Add New Address") : "Edit Address"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Label>{t("Select address type")}:</Form.Label>
                  <Form.Check
                    type={"radio"}
                    id="shipping"
                    name="type"
                    label={t("Shipping")}
                    {...formik.getFieldProps("type")}
                    value="shipping"
                    defaultChecked={
                      addressState.type != "" ? addressState.type == "shipping" : true
                    }
                    onChange={(event) => changeHandler(event.target)}
                  />
                  {mode !== "add-from-outside" && (
                    <Form.Check
                      type={"radio"}
                      id="billing"
                      name="type"
                      label="Billing"
                      {...formik.getFieldProps("type")}
                      value="billing"
                      defaultChecked={addressState.type == "billing"}
                      onChange={(event) => changeHandler(event.target)}
                    />
                  )}
                </Col>
              </Form.Group>

              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={t("Name")}
                    name="name"
                    isValid={formik.touched.name && !formik.errors.name}
                    isInvalid={formik.touched.name && formik.errors.name}
                    {...formik.getFieldProps("name")}
                    value={addressState.name}
                    onChange={(event) => changeHandler(event.target)}
                  />
                  <Form.Control.Feedback type="invalid">{formik.errors.name}</Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <PhoneInput
                    name="phone"
                    isValid={formik.touched.phone && !formik.errors.phone}
                    isInvalid={formik.touched.phone && formik.errors.phone}
                    {...formik.getFieldProps("phone")}
                    value={addressState.phone}
                    onChange={(value, country, e, formattedValue) => {
                      //console.log(formattedValue);
                      setAddressState((prevState) => {
                        return {
                          ...prevState,
                          phone: formattedValue,
                        };
                      });
                      //console.log(value);
                      formik.setFieldValue("phone", formattedValue);
                    }}
                    country={"my"}
                    onlyCountries={["my"]}
                    preferredCountries={["my"]}
                    inputClass="w-100 form-control"
                    containerClass="w-100"
                    disableDropdown={true}
                    countryCodeEditable={false}
                    autoFormat={false}
                  />
                  {/* <Form.Control
                    type="text"
                    placeholder="Phone No."
                    name="phone"
                    isValid={formik.touched.phone && !formik.errors.phone}
                    isInvalid={formik.touched.phone && formik.errors.phone}
                    {...formik.getFieldProps("phone")}
                    value={addressState.phone}
                    onChange={(event) => changeHandler(event.target)}
                  /> */}
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.phone}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={t("Line 1")}
                    name="line_1"
                    isValid={formik.touched.line_1 && !formik.errors.line_1}
                    isInvalid={formik.touched.line_1 && formik.errors.line_1}
                    {...formik.getFieldProps("line_1")}
                    value={addressState.line_1}
                    onChange={(event) => changeHandler(event.target)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.line_1}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={t("Line 2")}
                    name="line_2"
                    isValid={formik.touched.line_2 && !formik.errors.line_2}
                    isInvalid={formik.touched.line_2 && formik.errors.line_2}
                    {...formik.getFieldProps("line_2")}
                    value={addressState.line_2}
                    onChange={(event) => changeHandler(event.target)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.line_2}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={t("Line 3")}
                    name="line_3"
                    {...formik.getFieldProps("line_3")}
                    value={addressState.line_3 ?? ""}
                    onChange={(event) => changeHandler(event.target)}
                  />
                </Col>
              </Form.Group>
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    placeholder={t("Postal Code")}
                    name="postcode"
                    isValid={formik.touched.postcode && !formik.errors.postcode}
                    isInvalid={formik.touched.postcode && formik.errors.postcode}
                    {...formik.getFieldProps("postcode")}
                    value={addressState.postcode}
                    onChange={(event) => changeHandler(event.target)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.postcode}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {selectedState && (
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Select
                      name="city"
                      isValid={formik.touched.city && !formik.errors.city}
                      isInvalid={formik.touched.city && formik.errors.city}
                      {...formik.getFieldProps("city")}
                      value={selectedArea}
                      onChange={(event) => changeHandler(event.target)}
                      readOnly>
                      <option value="">{t('Select City')}</option>
                      {areaData
                        .filter((area) => parseInt(area.state_id) === parseInt(selectedState))
                        .map((area) => {
                          return (
                            <option key={area.id} value={area.id}>
                              {area.name}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.city}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
              <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Select
                    name="state"
                    isValid={formik.touched.state && !formik.errors.state}
                    isInvalid={formik.touched.state && formik.errors.state}
                    {...formik.getFieldProps("state")}
                    value={selectedState}
                    onChange={(event) => changeHandler(event.target)}
                    readOnly>
                    <option value="">{t("Select State")}</option>
                    {stateData.map((state) => {
                      return (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.state}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {/* <Form.Group className="mb-3" as={Row}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Country"
                    name="country"
                    isValid={formik.touched.country && !formik.errors.country}
                    isInvalid={formik.touched.country && formik.errors.country}
                    {...formik.getFieldProps("country")}
                    value={addressState.country}
                    onChange={(event) => changeHandler(event.target)}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.country}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group> */}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => {
              formik.resetForm();
              resetState();
              handleClose();
            }}>
            {t("Cancel")}
          </Button>
          <Button variant="primary" size="sm" className="text-white" type="submit">
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddressModal;
