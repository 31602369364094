import axios from "axios";
import { AiFillBoxPlot } from "react-icons/ai";
import { defaultAxios } from "../../utils";
import { catchError, getTranslation } from "../../utils/helpers";
import * as actionTypes from "./actionTypes";
import { getDetails } from "./profile/detailsAction";
import { getToken } from "./utils";

const initGetPreloved = () => {
  return {
    type: actionTypes.INIT_GET_PRELOVED,
    loading_preloved_products: true,
  };
};

const successGetPreloved = (data) => {
  //data.forEach((prod) => (prod.preloved = true));
  return {
    type: actionTypes.SUCCESS_GET_PRELOVED,
    loading_preloved_products: false,
    preloved_products: data,
  };
};

const failGetPreloved = (error) => {
  return {
    type: actionTypes.FAIL_GET_PRELOVED,
    loading_preloved_products: false,
    error_preloved_products: error,
  };
};

export const getPreloved = () => {
  return (dispatch) => {
    dispatch(initGetPreloved());
    defaultAxios
      .get("preloved")
      .then((response) => {
        const tempData = response.data.data.data;
        for (let i = 0; i < tempData.length; i++) {
          tempData[i].preloved = true;
        }

        dispatch(successGetPreloved(tempData));
      })
      .catch((err) => {
        dispatch(failGetPreloved(err));
        catchError(err);
      });
  };
};

const initPrelovedCheckout = () => {
  return {
    type: actionTypes.INIT_PRELOVED_CHECKOUT,
    loadingPrelovedCheckout: true,
    errorPrelovedCheckout: null,
  };
};

const successPrelovedCheckout = (data) => {
  return {
    type: actionTypes.SUCCESS_PRELOVED_CHECKOUT,
    loadingPrelovedCheckout: false,
    errorPrelovedCheckout: null,
    prelovedCheckoutData: data,
  };
};

const failPrelovedCheckout = (error) => {
  return {
    type: actionTypes.FAIL_PRELOVED_CHECKOUT,
    loadingPrelovedCheckout: false,
    errorPrelovedCheckout: error,
    prelovedCheckoutData: null,
  };
};

export const clearPrelovedCheckout = () => {
  return (dispatch) => {
    dispatch(successPrelovedCheckout(null));
  };
};

export const prelovedCheckout = (id, shippingAddressId) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();

  formData.append("id", id);
  formData.append("shipping_vendor", 1);

  if (shippingAddressId != null) {
    formData.append("shipping_address", shippingAddressId);
  }

  return (dispatch) => {
    dispatch(initPrelovedCheckout());
    defaultAxios
      .post("preloved/checkout", formData, config)
      .then((response) => {
        dispatch(successPrelovedCheckout(response.data.data));
      })
      .catch((err) => {
        if (err.response) {
          dispatch(failPrelovedCheckout(err.response.data.message));
        } else {
          dispatch(failPrelovedCheckout(err.message));
        }
        //dispatch(failCheckout(err.message));
        catchError(err);
      });
  };
};

export const buyPreloved = (redirect, data) => {
  const token = getToken();
  if (!token) {
    return (dispatch) => redirect("/user/login");
  }
  // else return dispatch => redirect('/user/my-account')
  else {
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    return (dispatch, getState) => {
      const { profile } = getState();
      const { profile_details, address_shipping } = profile;
      data = {
        ...data,
        device: "web",
        payment_method: "fpx",
        payment_gateway: "TOYYIBPAY",
        currency: "MYR",
        shipping_vendor: 1,
      };
      if (profile_details && profile_details.profile && address_shipping.length > 0) {
        let shipping_address = null;
        try {
          shipping_address = address_shipping.filter((addrees) => addrees.default === 1)[0].id;
        } catch {
          shipping_address = address_shipping[0].id;
        }
        data = {
          ...data,
          shipping_address: shipping_address,
          name: profile_details.profile.name,
          email: profile_details.email ?? profile_details.profile.email,
        };
        dispatch(purchasePreloved(data));
      } else {
        const { profile } = getState();
        const { profile_details } = profile;
        data = {
          ...data,
          device: "web",
          payment_method: "fpx",
          payment_gateway: "TOYYIBPAY",
          currency: "MYR",
          shipping_vendor: 1,
          name: profile_details.name ??profile_details.profile.name,
          email: profile_details.email ?? profile_details.profile.email,
        };
        defaultAxios
          .get("customer/address", config)
          .then((response) => {
            const shippingData = response.data.data.shipping;
            let shipping_address = null;
            try {
              try {
                shipping_address = shippingData.filter((addrees) => addrees.default === 1)[0].id;
              } catch {
                shipping_address = shippingData[0].id;
              }
            } catch {
              return redirect("/user/my-account?no=address");
            }
            data = {
              ...data,
              shipping_address: shipping_address,
            };
            dispatch(purchasePreloved(data));
          })
          .catch((err) => {
            catchError(err);
            redirect("/user/my-account");
          });
      }
    };
  }
};

const purchasePreloved = (data) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  formData.append("id", data.preloved_id);
  formData.append("shipment_price", data.shipment_price);
  formData.append("shipping_address", data.shipping_address);
  formData.append("shipping_vendor", data.shipping_vendor);

  // for (let [key, val] of formData.entries()) {
  //   console.log(key, val);
  // }
  return (dispatch) => {
    defaultAxios
      .post("preloved/purchase", formData, config)
      .then((response) => dispatch(prelovedTransaction(data, response.data.data)))
      .catch((err) => catchError(err));
  };
};

const prelovedTransaction = (data, resData) => {
  const excludeKyes = ["seller_id", "shipping_vendor", "shipping_address"];
  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    console.log(key, val);
  });
  Object.entries(data).forEach(([key, val]) => {
    if (!excludeKyes.includes(key)) formData.append(key, val.toString());
  });
  //console.log(resData);
  formData.delete("preloved_orders_id");
  formData.append("preloved_orders_id", resData.preloved.id);
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return (dispatch) => {
    defaultAxios
      .post("preloved/purchase-transaction", formData, config)
      .then((response) => (window.location.href = response.data.data.gateway_redirect_url))
      .catch((err) => catchError(err));
  };
};

//############################################
//############################################
//Add preloved

const successAddPreloved = () => {
  return {
    type: actionTypes.SUCCESS_ADD_PRELOVED,
    success_add_prelove: true,
    error_add_prelove: null,
  };
};

const failAddPreloved = () => {
  return {
    type: actionTypes.FAIL_ADD_PRELOVED,
    success_add_prelove: null,
    error_add_prelove: true,
  };
};

export const addPreloved = (data, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  if (data.status) data.status = 1;
  else data.status = 0;

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => {
    if (key === "images") {
      val.forEach((img, i) => formData.append(`images[${i}]`, img));
    } else {
      formData.append(key, val);
    }
  });

  console.log(formData);

  return (dispatch, getState) => {
    const { profile } = getState();
    defaultAxios
      .post("preloved", formData, config)
      .then((res) => {
        dispatch(successAddPreloved());
        addToast(getTranslation("Preloved product added"), {
          appearance: "success",
          autoDismiss: true,
        });
        dispatch(getPrelovedProfileProducts(profile.profile_details.id));
      })
      .catch((err) => {
        catchError(err);
        dispatch(failAddPreloved());
        addToast(getTranslation("Please try again"), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
};

const initGetPrelovedProfileProducts = () => {
  return {
    type: actionTypes.INIT_GET_PROFILE_PRELOVED,
    loading_profile_preloved_products: true,
  };
};

const successGetPrelovedProfileProducts = (data) => {
  data.forEach((prod) => (prod.preloved = true));
  return {
    type: actionTypes.SUCCESS_GET_PROFILE_PRELOVED,
    loading_profile_preloved_products: false,
    profile_preloved_products: data,
  };
};

const failGetPrelovedProfileProducts = (error) => {
  return {
    type: actionTypes.FAIL_GET_PROFILE_PRELOVED,
    loading_profile_preloved_products: false,
    error_profile_preloved_products: error,
  };
};

export const getPrelovedProfileProducts = (id) => {
  return (dispatch) => {
    dispatch(initGetPrelovedProfileProducts());
    defaultAxios
      .get("preloved/seller/" + id)
      .then((response) => dispatch(successGetPrelovedProfileProducts(response.data.data)))
      .catch((err) => catchError(err));
  };
};

export const deletePreloved = (id) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  formData.append("_method", "DELETE");
  formData.append("id", id);
  return (dispatch, getState) => {
    const { profile } = getState();
    defaultAxios
      .post("preloved", formData, config)
      .then((res) => dispatch(getPrelovedProfileProducts(profile.profile_details.id)))
      .catch((err) => catchError(err));
  };
};

//############################################
//############################################
//Profile preloved Edit product:
export const editPreloved = (data, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  if (data.status) data.status = 1;
  else data.status = 0;
  if (data.lock_item) data.lock_item = 1;
  else data.lock_item = 0;

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));

  formData.append("_method", "PUT");
  return (dispatch, getState) => {
    const { profile } = getState();
    defaultAxios
      .post("preloved", formData, config)
      .then((res) => {
        dispatch(getPrelovedProfileProducts(profile.profile_details.id));
        addToast(getTranslation("Succes"), { appearance: "success", autoDismiss: true });
      })
      .catch((err) => {
        catchError(err);
        dispatch(failAddPreloved());
        addToast(getTranslation("Please try again "), {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };
};

//##############################
//##############################
//preloved history sell:

const successPrelovedSale = (data) => {
  return {
    type: actionTypes.SUCCESS_PRELOVED_SALES,
    preloved_sales: data,
  };
};
const failPrelovedSale = () => {
  return {
    type: actionTypes.FAIL_PRELOVED_SALES,
    preloved_sales_err: null,
  };
};

export const prelovedSales = (type) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return (dispatch) => {
    defaultAxios
      .get("preloved/history/sell/all/" + type, config)
      .then((res) => dispatch(successPrelovedSale(res.data.data)))
      .catch((err) => catchError(err));
  };
};
//##############################
//##############################
//preloved history buy:
const successPrelovedBuy = (data) => {
  return {
    type: actionTypes.SUCCESS_PRELOVED_BUYS,
    preloved_buys: data,
  };
};
const failPrelovedBuy = () => {
  return {
    type: actionTypes.FAIL_PRELOVED_BUYS,
    preloved_buys_err: null,
  };
};

export const prelovedBuys = (type) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    defaultAxios
      .get("preloved/history/buy/all/" + type, config)
      .then((res) => dispatch(successPrelovedBuy(res.data.data)))
      .catch((err) => catchError(err));
  };
};

//preloved update order seller:

export const sellerUpdateOreder = (data, prelovedCase, oneRequest) => {
  const token = getToken();
  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData1 = new FormData();
  Object.entries(data).forEach(([key, val]) => formData1.append(key, val));

  const formData2 = new FormData();
  formData2.append("preloved_order_id", data.preloved_order_id);
  formData2.append("type", "shipping_status");
  formData2.append("status", "pending_parcel");
  return (dispatch) => {
    defaultAxios
      .post("preloved/update/order/seller", formData1, config)
      .then((res) => {
        console.log(res.data);
        if (!oneRequest) {
          axios
            .post("preloved/update/order/seller", formData2, config)
            .then((res) => dispatch(prelovedSales(prelovedCase)));
        } else dispatch(prelovedSales(prelovedCase));
      })
      .catch((err) => catchError(err));
  };
};
//preloved update order Buyer:
export const buyerUpdateOreder = (data, prelovedCase) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();
  Object.entries(data).forEach(([key, val]) => formData.append(key, val));
  formData.append("status", "complete");
  return (dispatch) => {
    defaultAxios
      .post("preloved/update/order/buyer", formData, config)
      .then((res) => {
        dispatch(prelovedBuys(prelovedCase));
      })
      .catch((err) => catchError(err));
  };
};
