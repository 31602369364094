import axios from "axios";
import { defaultAxios } from "../../utils";

export const getToken = () => {
  if (localStorage.getItem("idToken")) {
    return localStorage.getItem("idToken");
  } else {
    return sessionStorage.getItem("idToken");
  }
};

export const phoneRegex =
  /^(\+601)[02-46-9]-*[0-9]{7}$|^(\+601)[1]-*[0-9]{8}$/gm;
