import { Card, Row, Col, Button, Media, Table } from "react-bootstrap";
import Link from "next/link";
import { BsCardList, BsChevronLeft } from "react-icons/bs";
import { FaMoneyCheck, FaTruck, FaInbox, FaStar } from "react-icons/fa";
import { useEffect, useMemo } from "react";
import { useTranslation } from "next-i18next";

const OrderDetail = ({ detailToggle, detailsItem, orderDetailType }) => {
  // let orderProccess = 0; switch (orderDetailType) {
  //   case 'all' : orderProccess = 0;break;
  //   case 'pay' : orderProccess = 1;break;
  //   case 'ship' : orderProccess = 2;break;
  //   case 'receive' : orderProccess = 3;break;

  // }

  const {t} = useTranslation('common')

  const {
    vendor_name,
    preloved,
    parcel_status,
    shipping_vendor_name,
    order_id,
    vendor_id,
  } = detailsItem;

  let totalPrice = 0;

  const formatedAdderes_1 = `${detailsItem.shipping_address_full.line_1}, ${
    detailsItem.shipping_address_full.line_2
  }, ${
    detailsItem.shipping_address_full.line_3
      ? detailsItem.shipping_address_full.line_3
      : ""
  }`;
  const formatedAdderes_2 = `${detailsItem.shipping_address_full.postcode}, ${detailsItem.shipping_address_full.city}, ${detailsItem.shipping_address_full.state}, ${detailsItem.shipping_address_full.country}`;

  useEffect(() => {
    if (!detailsItem) detailToggle(false);
  }, [detailsItem]);
  return (
    <>
      <Card.Header>
        <Row>
          <Col>
            <a
              onClick={() => {
                detailToggle();
              }}
              className="d-flex align-items-center"
            >
              <BsChevronLeft></BsChevronLeft> {t("Back")}
            </a>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row className="pb-3">
          <Col></Col>
          <Col>
            <Button
              className="float-right"
              variant={"outline-primary"}
              size={"sm"}
            >
              {t("Cancel Order")}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="letter-line"></div>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h5>{t("Delivery Address")}</h5>
            <p>
              {formatedAdderes_1}
              <br />
              {formatedAdderes_2}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="letter-line"></div>
          </Col>
        </Row>
        <Row className="pt-4 pb-3">
          <Col>
            <p className="float-right text-capitalize">{parcel_status}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <hr></hr>
          </Col>
        </Row>
        <Row className="py-4">
          <Col lg={4}>
            {shipping_vendor_name} <br />
            {order_id}
          </Col>
          <Col className="border-left">{t("No Delivery Logistics Information.")}</Col>
        </Row>
        {/* <Row className="order-header bg--grey pt-3">
          <Col lg={12} sm={12} xs={12}>
            {vendor_name}
            <Button variant="primary" size="sm" className="ms-2" onClick={() => { }}>
              Chat Now
            </Button>
            <Link href="/seller/shop-products">
              <Button variant="outline-primary" size="sm">
                View Shop
              </Button>
            </Link>
          </Col>
        </Row> */}
        <Row className="bg--grey">
          <Col>
            <hr></hr>
          </Col>
        </Row>

        <Row className="py-2 bg--grey">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <img
                width={64}
                height={64}
                className="me-3"
                src={preloved.thumbnail}
                alt="Generic placeholder"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h6 className="fw-bold">{preloved.name}</h6>
              <Row>
                <Col>
                  <p>
                    {preloved.variance && `Variation: ${preloved.variance}` && (
                      <br />
                    )}{" "}
                    x 1
                  </p>
                </Col>
                <Col className="d-flex justify-content-end text-primary">
                  <p>RM {preloved.price}</p>
                </Col>
              </Row>
            </div>
          </div>
        </Row>
      </Card.Body>
    </>
  );
};

export default OrderDetail;
