import { Fragment } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { addCartItem, addWishlistItem, deleteWishlistItem } from "../../redux/actions/index";
import { addToCompare, deleteFromCompare } from "../../redux/actions/compareActions";
import VendorProductGrid from "./VendorProductGrid";

const ProductGridWrapperFive = ({
  products,
  bottomSpace,
  addCartItem,
  user,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClass,
  type,
}) => {
  const { addToast } = useToasts();
  return type === "best-seller" ? (
    <Fragment>
      {products &&
        products.map((product) => {
          const productPrice = parseInt(product.product.price).toFixed(2);
          const cartItem = cartItems.filter((cartItem) => cartItem.id === product.product.id)[0];
          const wishlistItem = wishlistItems.filter(
            (wishlistItem) => wishlistItem.product_id === product.product.id
          )[0];
          const compareItem = compareItems.filter(
            (compareItem) => compareItem.id === product.product.id
          )[0];

          return (
            <VendorProductGrid
              key={product.product.id}
              product={product.product}
              productPrice={productPrice}
              cartItem={cartItem}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              bottomSpace={bottomSpace}
              addToWishlist={addToWishlist}
              deleteFromWishlist={deleteFromWishlist}
              addToCompare={addToCompare}
              deleteFromCompare={deleteFromCompare}
              addToast={addToast}
              cartItems={cartItems}
              sliderClass={sliderClass}
              addCartItem={addCartItem}
              user={user}
              totalSold={product.total}
              type={type}
            />
          );
        })}
    </Fragment>
  ) : (
    <Fragment>
      {products &&
        products.map((product) => {
          //console.log(product);
          const productPrice = parseInt(product.price).toFixed(2);
          const cartItem = cartItems.filter((cartItem) => cartItem.id === product.id)[0];
          const wishlistItem = wishlistItems.filter(
            (wishlistItem) => wishlistItem.product_id === product.id
          )[0];
          const compareItem = compareItems.filter(
            (compareItem) => compareItem.id === product.id
          )[0];

          return (
            <VendorProductGrid
              key={product.id}
              product={product}
              productPrice={productPrice}
              cartItem={cartItem}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              bottomSpace={bottomSpace}
              addToWishlist={addToWishlist}
              deleteFromWishlist={deleteFromWishlist}
              addToCompare={addToCompare}
              deleteFromCompare={deleteFromCompare}
              addToast={addToast}
              cartItems={cartItems}
              sliderClass={sliderClass}
              addCartItem={addCartItem}
              user={user}
            />
          );
        })}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart_items,
    wishlistItems: state.wishlistData.wishlist_data,
    compareItems: state.compareData,
    user: state.authData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCartItem: (data, addToast) => dispatch(addCartItem(data, addToast)),
    addToWishlist: (item, addToast) => {
      dispatch(addWishlistItem(item, addToast));
    },
    deleteFromWishlist: (item, addToast) => {
      dispatch(deleteWishlistItem(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
    deleteFromCompare: (item, addToast) => {
      dispatch(deleteFromCompare(item, addToast));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductGridWrapperFive);
