import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import { ProductGridListWrapper } from "../../components";
import InfiniteScroll from "react-infinite-scroll-component";
import { Row, Col, Spinner, Button } from "react-bootstrap";
import { getToken } from "../../redux/actions/utils";
import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { addParam } from "../../redux/actions";
import { useTranslation } from "next-i18next";

const ProductSliderEight = ({ title, products, currentPage, type }) => {
  const { t } = useTranslation("common");

  const router = useRouter();
  const dispatch = useDispatch();
  const [productList, setProductList] = useState(products);
  const [hasMore, setHasMore] = useState(true);
  const [current, setCurrent] = useState(currentPage);

  const getMoreProduct = async () => {
    if (type == "wishlist") {
      const token = getToken();

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const res = await defaultAxios
        .get("wishlist?page=" + (parseInt(current) + 1), config)
        .catch((e) => catchError(e));
      const newProducts = res.data.data.data;
      const gotNextPage = res.data.data.next_page_url;
      //console.log(gotNextPage);
      if (newProducts.length === 0 || gotNextPage == null) {
        setHasMore(false);
      } else {
        setProductList((product) => [...product, ...newProducts]);
        setHasMore(true);
      }

      setCurrent(current + 1);
    } else {
      if (current == 1) {
        const res = await defaultAxios
          .get("search?page=" + (parseInt(current) + 1))
          .catch((e) => catchError(e));
        const newProducts = res.data.data.product.data;
        if (newProducts.length === 0) {
          setHasMore(false);
        } else {
          setProductList((product) => [...product, ...newProducts]);
          setHasMore(false);
        }

        setCurrent(current + 1);
      } else {
        const res = await defaultAxios
          .get("search?page=" + (parseInt(current) + 1))
          .catch((e) => catchError(e));
        const newProducts = res.data.data.product.data;
        if (newProducts.length === 0) {
          setHasMore(false);
        } else {
          setProductList((product) => [...product, ...newProducts]);
          setHasMore(true);
        }

        setCurrent(current + 1);
      }
    }
  };

  useEffect(() => {
    if (type == "wishlist") {
      async function checkHasMore() {
        const token = getToken();

        const config = {
          headers: {
            Authorization: "Bearer " + token,
          },
        };
        const res = await defaultAxios
          .get("wishlist?page=" + parseInt(current), config)
          .catch((e) => catchError(e));

        const gotNextPage = res.data?.data?.product?.next_page_url;
        //console.log(gotNextPage);
        if (gotNextPage == null) {
          setHasMore(false);
        }
      }

      checkHasMore();
    }
  }, []);

  return (
    <Fragment>
      <div className="section-title section-title--style-two heading-s1 d-flex justify-content-between align-items-center space-mb--30">
        <h4>{title}</h4>
        {type != "wishlist" && (
            <a
                className="text-default"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(addParam("search", null, null, ""));
                  router.push("/shop/products")
                }}>
                {t("View All")}
              </a>
        )}
      </div>
      <div className="shop-products">
        <InfiniteScroll
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            paddingRight: "3px",
            paddingLeft: "3px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
          dataLength={productList.length}
          next={getMoreProduct}
          hasMore={hasMore}
          loader={
            <Row>
              <Col className="text-center">
                <Spinner animation="border" className="text-primary" />
              </Col>
            </Row>
          }
          endMessage={
            <Row className="mt-2">
              <Col className="text-center">
                <button
                  className="btn btn-light fw-bold shadow-sm px-5"
                  onClick={() => {
                    dispatch(addParam("search", null, ""));
                    router.push("/shop/products");
                  }}>
                  {t("See More")}
                </button>
              </Col>
            </Row>
          }>
          <div className="grid row gx-3 gy-2">
            <ProductGridListWrapper
              products={productList}
              layout="grid"
              location="home"
              type={type}
              bottomSpace="space-mb--10"
            />
          </div>
        </InfiniteScroll>
      </div>
    </Fragment>
  );
};

export default ProductSliderEight;
