import { getToken } from "../utils";
import * as actionTypes from "../actionTypes";
import { defaultAxios } from "../../../utils";
import { catchError, getTranslation } from "../../../utils/helpers";

const initGetDetails = () => {
  return {
    type: actionTypes.INIT_GET_DETAILS,
    loading_details: true,
  };
};

const successGetDetails = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_DETAILS,
    loading_details: false,
    profile_details: data,
  };
};

const failGetDetails = (error) => {
  return {
    type: actionTypes.FAIL_GET_DETAILS,
    loading_details: false,
    error_details: error,
  };
};

export const getDetails = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetDetails());
    defaultAxios
      .get("customer/profile", config)
      .then((response) => dispatch(successGetDetails(response.data.data)))
      .catch((err) => {
        catchError(err);
      });
  };
};

//post:

export const postDetails = (data, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();

  Object.entries(data).map(([key, val]) => {
    if (key === "avatar" && typeof val === "string") {
      return false;
    } else {
      formData.append(key, val);
    }
  });

  // for (let [key, val] of formData.entries()) {
  //   console.log(key, val);
  // }
  return (dispatch) => {
    defaultAxios
      .post("customer/profile", formData, config)
      .then((response) => {
        //console.log(response.data);
        dispatch(getDetails());
        addToast(getTranslation("Saved"), { appearance: "success", autoDismiss: true });
      })
      .catch((err) => {
        catchError(err);
      });
  };
};
