import { useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { BsShieldShaded } from "react-icons/bs";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import { Card, Row, Col, Button, Media } from "react-bootstrap";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "next-i18next";

import { buyerUpdateOreder, sellerUpdateOreder } from "../../redux/actions";
export default function PrelovedProductCard({
  detailToggle,
  vendorOrders,
  orderDetailType,
  mode,
}) {
  const { vendor_name, preloved, id } = vendorOrders;
  const [mobile, setMobile] = useState(false);
  const [rating, setRating] = useState(3);
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t } = useTranslation('common');
  useEffect(() => {
    // Update the document title using the browser API
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  return (
    <Card className="order-card">
      <Card.Body>
        <Row className="my-4">
          <Col>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <img
                  width={64}
                  height={64}
                  className="me-3"
                  src={preloved.thumbnail}
                  alt="Generic placeholder"
                />
              </div>
              <div className="flex-grow-1 ms-3">
                <h6 className="fw-bold">{preloved.name}</h6>
                <Row>
                  <Col>
                    <p>
                      {preloved.variance &&
                        `Variation: ${preloved.variance}` && <br />}{" "}
                      x 1
                    </p>
                  </Col>
                  <Col className="d-flex justify-content-end text-primary">
                    <p>RM {preloved.price}</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col className="d-flex justify-content-end align-items-center">
            <h5 className="me-2 text-primary">
              <BsShieldShaded></BsShieldShaded>
            </h5>
            <h5 className="font-weight-normal me-3">{t("Order Total")}:</h5>
            <h5 className="text-primary">RM {preloved.price}</h5>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <Button
              variant={"outline-primary"}
              size={"sm"}
              className={mobile == true ? "ms-0 mt-2" : ""}
              onClick={() => {
                detailToggle(vendorOrders);
              }}
            >
              {t("View Order Details")}
            </Button>
            {orderDetailType === "ship" && mode === "sales" && (
              <Button
                variant={"primary"}
                size={"sm"}
                className={mobile == true ? "ms-0 mt-2" : ""}
                onClick={() => {
                  const data = {
                    preloved_order_id: id,
                    type: "order_status",
                    status: "complete",
                  };
                  dispatch(sellerUpdateOreder(data, orderDetailType));
                }}
              >
                {t("Shipped")}
              </Button>
            )}
            {orderDetailType === "receive" && mode === "sales" && (
              <Button
                variant={"primary"}
                size={"sm"}
                className={mobile == true ? "ms-0 mt-2" : ""}
                onClick={() => {
                  const data = {
                    preloved_order_id: id,
                    type: "shipping_status",
                    status: "complete",
                  };
                  dispatch(sellerUpdateOreder(data, orderDetailType, true));
                }}
              >
                {t("Recived")}
              </Button>
            )}

            {orderDetailType === "complete" && mode === "buys" && (
              <form
                className="row mt-3"
                onSubmit={(event) => {
                  event.preventDefault();
                  const data = {
                    preloved_order_id: id,
                    rating: rating,
                  };
                  if (rating > 0)
                    dispatch(buyerUpdateOreder(data, orderDetailType));
                  else
                    addToast(tr("Invalid"), {
                      appearance: "error",
                      autoDismiss: true,
                    });
                }}
              >
                <div className="form-group mt-2 col">
                  <span className="product-rating me-2">
                    {rating === 0 ? (
                      <IoIosStarOutline
                        onClick={() => {
                          setRating(1);
                        }}
                      />
                    ) : (
                      <IoIosStar
                        onClick={() => {
                          setRating(0);
                        }}
                      />
                    )}
                    {rating < 2 ? (
                      <IoIosStarOutline
                        onClick={() => {
                          setRating(2);
                        }}
                      />
                    ) : (
                      <IoIosStar
                        onClick={() => {
                          setRating(1);
                        }}
                      />
                    )}
                    {rating < 3 ? (
                      <IoIosStarOutline
                        onClick={() => {
                          setRating(3);
                        }}
                      />
                    ) : (
                      <IoIosStar
                        onClick={() => {
                          setRating(2);
                        }}
                      />
                    )}
                    {rating < 4 ? (
                      <IoIosStarOutline
                        onClick={() => {
                          setRating(4);
                        }}
                      />
                    ) : (
                      <IoIosStar
                        onClick={() => {
                          setRating(3);
                        }}
                      />
                    )}
                    {rating < 5 ? (
                      <IoIosStarOutline
                        onClick={() => {
                          setRating(5);
                        }}
                      />
                    ) : (
                      <IoIosStar
                        onClick={() => {
                          setRating(4);
                        }}
                      />
                    )}
                  </span>
                  <Button
                    variant={"primary"}
                    type="submit"
                    size={"sm"}
                    className={mobile == true ? "ms-0 mt-2" : ""}
                  >
                    {t("Rate")}
                  </Button>
                </div>
              </form>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
