import { useEffect, useRef } from "react";
import { Row, Col, Form, Dropdown } from "react-bootstrap";
import { AiFillShop } from "react-icons/ai";
import {
  FaApple,
  FaAndroid,
  FaWindows,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa";
import { animateScroll } from "react-scroll";
import classes from "../Header.module.css";
import { useTranslation } from "next-i18next";
import DropdownLanguage from "../../DropdownLanguage";

const HeaderTopThree = ({ containerClass, user, partner }) => {
  const { t } = useTranslation("common");

  const scrollToBottom = () => {
    animateScroll.scrollToBottom();

    setTimeout(() => {
      animateScroll.scrollToBottom();
    }, 2000);

    setTimeout(() => {
      animateScroll.scrollToBottom();
    }, 3000);
  };

  return (
    <div className={`top-header pb-0 d-none d-lg-block bg--dark light-skin ${classes.TopHeader}`}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <Row>
          <Col
            className={`${user ? "col-3" : "col-2"}`}
            style={{ marginRight: user ? "3.5rem" : "6.2rem" }}></Col>
          <Col>
            <div className="header-topbar-info d-flex align-items-center">
              {partner === "/" && (
                <>
                  <a href="https://pcari.my/">
                    <img
                      src="/assets/images/logoWhite.png"
                      style={{ height: "1.5rem" }}
                      className="pe-2 me-2 border-end"
                    />
                  </a>
                  <a
                    className="text-white pe-2 me-2 border-end"
                    href={process.env.NEXT_PUBLIC_SELLER_URL}
                    target={"_blank"}
                    rel="noopener noreferrer">
                    {t("Seller Centre")}
                  </a>
                  <a
                    onClick={() => {
                      scrollToBottom();
                    }}
                    rel="noopener noreferrer"
                    className="text-white pe-2 me-2 border-end"></a>
                  <p className="text-white pb-0 mb-0 me-1">{t("Follow us on")} :</p>
                  <div className={"${classes.Icons} d-flex"}>
                    <div className="pe-3">
                      <a
                        href="https://www.facebook.com/pcari.my/"
                        target={"_blank"}
                        rel="noopener noreferrer">
                        <FaFacebook color={"white"} size={15} className="me-1"></FaFacebook>
                      </a>
                      <a
                        href="https://twitter.com/pcari_official"
                        target={"_blank"}
                        rel="noopener noreferrer">
                        <FaTwitter color={"white"} size={15} className="me-1"></FaTwitter>
                      </a>
                      <a
                        href="https://www.instagram.com/pcari.my/?hl=en"
                        target={"_blank"}
                        rel="noopener noreferrer">
                        <FaInstagram color={"white"} size={15} className="me-1"></FaInstagram>
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCwZF0pzxwU-1eG3BXYpBWtg"
                        target={"_blank"}
                        rel="noopener noreferrer"
                        className="pe-3 border-end">
                        <FaYoutube color={"white"} size={15} className="me-1"></FaYoutube>
                      </a>
                    </div>
                  </div>
                </>
              )}
              <DropdownLanguage />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HeaderTopThree;
