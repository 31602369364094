import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "next-i18next";

import { addParam } from "../../redux/actions";

const ShopHeader = ({ layoutClass, currentCategory, currentSubCategory, gridType }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation('common');
  //const [categoryId, setCategoryId] = useState(currentCategory);

  return (
    <div className="shop-header-area">
      <Row className={`align-items-center mb-4 pb-1 ${layoutClass ? layoutClass : ""}`}>
        <Col>
          <div className="shop-header">
            <div className="shop-header__left">
              <Form.Select
                aria-label="Default select example"
                onChange={(e) =>
                  dispatch(addParam("sorting", currentCategory, currentSubCategory, e.target.value))
                }
                size="sm">
                <option value="latest_to_oldest">{t("Latest")}</option>
                <option value="oldest_to_latest">{t("Oldest")}</option>
                <option value="expensive_to_cheap">{t("Price - High to Low")}</option>
                <option value="cheap_to_expensive">{t("Price - Low to High")}</option>
              </Form.Select>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ShopHeader;
