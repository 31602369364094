import { Card, Modal, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "next-i18next";

const PrelovedShippingModal = ({
  show,
  handleClose,
  shippingOptions,
  checkoutItems,
  currentSelectedItem,
  currentSelectedOption,
  setModifiedCheckoutItems,
  setSelectedOptions,
}) => {
const { t } = useTranslation('common');

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("Select Shipping Courier")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {shippingOptions.map((option, i) => {
          return (
            <Row className="my-3" key={i}>
              <Col>
                <Card
                  className={`shadow-sm ${option.selected == true ? "border-primary" : ""}`}
                  onClick={() => {
                    let selectedOption = { ...option };
                    let checkoutItemsCopy = { ...checkoutItems };
                    let selectedItemCopy = { ...currentSelectedItem };
                    let optionCopy = [...shippingOptions];
                    selectedOption.selected = true;
                    let previousSelected = optionCopy.find((element) => element.selected == true);
                    if (previousSelected != null) {
                      previousSelected.selected = false;
                    }

                    optionCopy[optionCopy.indexOf(previousSelected)] = previousSelected;
                    optionCopy[i] = selectedOption;

                    selectedItemCopy.shipping_rate_id = selectedOption.rate_id;
                    selectedItemCopy.shipping_service_id = selectedOption.service_id;
                    selectedItemCopy.shipping_courier_name = selectedOption.courier_name;
                    selectedItemCopy.shipping_subtotal = selectedOption.shipment_price;

                    checkoutItemsCopy = selectedItemCopy;
                    checkoutItemsCopy.shipping_subtotal = selectedOption.shipment_price;
                    checkoutItemsCopy.shipping_service_id = selectedOption.service_id;
                    checkoutItemsCopy.shipping_courier_name = selectedOption.courier_name;
                    setSelectedOptions(optionCopy);

                    checkoutItemsCopy.total_payment =
                      parseFloat(checkoutItemsCopy.merchandise_subtotal ?? 0.0) +
                      checkoutItemsCopy.shipping_subtotal;

                    //setModifiedCheckoutItems(copyModifiedCheckoutItems);
                    console.log(checkoutItemsCopy);
                    setModifiedCheckoutItems(checkoutItemsCopy);
                    //console.log(checkoutItemsCopy);
                    handleClose();
                  }}>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 me-3">
                        <h5>{option.courier_name}</h5>
                        <p className="p-0 m-0">Estimated delivery days: {option.delivery}</p>
                        <p>
                          {option.shipment_price
                            ? "Rate: RM " + option.shipment_price.toFixed(2)
                            : ""}
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <img
                          src={option.courier_logo}
                          className="mr-3"
                          alt="courier"
                          width="120px"
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          );
        })}
      </Modal.Body>
    </Modal>
  );
};

export default PrelovedShippingModal;
