import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import VendorModal from "./elements/VendorModal";
import { useRouter } from "next/router";
import { ProductRating } from "../Product";
import { Card, Button, Row, Col } from "react-bootstrap";
import { FaGripfire, FaHeart } from "react-icons/fa";
import { BsHeart } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import LazyLoad from "react-lazyload";

const VendorProductGrid = ({
  product,
  productPrice,
  cartItem,
  wishlistItem,
  compareItem,
  bottomSpace,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  addToast,
  cartItems,
  sliderClass,
  addCartItem,
  user,
  type,
  totalSold,
}) => {
  const router = useRouter();

  const [modalShow, setModalShow] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const inCart = cartItems.filter((item) => item.product_id === product.id).length > 0;

  let href = `/shop/product/${product.id}`;

  if (product.preloved) href = `/shop/preloved/${product.id}/${product.customer_id}`;

  return (
    <Fragment>
      <div className={`${sliderClass ? sliderClass : ""} ${bottomSpace ? bottomSpace : ""}`}>
        <LazyLoad>
          <Link href={href} passHref>
            <Card
              style={{
                borderRadius: "5px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
                border: 'none'
              }}
              className={`h-100 ${type != null && type == "best-seller" ? "border-danger" : ""}`}>
              <Card.Img
                variant="top"
                src={product.thumbnail}
                height="180px"
                style={{ borderRadius: "5px", objectFit: "cover" }}
              />
              {type != null && type == "best-seller" && (
                <div className="sold-tag">Monthly Sales: {totalSold}</div>
              )}
              <Card.Body className=" --postiion-relative">
                {type != null && type == "best-seller" && (
                  <div className="tag-green px-2 d-flex align-items-center --top-left">
                    {/* <FaGripfire size={20} className="me-1" />  */}
                    <div>TOP</div>
                  </div>
                )}
                <Row className="product-grid__info mt-4">
                  <Col>
                    <h6 className="product-title text-truncate pt-5s">
                      <Link
                        href={href}
                        // as={"/shop/product/" + product.id}
                      >
                        <a>{product.name}</a>
                      </Link>
                    </h6>
                    <div className="product-price text-primary fw-bold text-truncate">
                      {product.discount ? (
                        <Fragment>
                          <span className="price">RM {discountedPrice}</span>
                          <del>RM {productPrice}</del>
                          <span className="on-sale">{product.discount}% Off</span>
                        </Fragment>
                      ) : (
                        <>
                          {product.type === "configurable" && product.price_range ? (
                            <>
                              {product.price_range.min === product.price_range.max ? (
                                <>
                                  <span className="price-currency">RM</span>
                                  <span className="price">{productPrice}</span>
                                </>
                              ) : (
                                <>
                                  <span className="price-currency">RM</span>
                                  <span className="price">{product.price_range.min}</span> -{" "}
                                  <span className="price-currency">RM</span>
                                  <span className="price">{product.price_range.max}</span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <span className="price-currency">RM</span>
                              <span className="price">{productPrice}</span>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <Row className="mt-2">
                      <Col
                        xs={10}
                        sm={10}
                        md={10}
                        lg={10}
                        className="d-flex align-items-center"
                        style={{ fontSize: "10px" }}>
                        <ImLocation color={"#026D5E"}></ImLocation>{" "}
                        <span className="text-truncate">
                          {product.vendor != null
                            ? product.vendor.company_address_state ?? "-"
                            : "-"}
                        </span>
                      </Col>
                      <Col className="p-0 d-flex align-items-center justify-content-center ">
                        <div
                          onClick={
                            wishlistItem !== undefined
                              ? (e) => {
                                  e.preventDefault(), deleteFromWishlist(wishlistItem.id, addToast);
                                }
                              : (e) => {
                                  e.preventDefault(), addToWishlist(product.id, addToast);
                                }
                          }
                          className={`d-flex align-items-center justify-content-center ${
                            wishlistItem !== undefined ? "active" : ""
                          }`}>
                          {wishlistItem === undefined ? (
                            <BsHeart color={"#32baa5"}></BsHeart>
                          ) : (
                            <FaHeart color={"#32baa5"}></FaHeart>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Link>
        </LazyLoad>
      </div>
      {/* product modal */}
      <VendorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        productPrice={productPrice}
        cartitems={cartItems}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtowishlist={addToWishlist}
        deletefromwishlist={deleteFromWishlist}
        addtocompare={addToCompare}
        deletefromcompare={deleteFromCompare}
        addtoast={addToast}
        addCartItem={addCartItem}
        user={user}
      />
    </Fragment>
  );
};

export default VendorProductGrid;
