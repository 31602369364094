import { Fragment } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

import { logoutAuth } from "../../../redux/actions/index";
import classes from "../Header.module.css";
import { useTranslation } from "next-i18next";

const MiniCart = ({}) => {
  const { t } = useTranslation("common");

  const router = useRouter();

  const dispatch = useDispatch();
  return (
    <Fragment>
      <div className="cart-box" style={{ width: "max-content" }}>
        <ul className={classes.mini_account_list}>
          <li onClick={() => router.push("/user/my-account?tab=orders")}>{t("Account")}</li>
          <li
            onClick={() => {
              router.push("/");
              dispatch(logoutAuth());
            }}>
            {t("Log Out")}
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default MiniCart;
