import { Fragment, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { animateScroll } from "react-scroll";
import { IoIosArrowUp } from "react-icons/io";
import {
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiOutlineYoutube,
  AiOutlineInstagram,
} from "react-icons/ai";
import Link from "next/link";
import { FaApple, FaPlay } from "react-icons/fa";
import { useTranslation } from "next-i18next";

const FooterFive = () => {
  const { t } = useTranslation("common");

  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <Fragment>
      <footer className="bg--grey" id="footer">
        <div className="footer-top footer-top--style-two">
          <div className="container">
            {/* <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <h5>Follow Us On:</h5>
              </Col>
            </Row> */}
            <Row className="gy-3">
              <Col className="d-flex flex-column" lg md sm={12}>
                <h5 className="fw-bold">{t('About')} pcari.my</h5>
                <Link href="/faqs" passHref>
                  <a>FAQ</a>
                </Link>
                <Link href="/terms-and-conditions" passHref>
                  <a>{t('Terms and Conditions')}</a>
                </Link>
                <Link href="/privacy-policy" passHref>
                  <a>Privacy Policy</a>
                </Link>
              </Col>
              <Col lg md sm={12}>
                <Row>
                  <Col>
                    <h5 className="fw-bold">{t('Payment')}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src="/assets/footer/master-card.png" alt="payment" className="py-2 px-2" />
                    <img src="/assets/footer/fpx.png" alt="payment" className="py-2 px-2" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src="/assets/footer/maybank.png" alt="payment" className="py-2 px-2" />
                    <img src="/assets/footer/cimb.png" alt="payment" className="py-2 px-2" />
                  </Col>
                </Row>
              </Col>
              <Col lg md sm={12}>
                <Row>
                  <Col>
                    <h5 className="fw-bold">{t('Logistics')}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src="/assets/footer/aramex.png" alt="shipping" className="py-2 px-2" />
                    <img src="/assets/footer/fedex.png" alt="shipping" className="py-2 px-2" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src="/assets/footer/zepto.png" alt="shipping" className="py-2 px-2" />
                    <img src="/assets/footer/dhl.png" alt="shipping" className="py-2 px-2" />
                    <img src="/assets/footer/jtexpress.png" alt="shipping" className="py-2 px-2" />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <img src="/assets/footer/skynet.png" alt="shipping" className="py-2 px-2" />
                    <img src="/assets/footer/ninja-van.png" alt="shipping" className="py-2 px-2" />
                  </Col>
                </Row>
              </Col>
              <Col className="d-flex flex-column" lg md sm={12}>
                <h5 className="fw-bold">{t('Follow Us')}</h5>
                <a
                  href="https://www.facebook.com/pcari.my/"
                  target={"_blank"}
                  rel="noopener noreferrer">
                  <img src="/assets/footer/fb.svg" alt="payment" width="16px" className="me-3" />
                  Facebook
                </a>
                <a
                  href="https://twitter.com/pcari_official"
                  target={"_blank"}
                  rel="noopener noreferrer">
                  <img
                    src="/assets/footer/twitter.svg"
                    alt="payment"
                    width="16px"
                    className="me-3"
                  />
                  Twitter
                </a>
                <a
                  href="https://www.instagram.com/pcari.my/?hl=en"
                  target={"_blank"}
                  rel="noopener noreferrer">
                  <img src="/assets/footer/ig.svg" alt="payment" width="16px" className="me-3" />
                  Instagram
                </a>
                <a
                  href="https://www.youtube.com/channel/UCwZF0pzxwU-1eG3BXYpBWtg"
                  target={"_blank"}
                  rel="noopener noreferrer">
                  <AiOutlineYoutube size={20} className="me-3"></AiOutlineYoutube> Youtube
                </a>
              </Col>
              <Col lg md sm={12}>
                <Row>
                  <Col>
                    <h5 className="fw-bold">{t("Download Our App Now")}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column">
                    <a
                      href="https://apps.apple.com/my/app/pcari-my/id1536313176"
                      target={"_blank"}
                      rel="noopener noreferrer">
                      <img
                        src="/assets/footer/apple.svg"
                        alt="payment"
                        width="16px"
                        className="me-3"
                      />
                      Apple Store
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.pcari.pcari_user"
                      target={"_blank"}
                      rel="noopener noreferrer">
                      <img
                        src="/assets/footer/play.svg"
                        alt="payment"
                        width="16px"
                        className="me-3"
                      />
                      Play Store
                    </a>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <h5 className="fw-bold">{t("Sell Your Products")}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex flex-column">
                    <a
                      href={process.env.NEXT_PUBLIC_SELLER_URL}
                      target={"_blank"}
                      rel="noopener noreferrer">
                      {t("Sign up here to be a seller!")}
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="footer-bottom space-pt--10 space-pb--30">
          <div className="container">
            <Row>
              <Col className="d-flex justify-content-center align-items-center">
                <p className="text-center mb-3 mb-lg-0">
                  {t('Copyright')} &copy; {new Date().getFullYear() + " "}
                  <a href="https://www.pcari.my">. pcari.my</a>
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <button
          className={`scroll-top ${scroll > top ? "show" : ""}`}
          onClick={() => scrollToTop()}>
          <IoIosArrowUp />
        </button>
      </footer>
    </Fragment>
  );
};

export default FooterFive;
