import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Transition } from "react-transition-group";
import { useTranslation } from "next-i18next";

import { getShopProducts } from "../../redux/actions";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const TopBar = ({ categories, productList, setProductList, vendorId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [expand, setExpand] = useState(false);
  const duration = 500;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <div className="topbar">
      <div className="widget">
        {categories.length > 0 ? (
          <div className="widget__categories d-flex align-items-center flex-wrap">
            <div className={`widget__child my-2 mx-3 ${productList == "all" ? "active" : ""}`}>
              <button
                onClick={(e) => {
                  setProductList("all");
                  dispatch(getShopProducts(vendorId, "all"));
                }}>
                {/* <IoIosArrowForward /> */}
                <span className="categories-name">{t("All")}</span>
                {/* <span className="categories-num">({countItems(products)})</span> */}
              </button>
            </div>
            {categories &&
              categories.map((category, key) => {
                return key < 6 ? (
                  <div
                    key={key}
                    className={`widget__child my-2 mx-3 ${
                      productList == category.id.toString() ? "active" : ""
                    }`}>
                    <button
                      onClick={(e) => {
                        if (vendorId) {
                          setProductList(category.id.toString());
                          dispatch(getShopProducts(vendorId, category.id));
                        }
                      }}>
                      {/* <IoIosArrowForward /> */}
                      <span className="categories-name">{category.name}</span>
                      {/* <span className="categories-num">({category.id})</span> */}
                    </button>
                  </div>
                ) : (
                  <Transition in={expand} timeout={duration} key={key} unmountOnExit>
                    {(state) => (
                      <div
                        style={{
                          ...defaultStyle,
                          ...transitionStyles[state],
                        }}
                        className={`widget__child my-2 mx-3 ${
                          productList == category.id.toString() ? "active" : ""
                        }`}>
                        <button
                          onClick={(e) => {
                            if (vendorId) {
                              setProductList(category.id.toString());
                              dispatch(getShopProducts(vendorId, category.id));
                            }
                          }}>
                          {/* <IoIosArrowForward /> */}
                          <span className="categories-name">{category.name}</span>
                          {/* <span className="categories-num">({category.id})</span> */}
                        </button>
                      </div>
                    )}
                  </Transition>
                );
              })}
            {categories.length > 5 && (
              <Button
                //className="text-white"
                size="sm"
                onClick={() => setExpand(!expand)}
                style={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "100%",
                  backgroundColor: "#ffffff",
                  color: "#32BAA5",
                  border: "0",
                }}>
                {!expand ? <FaChevronRight size="20" /> : <FaChevronLeft size="20" />}
              </Button>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default TopBar;
