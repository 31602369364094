import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import * as actionTypes from "./actionTypes";

const initGetCategories = () => {
  return {
    type: actionTypes.INIT_GET_CATEGORIES,
    loading: true,
  };
};

const successGetCategories = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_CATEGORIES,
    data: data,
    loading: false,
  };
};

const failGetCategories = (error) => {
  return {
    type: actionTypes.FAIL_GET_CATEGORIES,
    loading: false,
    error: error,
  };
};

export const getCategories = () => {
  return (dispatch) => {
    dispatch(initGetCategories());

    defaultAxios
      .get("category/")
      .then((response) => dispatch(successGetCategories(response.data.data)))
      .catch((err) => {
        catchError(err);
      });
  };
};
