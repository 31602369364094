import { useEffect, useState, Fragment } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getProduct } from "../../redux/actions/index";
import ProductVariant from "../ProductThumb/elements/ProductVariants";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";

export default function CartModal({ productCart, show, onHide, addToast, addCartItem, user }) {
  const { t } = useTranslation("common");

  const dipatch = useDispatch();

  const { loading_product, product, error_product } = useSelector((state) => state.products);
  //
  const [productStock, setProductStock] = useState(product ? product.stock : 0);
  const [quantityCount, setQuantityCount] = useState(1);

  const [configurableId, setConfigurableId] = useState(null);

  //

  const [attributeIds, setAttributeIds] = useState(null);

  useEffect(() => {
    if (product && product.type === "configurable" && attributeIds) {
      const getStockIds = () => {
        const attributeIdsArtt = [];

        Object.values(attributeIds).forEach((val) => attributeIdsArtt.push(val));

        let result = {};

        product.configurable.map((varaint) => {
          const varAttriIds = [];

          varaint.attribute.forEach((varAttri) => varAttriIds.push(varAttri.attribute_id));

          const filteredArray = attributeIdsArtt.filter((value) => varAttriIds.includes(value));

          if (filteredArray.length === attributeIdsArtt.length)
            result = {
              product_configurable_id: varaint.id,
              product_id: product.id,
              stock: varaint.stock,
            };
        });
        return result;
      };
      const { product_configurable_id, stock, product_id } = getStockIds();
      //console.log(product_configurable_id, stock, product_id);

      setProductStock(stock);
      setQuantityCount(1);
      setConfigurableId(product_configurable_id);
    }
  }, [attributeIds]);
  //

  useEffect(() => {
    if (productCart) dipatch(getProduct(productCart.product_id));
  }, [productCart]);

  return (
    <Modal show={show} onHide={onHide} className="cart-quickview" centered>
      <Modal.Body>
        <Modal.Header closeButton></Modal.Header>
        <Row>
          {product && (
            <Col>
              <div className="product-quickview__content">
                <h2 className="product-quickview__title space-mb10">{product.name}</h2>
                <div className="product-quickview__price rating-wrapper space-mb-10">
                  <div className="product-quickview__price dflex align-items-center">
                    <span className="price">RM {product.price}</span>
                  </div>
                </div>
                <div className="product-quickview__description spacemb20">
                  <p>{parse(`${product.short_description}`)}</p>
                </div>

                {product.type === "configurable" && (
                  <ProductVariant
                    product={product}
                    attributeIds={attributeIds}
                    setAttributeIds={setAttributeIds}
                  />
                )}
                <hr />
                <Fragment>
                  <div className="product-quickview__buttonwrapper d-flex align-items-center">
                    <div className="product-quickview__quantity">
                      <div className="cart-plus-minus">
                        <button
                          onClick={() =>
                            setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
                          }
                          className="qtybutton">
                          -
                        </button>
                        <input
                          className="cart-plus-minus-box"
                          type="text"
                          value={quantityCount}
                          readOnly
                        />
                        <button
                          onClick={() =>
                            setQuantityCount(
                              quantityCount < productStock ? quantityCount + 1 : quantityCount
                            )
                          }
                          className="qtybutton">
                          +
                        </button>
                      </div>
                    </div>
                    {product.type === "configurable" ? (
                      <button
                        className="btn btn-fill-out btn-addtocart"
                        onClick={() => {
                          if (!user) {
                            return router.replace("/user/login");
                          }
                          const data = {
                            product_configurable_id: configurableId,
                            product_id: product.id,
                            quantity: quantityCount,
                          };
                          addCartItem({
                            product_id: productCart.product_id,
                            product_configurable_id: productCart.product_configurable_id,
                            vendor_id: productCart.vendor_id,
                            quantity: 0,
                          });
                          addCartItem(data, addToast);
                          onHide(true);
                        }}>
                        {t("Add to Cart")}
                      </button>
                    ) : (
                      <button disabled className="btn btn-fill-out btn-addtocart">
                        {t("Out of stock")}
                      </button>
                    )}
                  </div>
                </Fragment>
              </div>
            </Col>
          )}
        </Row>
      </Modal.Body>
    </Modal>
  );
}
