import { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { Row, Card, Col, Form, Button, Table } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { useToasts } from "react-toast-notifications";

import { refundOrder } from "../../redux/actions";

export default function RefundOrderForm({
  detailToggle,
  detailsItem,
  orderDetailType,
  setMessageModal,
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { addToast } = useToasts();

  let totalPrice = 0;

  const [images, setImages] = useState([
    { id: "image1", file: null },
    { id: "image2", file: null },
    { id: "image3", file: null },
    { id: "image4", file: null },
  ]);

  const cancelReasons = [
    {
      id: 1,
      cancelReason: "Received a product with a physical damage (e.g dented, scratched, shattered)",
    },
    { id: 2, cancelReason: "Received an incomple product (missing quantity or accessories)" },
    {
      id: 3,
      cancelReason: "Received a faulty product (e.g. mailfunction, does not work as intended",
    },
    { id: 4, cancelReason: "Received a counterfeit product" },
    { id: 5, cancelReason: "States your own reason" },
  ];

  const formik = useFormik({
    initialValues: {
      reason: "",
      own_reason: "",
      account_holder_name: "",
      bank_account_no: "",
      bank_name: "",
      attachment: [],
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required("Reason is required."),
      own_reason: Yup.string().when("reason", {
        is: "States your own reason",
        then: Yup.string().required("Reason is required."),
      }),
      account_holder_name: Yup.string().required("Account Holder Name is required."),
      bank_account_no: Yup.string().required("Account Number is required."),
      bank_name: Yup.string().required("Bank Name is required."),
      attachment: Yup.array().min(1, "Upload one image at least"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        sales_order_id: detailsItem.id,
        reason: values.reason === "States your own reason" ? values.own_reason : values.reason,
        bank_account_no: values.bank_account_no,
        bank_name: values.bank_name,
        attachment: values.attachment,
      };

      console.log(data);
      setSubmitting(true);
      dispatch(refundOrder(data, orderDetailType, addToast, detailToggle, setMessageModal));
      setTimeout(() => {
        setSubmitting(false);
      }, 2000);
    },
  });

  const { setSubmitting } = formik;

  // UNSUBSCRIBE
  useEffect(() => {
    return () => setSubmitting(false);
  }, [setSubmitting]);

  return (
    <>
      <Card.Header>
        <Row>
          <Col>
            <a
              onClick={() => {
                detailToggle(undefined, "tab");
              }}
              className="d-flex align-items-center">
              <BsChevronLeft /> Back
            </a>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h3 className="mb-0 mt-3 fw--600">Refund</h3>
            <p className="mb-5">Please complete this refund process</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={formik.handleSubmit}>
              <h5 className="fs-5 mb-0 fw--600">1. Select Your Reason to Refund</h5>
              <p className="mb-3">Upload your defect product item</p>
              <Form.Group className="px-2">
                {cancelReasons.map(({ id, cancelReason }) => (
                  <Row key={id}>
                    <Col
                      xs={12}
                      className="p-2  cursor-pointer mb-2 mb-md-0"
                      onClick={() => formik.setFieldValue("reason", cancelReason)}>
                      <span
                        className="mb-2 fw--500"
                        style={{
                          color: "#615C5C",
                        }}>
                        {formik.values.reason === cancelReason ? (
                          <MdRadioButtonChecked
                            className="me-2"
                            fontSize="1.4rem"
                            color={formik.values.reason === cancelReason ? "#32baa5" : "#615C5C"}
                          />
                        ) : (
                          <MdRadioButtonUnchecked className="me-2" fontSize="1.4rem" />
                        )}
                        {cancelReason}
                      </span>
                    </Col>
                  </Row>
                ))}
                {formik.touched.reason && formik.errors.reason && (
                  <small className="invalid-feedback d-block">{formik.errors.reason}</small>
                )}

                {formik.values.reason.startsWith("States") && (
                  <Row className="mb-3">
                    <Col xs="12" lg="10">
                      <Form.Control
                        as="textarea"
                        placeholder="Text Message"
                        isInvalid={formik.touched.own_reason && formik.errors.own_reason}
                        {...formik.getFieldProps("own_reason")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.own_reason}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                )}
              </Form.Group>

              <h5 className="fs-5 mb-0 mt-5 fw--600">2. Media Upload</h5>
              <p className="mb-5">Upload your defect product item</p>
              <div className="d-flex flex-wrap">
                {images.map((image, index) => {
                  return (
                    <div className="position-relative" key={image.id}>
                      <Form.Label
                        htmlFor={image.id}
                        className="border border-gray-seven rounded mx-2 p-3 d-flex justify-content-center"
                        style={{ width: "120px", height: "120px", cursor: "pointer" }}>
                        <img
                          src={image.file ? image.file : "/assets/images/add-photo.png"}
                          className="img-fluid"
                        />
                      </Form.Label>
                    </div>
                  );
                })}
              </div>
              {formik.touched.attachment && formik.errors.attachment && (
                <small className="text-danger">{formik.errors.attachment}</small>
              )}
              {images.map((image, index) => (
                <Form.Control
                  key={image.id}
                  type="file"
                  name={image.id}
                  accept="image/*"
                  id={image.id}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      formik.setFieldTouched("attachment");
                      const file = event.target.files[0];

                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      const imgTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
                      const validType = imgTypes.some((type) => file.type === type);
                      if (file.size < 6 * Math.pow(10, 6) && validType) {
                        reader.onload = () => {
                          const tempImages = images;
                          const tempImage = image;
                          tempImage.file = reader.result;

                          tempImages[index] = tempImage;
                          setImages([...tempImages]);
                        };

                        formik.setValues((values) => {
                          const formImages = values.attachment;
                          if (formImages[index]) {
                            formImages[index] = file;
                          } else {
                            formImages.push(file);
                          }

                          return {
                            ...values,
                            attachment: formImages,
                          };
                        });
                      }
                    }
                  }}
                />
              ))}

              <h5 className="fs-5 mb-0 mt-5 fw--600">3. Refunding Account Details</h5>
              <p className="mb-5">Please fill in the details below for refunding transaction</p>
              <Row className="mb-3">
                <Col xs="12" lg="10">
                  <Form.Control
                    placeholder="Acc Holder Name"
                    isInvalid={
                      formik.touched.account_holder_name && formik.errors.account_holder_name
                    }
                    {...formik.getFieldProps("account_holder_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.account_holder_name}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12" lg="10">
                  <Form.Control
                    placeholder="Bank Account Number"
                    isInvalid={formik.touched.bank_account_no && formik.errors.bank_account_no}
                    {...formik.getFieldProps("bank_account_no")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bank_account_no}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="12" lg="10">
                  <Form.Control
                    placeholder="Bank Name"
                    isInvalid={formik.touched.bank_name && formik.errors.bank_name}
                    {...formik.getFieldProps("bank_name")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.bank_name}
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <h5 className="fs-5 mb-0 mt-5 fw--600">4. Order Details</h5>
              <p className="mb-5">List of order items to cancel</p>
              <hr className="px-2" />
              {detailsItem && (
                <div className="px-2">
                  <Row className="pb-4">
                    <Col lg={4}>
                      <p className="fw-bold">Order No</p>
                      {detailsItem.order_no ?? "N/A"}
                    </Col>
                    {detailsItem.shipping_vendor_name ? (
                      <Col className="border-left d-flex justify-content-end">{`Shipped By ${detailsItem.shipping_vendor_name}`}</Col>
                    ) : (
                      <Col className="border-left d-flex justify-content-end">{`Shipped By N/A`}</Col>
                    )}
                  </Row>
                  <Row className="order-header bg--grey pt-3">
                    <Col lg={12} sm={12} xs={12}>
                      {detailsItem.vendor_name}
                      <Link href={"/seller/shop-products?seller=" + detailsItem.vendor_id} passHref>
                        <Button variant="outline-primary" size="sm" className="ms-2">
                          View Shop
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="bg--grey">
                    <Col>
                      <hr></hr>
                    </Col>
                  </Row>
                  <Row className="py-2 bg--grey">
                    {detailsItem.item.map((product) => {
                      totalPrice = totalPrice + +product.subtotal;
                      return (
                        <Col xs="12" key={product.product_id}>
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <img
                                width={64}
                                height={64}
                                className="me-3"
                                src={product.product_thumbnail}
                                alt="Generic placeholder"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h6 className="fw-bold">{product.product_name}</h6>
                              <Row>
                                <Col>
                                  <p>
                                    {product.product_variance && (
                                      <span className="badge rounded-pill pt-2 bg-primary">
                                        {product.product_variance}
                                      </span>
                                    )}
                                    {product.product_variance && <br />}x {product.product_quantity}
                                  </p>
                                </Col>
                                <Col className="d-flex justify-content-end text-primary">
                                  <p>RM {parseFloat(product.product_price).toFixed(2)}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row className="bg--grey">
                    <Col>
                      <Table bordered>
                        <tbody>
                          <tr>
                            <td colSpan="5" className="text-right">
                              Sub-Total
                            </td>
                            <td className="text-right" width="20%">
                              RM {parseFloat(detailsItem.merchandise_subtotal).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="5" className="text-right">
                              Shipping Cost
                            </td>
                            <td className="text-right" width="20%">
                              RM {parseFloat(detailsItem.shipping_subtotal).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="5" className="text-right">
                              Total
                            </td>
                            <td className="text-right fw-bold text-primary" width="20%">
                              RM {parseFloat(detailsItem.total_payment).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>
              )}
              <Row>
                <Col>
                  <Button className="text-white mt-5" type="submit" disabled={formik.isSubmitting}>
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
}
