import { Col, Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

const Preloader = ({ location }) => {
  return (
    <>
      <Row className="gx-3 gy-2">
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
        <Col
          lg={location == "home" ? 2 : 3}
          md={location == "home" ? 2 : 3}
          sm={6}
          xs={6}
        >
          <Skeleton width={"100%"} height={200} />
        </Col>
      </Row>
    </>
  );
};

export default Preloader;
