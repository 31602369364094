import { Card, Row, Col } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { IoIosStarOutline, IoIosStar } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import { postReview } from "../../redux/actions";

export default function RateItem({ detailToggle, detailsItem, orderDetailType, setMessageModal }) {
  const { profile_details } = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const starStyle = {
    color: "#F3C854",
    fontSize: "2.5rem",
  };

  const formik = useFormik({
    initialValues: {
      rating: 0,
      review: "",
      anonymous: false,
    },
    validationSchema: Yup.object().shape({
      rating: Yup.number().min(1, "Minimum rating is one star"),
      review: Yup.string().required("Review is required"),
    }),
    onSubmit: (values, { setSubmitting }) => {
      const data = {
        product_id: detailsItem.product_id,
        rating: values.rating,
        review: values.review,
        anonymous: values.anonymous === true ? 0 : 1,
        vendor_id: detailsItem.vendor_id,
        sales_order_id: detailsItem.sales_order_id,
      };
      dispatch(postReview(data, addToast, detailToggle, setMessageModal));
      console.log(data)
    },
  });

  return (
    <>
      <Card.Header>
        <Row>
          <Col>
            <a
              onClick={() => {
                detailToggle(undefined, "tab");
              }}
              className="d-flex align-items-center">
              <BsChevronLeft /> Back
            </a>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <div key={detailsItem.product_id} className="p-4 bg--grey mb-5">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <img
                width={64}
                height={64}
                className="me-3"
                src={detailsItem.product_thumbnail}
                alt="Generic placeholder"
              />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="fw--500 mb-0">{detailsItem.product_name}</h5>
              <hr className="my-1 bg-gray" />
              <Row>
                <Col className="d-flex justify-content-start ">
                  <p style={{ color: "#026D5E" }} className="fw--500">
                    RM {parseFloat(detailsItem.product_price).toFixed(2)}
                  </p>
                </Col>
                <Col>
                  <p className="text-end fw--500">
                    {detailsItem.product_variance && (
                      <span className="badge rounded-pill pt-2 bg-primary">
                        {product.product_variance}
                      </span>
                    )}
                    {detailsItem.product_variance && <br />}x {detailsItem.product_quantity}
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <h5 className="fw--600">Rate your experience</h5>
        <form className="row mt-3" onSubmit={formik.handleSubmit}>
          <div className="form-group mt-2 col-12">
            <span className="product-rating bg--grey p-3 d-inline-block mb-3 rounded-3">
              {formik.values.rating === 0 ? (
                <IoIosStarOutline
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 1);
                  }}
                />
              ) : (
                <IoIosStar
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 0);
                  }}
                />
              )}
              {formik.values.rating < 2 ? (
                <IoIosStarOutline
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 2);
                  }}
                />
              ) : (
                <IoIosStar
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 1);
                  }}
                />
              )}
              {formik.values.rating < 3 ? (
                <IoIosStarOutline
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 3);
                  }}
                />
              ) : (
                <IoIosStar
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 2);
                  }}
                />
              )}
              {formik.values.rating < 4 ? (
                <IoIosStarOutline
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 4);
                  }}
                />
              ) : (
                <IoIosStar
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 3);
                  }}
                />
              )}
              {formik.values.rating < 5 ? (
                <IoIosStarOutline
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 5);
                  }}
                />
              ) : (
                <IoIosStar
                  style={starStyle}
                  onClick={() => {
                    formik.setFieldValue("rating", 4);
                  }}
                />
              )}
            </span>
            {formik.errors.rating && <p className="text-danger mb-4">{formik.errors.rating}</p>}
          </div>

          <h5 className="fw--600">Customer Review</h5>
          <div className="form-group mt-2 col-12">
            <textarea
              rows={6}
              placeholder="Your review *"
              style={{ backgroundColor: "#F4F4F4", resize: "none", border: "none", width: "80%" }}
              className="p-2 rounded"
              name="message"
              {...formik.getFieldProps("review")}
            />
          </div>
          {formik.touched.review && formik.errors.review && (
            <p className="text-danger">{formik.errors.review}</p>
          )}

          <h5 className="fw--600 my-4">Customer Review</h5>
          <div className="d-flex justify-content-between align-items-center w-10">
            <p className="fs-5 text-muted">
              Your username will be shown as {profile_details?.name}
            </p>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                {...formik.getFieldProps("anonymous")}
              />
            </div>
          </div>

          <div className="form-group mt-2 col-12">
            <button
              type="submit"
              className="btn btn-primary text-white my-4"
              name="submit"
              value="Submit">
              Submit Review
            </button>
          </div>
        </form>
      </Card.Body>
    </>
  );
}
