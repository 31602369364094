import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Card, Row, Col, Button, Spinner } from "react-bootstrap";
import { BsChevronLeft } from "react-icons/bs";
import { useRouter } from "next/router";

export default function RateList({ detailToggle, detailsItem, orderDetailType, setMessageModal }) {
  const { orderDetail, orderDetailLoading, orderDetailError } = useSelector(
    (state) => state.profile
  );
  const router = useRouter();

  useEffect(() => {
    if (!detailsItem) detailToggle(undefined, "tab");
  }, [detailsItem]);

  return (
    <>
      <Card.Header>
        <Row>
          <Col>
            <a
              onClick={() => {
                detailToggle(undefined, "tab");
              }}
              className="d-flex align-items-center">
              <BsChevronLeft /> Back
            </a>
          </Col>
        </Row>
      </Card.Header>
      {orderDetailLoading == true && (
        <Row className="mt-3">
          <Col>
            <Card className="cardShadow text-center py-5">
              <Card.Body className="mx-auto">
                <Spinner animation="border" className="text-primary" />
                <p
                  style={{
                    textAlign: "center !important",
                  }}>
                  Loading..
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {orderDetailError  && (
        <Row className="mt-3">
          <Col>
            <Card className="cardShadow text-center py-5">
              <Card.Body className="mx-auto">
                <p
                  style={{
                    textAlign: "center !important",
                  }}>
                  {orderDetailError}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
      {orderDetail && (
        <Card.Body>
          <h4 className="fw--500">Order ID #{orderDetail.order_no}</h4>
          {orderDetail.item.map((product) => (
            <div key={product.product_id} className="p-4 bg--grey mb-5">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img
                    width={64}
                    height={64}
                    className="me-3"
                    src={product.product_thumbnail}
                    alt="Generic placeholder"
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="fw--500 mb-0">{product.product_name}</h5>
                  <hr className="my-1 bg-gray" />
                  <Row>
                    <Col className="d-flex justify-content-start ">
                      <p style={{ color: "#026D5E" }} className="fw--500">
                        RM {parseFloat(product.product_price).toFixed(2)}
                      </p>
                    </Col>
                    <Col>
                      <p className="text-end fw--500">
                        {product.product_variance && (
                          <span className="badge rounded-pill pt-2 bg-primary">
                            {product.product_variance}
                          </span>
                        )}
                        {product.product_variance && <br />}x {product.product_quantity}
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
              <hr className="mt-3 mb-1 bg-gray" />
              <Row>
                <Col sm="6">
                  <p className="mt-3">
                    {!product.review
                      ? "Please rate and leave your review"
                      : "Product has been rated and reviewed"}
                  </p>
                </Col>
                <Col sm="6" className="text-end">
                  {!product.review ? (
                    <button
                      className="btn btn-sm btn-yellow--four mt-3"
                      onClick={() => {
                        detailToggle(
                          {
                            ...product,
                            sales_order_id: orderDetail.id,
                            vendor_id: orderDetail.vendor_id,
                          },
                          "rate_item"
                        );
                      }}>
                      To Rate
                    </button>
                  ) : (
                    <Button
                      size="sm"
                      className="text-white mt-3"
                      onClick={() => router.push(`/shop/product/${product.product_id}`)}>
                      View Review
                    </Button>
                  )}
                </Col>
              </Row>
            </div>
          ))}
        </Card.Body>
      )}
    </>
  );
}
