import { useState, useEffect } from "react";
import Link from "next/link";
import { Row, Col, Offcanvas } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { IoIosSearch, IoIosMenu, IoIosShuffle } from "react-icons/io";
import {
  AiOutlineShoppingCart,
  AiOutlineHeart,
  AiFillShopping,
  AiFillHeart,
  AiFillShop,
} from "react-icons/ai";
import { BsChatDotsFill, BsPerson } from "react-icons/bs";
import { FiLogOut, FiPhoneCall } from "react-icons/fi";
import MiniCart from "./elements/MiniCart";
import MiniAccount from "./elements/MiniAccount";
//imports:
import classes from "./Header.module.css";
import { addParam, getDetails } from "../../redux/actions";
import { FaUser, FaUserAlt, FaUserPlus } from "react-icons/fa";
import Authentication from "../Authentication/Authentication";
import HeaderTopThree from "./elements/HeaderTopThree";
import { auth } from "../../firebase/firebase";
import { logoutAuth } from "../../redux/actions/index";
import { useTranslation } from "next-i18next";

const HeaderFive = ({
  cartItems,
  wishlistItems,
  compareItems,
  navPositionClass,
  showCategories,
  user,
  partner
}) => {
  const { t } = useTranslation("common");

  const [scroll, setScroll] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [offCanvasMobileMenuActive, setOffCanvasMobileMenuActive] = useState(false);
  const [authMode, setAuthMode] = useState("signIn");
  const [showAuth, setShowAuth] = useState(false);
  const { profile_details } = useSelector((state) => state.profile);
  const { user: firebaseInfo } = useSelector((state) => state.authData);
  const { search: searchParam } = useSelector((state) => state.products);

  const router = useRouter();
  const [search, setSearch] = useState(router?.pathname === "/" ? "" : searchParam ?? "");
  const dispatch = useDispatch();
  useEffect(() => {
    const header = document.querySelector(".header-wrap");
    setHeaderHeight(header.offsetHeight);
    if (localStorage.getItem("idToken") != null) {
      dispatch(getDetails());
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  const showAuthHandler = (mode, keepShowing) => {
    if (keepShowing) {
      setShowAuth(true);
    } else {
      setShowAuth(!showAuth);
    }
    if (mode === "signUp") {
      setAuthMode("signUp");
    } else if (mode === "signIn") {
      setAuthMode("signIn");
    } else if (mode === "passwordReset") {
      setAuthMode("passwordReset");
    } else if (mode === "verifyEmail") {
      setAuthMode("verifyEmail");
    }
  };

  //${scroll > headerHeight ? "is-sticky" : ""} className for header-wrap lead to scroll bug.
  return (
    <header className={`header-wrap header-with-topbar`}>
      {/* header top */}
      <HeaderTopThree user={user} partner={partner}/>
      {/* middle header */}
      <div className={`middle-header dark-skin pt-2 pb-3 ${classes.MiddelHeader}`}>
        <div className="container d-flex justify-content-between align-items-center">
          {/* logo */}
          <Link href={partner}>
            <a className="navbar-brand pt-0 pb-0">
              <img className="logo-light" src="/assets/images/shopping_logo.png" alt="logo" />
              <img className={classes.Logo} src="/assets/images/shopping_logo.png" alt="logo" />
            </a>
          </Link>
          <div className="product-search-form product-search-form--style-two d-none d-lg-block mx-2">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                dispatch(addParam("search", null, null, search));
                router.push("/shop/products");
              }}>
              <div className="input-group">
                <input
                  className="form-control"
                  style={{ paddingLeft: "1.8rem" }}
                  placeholder={`${t("Search Product")}...`}
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                />
                <button type="submit" className={`search-btn ${classes.SearchBtn}`}>
                  <IoIosSearch />
                </button>
              </div>
            </form>
          </div>

          <div className="contact-phone">
            {showCategories == false ? (
              user ? (
                <ul className="header-icons d-flex justify-content-end">
                  <li className="d-none d-lg-block position-relative">
                    <Link href="/user/my-account?tab=orders">
                      {profile_details &&
                      profile_details.profile &&
                      profile_details.profile.avatar ? (
                        <a
                          className="nav-link mini-cart-trigger p-0 mx-2"
                          style={{
                            background: `url(${profile_details.profile.avatar})`,
                            borderRadius: "100%",
                            backgroundSize: "contain",
                            height: "3rem",
                            width: "3rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}></a>
                      ) : (
                        <>
                          {firebaseInfo?.photoURL ? (
                            <a
                              className="nav-link mini-cart-trigger p-0 mx-2"
                              style={{
                                backgroundImage: `url(${firebaseInfo.photoURL})`,
                                backgroundSize: "contain",
                                borderRadius: "100%",
                                height: "3rem",
                                width: "3rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}></a>
                          ) : (
                            <a
                              className="nav-link mini-cart-trigger p-0 mx-2"
                              style={{
                                backgroundColor: "white",
                                borderRadius: "100%",
                                height: "3rem",
                                width: "3rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}>
                              <FaUser size={20}></FaUser>
                            </a>
                          )}
                        </>
                      )}
                    </Link>
                    {/*mini Me*/}
                    <MiniAccount />
                  </li>
                  {/* <li className="position-relative">
                      <Link href="/products/compare">
                        <a className="nav-link">
                          <IoIosShuffle />
                          {compareItems.length > 0 ? (
                            <span className="cart-count cart-count--mobile">
                              {compareItems.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </a>
                      </Link>
                    </li> */}
                  <li className="position-relative">
                    <Link href="/products/wishlist">
                      <a
                        className="nav-link mini-cart-trigger p-0 mx-2"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "100%",
                          height: "3rem",
                          width: "3rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <AiFillHeart size={20}></AiFillHeart>
                        {wishlistItems.length > 0 ? (
                          <span className="cart-count cart-count--mobile">
                            {wishlistItems.length}
                          </span>
                        ) : (
                          ""
                        )}
                      </a>
                    </Link>
                  </li>

                  <li className="d-none d-lg-block position-relative">
                    <Link href="/products/cart">
                      <a
                        className="nav-link mini-cart-trigger p-0 mx-2"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "100%",
                          height: "3rem",
                          width: "3rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <AiFillShopping size={26}></AiFillShopping>
                        {cartItems.length > 0 ? (
                          <span className="cart-count">
                            {cartItems.map((e) => e.cart.length).reduce((a, b) => a + b)}
                          </span>
                        ) : (
                          ""
                        )}
                      </a>
                    </Link>
                    {/* mini cart */}
                    <MiniCart cartItems={cartItems} />
                  </li>
                  <li className="d-none d-lg-block position-relative">
                    <Link href="/chats">
                      <a
                        className="nav-link mini-cart-trigger p-0 mx-2"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "100%",
                          height: "3rem",
                          width: "3rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <BsChatDotsFill size={20}></BsChatDotsFill>
                      </a>
                    </Link>
                  </li>

                  <li className="d-block d-lg-none position-relative">
                    <Link href="/products/cart">
                      <a
                        className="nav-link mini-cart-trigger p-0"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "100%",
                          height: "3rem",
                          width: "3rem",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}>
                        <AiFillShopping size={25} />
                        {cartItems.length > 0 ? (
                          <span className="cart-count cart-count--mobile">{cartItems.length}</span>
                        ) : (
                          ""
                        )}
                      </a>
                    </Link>
                  </li>

                  <li className="d-block d-lg-none position-relative">
                    <a
                      className="nav-link mini-cart-trigger p-0 mx-2"
                      onClick={() => {
                        setOffCanvasMobileMenuActive(true);
                      }}
                      style={{
                        backgroundColor: "white",
                        borderRadius: "100%",
                        height: "3rem",
                        width: "3rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}>
                      <IoIosMenu size={25} />
                    </a>
                  </li>
                </ul>
              ) : (
                <div className={classes.btn_wrapper}>
                  <button
                    className="btn btn-light btn-sm fw-bold"
                    onClick={() => showAuthHandler("signIn")}>
                    <FaUserAlt size={12} className="me-2"></FaUserAlt> {t("Log In / Register")}
                  </button>
                  <a
                    className="btn btn-light btn-sm fw-bold d-none d-lg-flex align-items-center"
                    href={process.env.NEXT_PUBLIC_SELLER_URL}>
                    <AiFillShop size={16} className="me-2" />
                    {t("Seller Centre")}
                  </a>
                </div>
              )
            ) : (
              <>
                <FiPhoneCall />
                <span className="ms-2">123-456-7689</span>
              </>
            )}
          </div>
        </div>
      </div>

      {/* bottom header */}
      {showCategories == false ? (
        <></>
      ) : (
        <>
          <div className="bottom-header dark-skin border-top border-bottom">
            <div className="container">
              <Row className="align-items-center">
                {/* category menu */}
                {/* <Col lg={3} xs={6}>
                  <div className="mobile-category d-block d-lg-none">
                    <button
                      className="nav-link mobile-category-menu-trigger"
                      onClick={() => {
                        setOffCanvasMobileCategoryMenuActive(true);
                      }}>
                      <IoIosMenu />
                    </button>
                  </div>
                  <div className={showCategories == false ? "d-none" : "d-none d-lg-block"}>
                    
                    <CategoryMenuTwo />
                  </div>
                </Col> */}

                <Col lg={12} xs={12}>
                  <div className="d-flex align-items-center justify-content-end">
                    {/* icons */}
                    <ul className="header-icons d-flex justify-content-end">
                      <li>
                        <Link href="/user/my-account?tab=orders">
                          <a className="nav-link pe-3 ">
                            <BsPerson />
                          </a>
                        </Link>
                      </li>

                      <li className="position-relative">
                        <Link href="/products/wishlist">
                          <a className="nav-link mini-cart-trigger pe-3 ">
                            <AiOutlineHeart />
                            {wishlistItems.length > 0 ? (
                              <span className="cart-count cart-count--mobile">
                                {wishlistItems.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                      </li>

                      <li className="d-none d-lg-block position-relative">
                        <Link href="/products/cart">
                          <a className="nav-link mini-cart-trigger pe-3  pr-lg-0">
                            <AiOutlineShoppingCart />
                            {cartItems.length > 0 ? (
                              <span className="cart-count">
                                {cartItems.map((e) => e.cart.length).reduce((a, b) => a + b)}
                              </span>
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                        {/* mini cart */}
                        <MiniCart cartItems={cartItems} />
                      </li>

                      <li className="d-block d-lg-none position-relative">
                        <Link href="/products/cart">
                          <a className="nav-link mini-cart-trigger pe-3  pr-lg-0">
                            <AiOutlineShoppingCart />
                            {cartItems.length > 0 ? (
                              <span className="cart-count cart-count--mobile">
                                {cartItems.length}
                              </span>
                            ) : (
                              ""
                            )}
                          </a>
                        </Link>
                      </li>

                      <li className="d-block d-lg-none">
                        <button
                          className="nav-link mobile-menu-trigger pe-0"
                          onClick={() => {
                            setOffCanvasMobileMenuActive(true);
                          }}>
                          <IoIosMenu />
                        </button>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}
      {showAuth && (
        <Authentication showAuth={showAuth} showAuthHandler={showAuthHandler} authMode={authMode} />
      )}

      <Offcanvas
        show={offCanvasMobileMenuActive}
        onHide={() => setOffCanvasMobileMenuActive(false)}
        placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col>
              <Link href="/user/my-account?tab=orders">
                <a className="nav-link mini-cart-trigger">
                  <FaUser size={20} className="me-2"></FaUser> My Account
                </a>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              <a
                className="nav-link mini-cart-trigger"
                onClick={(e) => {
                  e.preventDefault();
                  router.push("/");
                  setOffCanvasMobileMenuActive(false);
                  dispatch(logoutAuth());
                }}>
                <FiLogOut size={20} className="me-2" /> {t("Log Out")}
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link href={process.env.NEXT_PUBLIC_SELLER_URL}>
                <a className="nav-link mini-cart-trigger">
                  <AiFillShop size={20} className="me-2" /> Seller Centre
                </a>
              </Link>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.data,
    wishlistItems: state.wishlistData.wishlist_data,
    compareItems: state.compareData,
    user: state.authData.user,
  };
};

export default connect(mapStateToProps)(HeaderFive);
