import { Fragment } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import {
  addToCompare,
  deleteFromCompare,
} from "../../redux/actions/compareActions";
import ShopGridList from "./ShopGridList";
import { addCartItem, addWishlistItem, deleteWishlistItem } from "../../redux/actions/index";

const ProductGridListWrapper = ({
  products,
  bottomSpace,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  cartItems,
  wishlistItems,
  compareItems,
  sliderClass,
  layout,
  location,
  addCartItem,
  user,
}) => {
  const { addToast } = useToasts();
  return (
    <Fragment>
      {products &&
        products.map((product) => {
          // const discountedPrice = getDiscountPrice(
          //   product.price,
          //   product.discount
          // ).toFixed(2);
          const discountedPrice = product.price;
          const productPrice = product.price;
          const cartItem = cartItems.filter(
            (cartItem) => cartItem.id === product.id
          )[0];
          const wishlistItem = wishlistItems
            ? wishlistItems.filter(
                (wishlistItem) => wishlistItem.product_id === product.id
              )[0]
            : undefined;
          const compareItem = compareItems.filter(
            (compareItem) => compareItem.id === product.id
          )[0];

          return (
            <ShopGridList
              key={product.id}
              product={product}
              discountedPrice={discountedPrice}
              productPrice={productPrice}
              cartItem={cartItem}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              bottomSpace={bottomSpace}
              addToWishlist={addToWishlist}
              deleteFromWishlist={deleteFromWishlist}
              addToCompare={addToCompare}
              deleteFromCompare={deleteFromCompare}
              addToast={addToast}
              layout={layout}
              cartItems={cartItems}
              sliderClass={sliderClass}
              location={location}
              addCartItem={addCartItem}
              user={user}
            />
          );
        })}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData.cart_items,
    wishlistItems: state.wishlistData.wishlist_data,
    compareItems: state.compareData,
    user: state.authData.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCartItem: (data, addToast) => dispatch(addCartItem(data, addToast)),
    addToWishlist: (item, addToast) => {
      dispatch(addWishlistItem(item, addToast));
    },
    deleteFromWishlist: (item, addToast) => {
      dispatch(deleteWishlistItem(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    },
    deleteFromCompare: (item, addToast) => {
      dispatch(deleteFromCompare(item, addToast));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductGridListWrapper);
