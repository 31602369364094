import { getToken } from "./utils";
import * as actionTypes from "./actionTypes";
import { strapiAxios } from "../../utils";
import { catchError } from "../../utils/helpers";

const initGetFaqs = () => {
  return {
    type: actionTypes.INIT_GET_FAQS,
    loading: true,
  };
};

const successGetFaqs = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_FAQS,
    loading: false,
    faqs: data,
  };
};

const failGetFaqs = (error) => {
  return {
    type: actionTypes.FAIL_GET_FAQS,
    loading: false,
    error: error,
  };
};

export const getFaqs = () => {
  return (dispatch) => {
    dispatch(initGetFaqs());

    strapiAxios
      .post("/graphql", {
        query: `query ($moduleName: String!){
          data : faqCategories(locale : "${global.localStorage?.lang == "ms" ? "ms-MY" : "en"}") {
            id
            name
            faqs(where: { 
                module:{
                    #set in variables auto | shopping | star
                    name: $moduleName 
                    }
                }) {
                id
                answer
                question
                created_at
                updated_at
            }
          }
        }`,
        variables: {
          moduleName: "shopping",
        },
      })
      .then((response) => {
        if (response.data.data.data) dispatch(successGetFaqs(response.data.data.data));
        console.clear();
        console.log(response.data.data.data);
      })
      .catch((err) => {
        dispatch(failGetFaqs(err.message));
        catchError(err);
      });
  };
};
