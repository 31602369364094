import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { handleLocalStorage } from "../../utils";
import Link from "next/link";

const DropdownLanguage = () => {
  const router = useRouter();
  const { t } = useTranslation("common");

  const [lang, setLang] = useState(global.localStorage?.lang == "ms" ? "ms" : "en");

  const handleSwitchLang = (value) => {
    handleLocalStorage(value);
    setLang(value);
    // i18n.changeLanguage(value);
  };

  useEffect(() => {
    console.log("Router",router);
  }, [router]);
  

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="bg-transparent p-0 m-0 border-0">
        {router.locale == "ms" ? "Bahasa" : "English"}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleSwitchLang("en")}>
          <Link href={"/en" + router.asPath} locale="en" passHref>
            <Button className="border-0 bg-transparent">English</Button>
          </Link>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleSwitchLang("ms")}>
          <Link href={"/ms" + router.asPath} locale="ms" passHref>
            <Button className="border-0 bg-transparent">Bahasa</Button>
          </Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownLanguage;
