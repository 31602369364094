import { Card, Row, Col, Button } from "react-bootstrap";
import { AiOutlineMessage, AiOutlineShop, AiOutlineStar } from "react-icons/ai";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { checkUserRegistered } from "../../redux/actions";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "next-i18next";

const SellerQuickInfo = ({ seller, vendor, shopInfo, search }) => {
  const dispatch = useDispatch();
  const { userRegistered, chatClient } = useSelector((state) => state.streamChatData || {});
  const { addToast } = useToasts();
  const [chatEnabled, setChatEnabled] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    console.log(seller, vendor);
    if (seller) {
      dispatch(checkUserRegistered("email", seller.company_email));
    } else if (vendor) {
      dispatch(checkUserRegistered("email", vendor.company_email));
    }
  }, [chatClient]);

  useEffect(() => {
    if (userRegistered) {
      setChatEnabled(true);
    }
  }, [userRegistered]);

  return seller ? (
    <Card className="quick-info">
      <Card.Body>
        <Row>
          <Col lg={5}>
            <div className="d-flex">
              <div className="flex-shrink-0">
                <div
                  style={{ width: "64px", height: "64px" }}
                  className="d-flex align-items-center">
                  <img
                    src={seller.company_logo ?? "/assets/images/not_found.png"}
                    className="img-fluid"
                    alt="placeholder"
                  />
                </div>

                {/* <img
                  width={64}
                  height={64}
                  className="me-3"
                  src={seller.company_logo ?? "/assets/images/not_found.png"}
                  alt="Generic placeholder"
                /> */}
              </div>
              <div className="flex-grow-1 ms-3">
                <Row>
                  <Col>
                    <a className="fw-bold">{seller.shop_name}</a>
                  </Col>
                </Row>
              </div>
            </div>

            {/* <Row className="mt-2">
              <Col className="d-flex justify-content-center">
                <Button variant="outline-primary" size="sm">
                  <AiOutlinePlus></AiOutlinePlus> Follow
                </Button>
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    toggleChat();
                  }}>
                  <AiOutlineWechat></AiOutlineWechat> Chat Now
                </Button>
              </Col>
            </Row> */}
          </Col>
          <Col lg={7} className="border-left">
            <Row>
              <Col>
                <AiOutlineShop></AiOutlineShop> {t("Products")}:{" "}
                <span className="text-primary">{shopInfo.product}</span>
              </Col>
              <Col>
                <AiOutlineStar></AiOutlineStar> {t("Rating")}:{" "}
                <span className="text-primary">{shopInfo.rating}</span>
              </Col>

              {search !== true && (
                <Col className="d-flex justify-content-end">
                  {chatEnabled === true ? (
                    <Link
                      href={{
                        pathname: "/chats",
                        query: { seller: seller.company_email ?? "" },
                      }}>
                      <a className="btn btn-outline-primary btn-sm">
                        <AiOutlineMessage className="text-primary" /> Chat Now
                      </a>
                    </Link>
                  ) : (
                    <button
                      className="btn btn-outline-primary btn-sm"
                      onClick={() => {
                        addToast("Seller not registered to chat.", {
                          appearance: "error",
                          autoDismiss: true,
                        });
                      }}>
                      <AiOutlineMessage className="text-primary" /> Chat Now
                    </button>
                  )}
                </Col>
              )}
            </Row>
            {/* <Row className="my-2">
              <Col>
                <AiOutlineUserAdd></AiOutlineUserAdd> Following:{" "}
                <span className="text-primary">100</span>
              </Col>
              <Col>
                <AiOutlineUser></AiOutlineUser> Followers:{" "}
                <span className="text-primary">2.8k</span>
              </Col>
            </Row> */}
            {/* <Row className="my-2">
              <Col>
                <AiOutlineWechat></AiOutlineWechat> Chat Performance:{" "}
                <span className="text-primary">99%</span>
              </Col>
              <Col>
                <AiOutlineCheckCircle></AiOutlineCheckCircle> Joined:{" "}
                <span className="text-primary">13 Months Ago</span>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : vendor ? (
    <Card className="quick-info">
      <Card.Body>
        <Row>
          <Col lg={5}>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                <div
                  style={{ width: "64px", height: "64px" }}
                  className="d-flex align-items-center">
                  <img
                    src={
                      vendor.company_logo == "default/default_product.png"
                        ? "/assets/images/not_found.png"
                        : vendor.company_logo ?? "/assets/images/not_found.png"
                    }
                    className="img-fluid"
                    alt="placeholder"
                  />
                </div>

                {/* <img
                  width={64}
                  height={64}
                  className="me-3"
                  src={vendor.logo}
                  alt="Generic placeholder"
                /> */}
              </div>
              <div className="flex-grow-1 ms-3">
                <Row>
                  <Col>
                    <Link
                      href={{
                        pathname: "/seller/shop-products",
                        query: { seller: vendor.user_id ?? vendor.id },
                      }}>
                      <a className="fw-bold">{vendor.shop_name}</a>
                    </Link>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="text-primary text-truncate">{`${vendor.company_address_state}, ${vendor.company_address_country}`}</span>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col lg={7} className="border-left">
            <Row className="my-2">
              {/* <Col>
                Ratings <span className="text-primary">806</span>
              </Col> */}
              {/* <Col>
                Response Rate <span className="text-primary">100%</span>
              </Col> */}
              <Col className="d-flex justify-content-end">
                {chatEnabled === true ? (
                  <Link
                    href={{
                      pathname: "/chats",
                      query: { seller: vendor.company_email },
                    }}>
                    <a className="btn btn-outline-primary btn-sm">
                      <AiOutlineMessage className="text-primary" /> {t("Chat Now")}
                    </a>
                  </Link>
                ) : (
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={() => {
                      addToast(t("Seller not registered to chat."), {
                        appearance: "error",
                        autoDismiss: true,
                      });
                    }}>
                    <AiOutlineMessage className="text-primary" /> {t("Chat Now")}
                  </button>
                )}

                <Link
                  href={{
                    pathname: "/seller/shop-products",
                    query: { seller: vendor.user_id ?? vendor.id },
                  }}
                  passHref>
                  <Button variant="outline-primary" size="sm">
                    <AiOutlineShop className="text-primary" /> {t("View Shop")}
                  </Button>
                </Link>
              </Col>
              {/* <Col>
                Joined <span className="text-primary">13 months ago</span>
              </Col> */}
            </Row>
            {/* <Row className="my-2">
              <Col>
                Products <span className="text-primary">2.8k</span>
              </Col>
              <Col>
                Response Time <span className="text-primary">within hours</span>
              </Col>
              <Col>
                Follower <span className="text-primary">1.3k</span>
              </Col>
            </Row> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  ) : null;
};

export default SellerQuickInfo;
