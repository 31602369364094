import { useEffect, Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, checkLinkedWithEmail, db } from "../firebase/firebase";

import {
  getCart,
  getWishlist,
  getCurrentOnboarding,
  getStreamToken,
  connectToClient,
} from "../redux/actions/index";
import { successAuth, logoutAuth } from "../redux/actions/index";
import { HeaderFive, FooterFive } from "../components";
import axios from "axios";
import { defaultAxios } from "../utils";
import router, { useRouter } from "next/router";

const LayoutFive = ({ children, navPositionClass, showCategories }) => {
  const { user, rememberUser, error } = useSelector((state) => state.authData);
  const { completedOnboarding } = useSelector((state) => state.onboardingData || {});
  const { chatClient, streamToken, userRegistered } = useSelector((state) => state.streamChatData);
  const [partner, setPartner] = useState("/");

  const dispatch = useDispatch();
  const router = useRouter();

  // useEffect(() => {
  //   let refreshMyToken = null,
  //     shopingToken = null;

  //   async function setToken(token, local) {
  //     if (local) localStorage.setItem("idToken", token);
  //     else sessionStorage.setItem("idToken", token);
  //     dispatch(getCart());
  //     dispatch(getWishlist());
  //   }
  //   const shouldLogOut = localStorage.getItem("idToken") ?? sessionStorage.getItem("idToken");

  //   if (!shouldLogOut && !user) {
  //     dispatch(logoutAuth());
  //   } else {
  //     auth.onAuthStateChanged((user) => {
  //       if (user && user.emailVerified) {
  //         const local = rememberUser || localStorage.getItem("idToken");

  //         dispatch(successAuth(user));
  //         user.getIdTokenResult(true).then((res) => {
  //           setToken(res.token, local);
  //           refreshMyToken = setInterval(() => {
  //             localStorage.removeItem("idToken");
  //             sessionStorage.removeItem("idToken");
  //             user.getIdToken(true).then((newToken) => setToken(newToken, local));
  //           }, 3590 * 1000);
  //         });
  //       } else {
  //         localStorage.removeItem("idToken");
  //         sessionStorage.removeItem("idToken");
  //       }
  //     });
  //   }
  //   return () => {
  //     clearInterval(refreshMyToken);
  //   };
  // }, []);

  useEffect(() => {
    let refreshMyToken = null;

    auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        dispatch(getCurrentOnboarding());
        // user
        //   .unlink("password")
        //   .then(() => {
        //     // Auth provider unlinked from account
        //     // ...
        //   })
        //   .catch((error) => {
        //     // An error happened
        //     // ...
        //   });
        localStorage.setItem("user", user.email);
        dispatch(successAuth(user));
        user.getIdTokenResult(true).then(async (res) => {
          localStorage.setItem("idToken", res.token);

          refreshMyToken = setInterval(() => {
            localStorage.removeItem("idToken");
            user.getIdToken(true).then((newToken) => {
              localStorage.setItem("idToken", newToken);
            });
          }, 3590 * 1000);

          dispatch(getCart());
          dispatch(getWishlist());
        });
      } else {
        localStorage.removeItem("idToken");
      }
    });

    return () => {
      clearInterval(refreshMyToken);
    };
  }, []);

  useEffect(() => {
    if (completedOnboarding === false && auth.currentUser) {
      router.push("/user/onboarding");
    }
  }, [completedOnboarding]);

  useEffect(() => {
    if (user) {
      dispatch(getStreamToken());
    }
  }, [user]);

  useEffect(() => {
    if (streamToken) {
      dispatch(connectToClient());
    }
  }, [streamToken]);

  useEffect(() => {
    if (sessionStorage.getItem("partner") === "tango") {
      setPartner("/?partner=tango");
    }
  }, []);

  useEffect(() => {
    if (router.isReady && router.query?.partner === "tango") {
      setPartner("/?partner=tango");
      sessionStorage.setItem("partner", "tango");
    }
  }, [router.query]);

  return (
    <Fragment>
      <HeaderFive navPositionClass={navPositionClass} showCategories={showCategories} partner={partner}/>
      {children}
      {partner === "/" && <FooterFive />}
    </Fragment>
  );
};

export default LayoutFive;
