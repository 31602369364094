import axios from "axios";

export const defaultAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

export const chatAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CHAT_API_URL,
});

export const strapiAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STRAPI_URL,
});

export const newChatAxios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STREAM_URL,
});
