import axios from "axios";
import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import * as actionTypes from "./actionTypes";

const initGetShop = () => {
  return {
    type: actionTypes.INIT_GET_SHOP,
    loading_shop: true,
  };
};

const successGetShop = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_SHOP,
    shop_data: data,
    loading_shop: false,
  };
};

const failGetShop = (error) => {
  return {
    type: actionTypes.FAIL_GET_SHOP,
    loading_shop: false,
    error_shop: error,
  };
};

export const getShop = (id, redirect) => {
  return (dispatch) => {
    dispatch(initGetShop());

    let combinedMap = {};
    defaultAxios
      .get("shop/" + id + "/heading/")
      .then((response) => {
        combinedMap.heading = response.data.data;
        defaultAxios
          .get("shop/" + id + "/subheading/")
          .then((res) => {
            combinedMap.subheading = res.data.data;
            defaultAxios
              .get("shop/" + id + "/categories/")
              .then((rs) => {
                combinedMap.categories = rs.data.data;
                defaultAxios
                  .get("shop/" + id + "/vendor/")
                  .then((r) => {
                    combinedMap.vendor = r.data.data;
                    dispatch(successGetShop(combinedMap));
                  })
                  .catch((error) => {
                    dispatch(failGetShop(error));
                    catchError(error);
                    redirect("/404/");
                  });
              })
              .catch((e) => {
                dispatch(failGetShop(e));
                catchError(e);
                redirect("/404/");
              });
          })
          .catch((er) => {
            dispatch(failGetShop(er));
            catchError(er);
            redirect("/404/");
          });
      })
      .catch((err) => {
        dispatch(failGetShop(err));
        catchError(err);
        redirect("/404/");
      });
  };
};

const initGetShopProducts = () => {
  return {
    type: actionTypes.INIT_GET_SHOP_PRODUCTS,
    loadingShopProducts: true,
  };
};

const failGetShopProducts = (error) => {
  return {
    type: actionTypes.FAIL_GET_SHOP_PRODUCTS,
    loadingShopProducts: false,
    errorLoadingShopProducts: error,
  };
};

const successGetShopProducts = (data, type) => {
  if (type !== "all") {
    data.forEach((cate) => {
      cate.name = cate.product_name;
      cate.id = cate.product_id;
    });
  }

  return {
    type: actionTypes.SUCCESS_GET_SHOP_PRODUCTS,
    loadingShopProducts: false,
    shop_products: data,
  };
};

export const getShopProducts = (id, categoryId, redirect) => {
  return (dispatch) => {
    dispatch(initGetShopProducts());
    if (categoryId === "all") {
      defaultAxios
        .get("search?vendor_id=" + id)
        .then((response) => {
          dispatch(successGetShopProducts(response.data.data.product.data, "all"));
        })
        .catch((err) => {
          dispatch(failGetShopProducts(err));
        });
    } else {
      defaultAxios
        .get("shop/" + id + "/category/" + categoryId + "/product")
        .then((response) =>
          dispatch(successGetShopProducts(response.data.data[0].categoryproduct, "shop"))
        )
        .catch((err) => {
          dispatch(failGetShopProducts(err));
        });
    }
  };
};
