import { useRouter } from "next/router";
import { IoIosFlash } from "react-icons/io";
import { Row, Col } from "react-bootstrap";
import Swiper from "react-id-swiper";
import { VendorProductWrapper } from "../ProductThumb";
import { useTranslation } from "next-i18next";
import { useDispatch } from "react-redux";
import { addParam } from "../../redux/actions";

const ProductSliderNine = ({ title, bannerImage, products, vendor }) => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  const router = useRouter();

  const params = {
    loop: false,
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 10,
    pagination: {
      clickable: true,
    },
    breakpoints: {
      1024: {
        slidesPerView: 5,
        slidesPerGroup: 5,
      },
      769: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      576: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
      320: {
        slidesPerView: 2,
        slidesPerGroup: 2,
      },
    },
  };

  let link = "/shop/products";
  if (vendor === "preloved") link = "/shop/preloved";

  //console.log(products);

  return (
    <div className="product-slider-area space-pb--r70">
      <div className="container">
        <Row>
          <Col lg={12}>
            <div className="section-title section-title--style-two heading-s1 d-flex justify-content-between align-items-center space-mb--30">
              <h4>{title}</h4>
              <a
                className="text-default"
                href={link}
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(addParam("search", null, null, ""));
                  router.push(link)
                }}>
                {t("View All")}
              </a>
            </div>
            <div className="product-slider-wrap product-slider-wrap--custom-bullet">
              <Swiper {...params}>
                {vendor ? (
                  <VendorProductWrapper
                    type={vendor}
                    products={products}
                    sliderClass="swiper-slide"
                    bottomSpace="space-mb--30"
                  />
                ) : (
                  <></>
                )}
              </Swiper>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductSliderNine;
