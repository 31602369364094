import { getToken } from "../utils";
import * as actionTypes from "../actionTypes";
import { defaultAxios } from "../../../utils";
import { catchError, getTranslation } from "../../../utils/helpers";

const initGetAddress = () => {
  return {
    type: actionTypes.INIT_GET_ADDRESS,
    loading_address: true,
  };
};

const successGetAddress = (shipping, billing) => {
  return {
    type: actionTypes.SUCCESS_GET_ADDRESS,
    loading_address: false,
    address_shipping: shipping,
    address_billing: billing,
  };
};

const failGetAddress = (error) => {
  return {
    type: actionTypes.FAIL_GET_ADDRESS,
    loading_address: false,
    error_address: error,
  };
};

export const getAddress = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetAddress());
    defaultAxios
      .get("customer/address", config)
      .then((response) => {
        dispatch(successGetAddress(response.data.data.shipping, response.data.data.billing));
      })
      .catch((err) => {
        catchError(err);
      });
  };
};

export const postAddress = (data, addToast) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  const formData = new FormData();

  Object.entries(data).map(([key, val]) => {
    formData.append(key, val);
  });

  // for (let [key, val] of formData.entries()) {
  //   console.log(key, val);
  // }
  return (dispatch) => {
    defaultAxios
      .post("customer/address", formData, config)
      .then((response) => {
        let message = getTranslation("Address have been added successfully.");
        dispatch(getAddress());
        if (data._method && data._method === "DELETE") message = "Address have been deleted.";
        if (data._method && data._method === "PUT")
          message = "Address have been edited successfully.";
        addToast(message, { appearance: "success", autoDismiss: true });
      })
      .catch((err) => {
        console.log(err);
        addToast(err.response.data ? err.response.data.messages : err.message, {
          appearance: "error",
          autoDismiss: true,
        });
        catchError(err);
      });
  };
};
