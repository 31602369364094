import { Fragment, useState, useEffect, useRef } from "react";
import Swiper from "react-id-swiper";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import ReactPlayer from "react-player";
import ImageGallery from "react-image-gallery";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const ImageGalleryBottomThumb = ({ product, thumbnailId }) => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const refImg = useRef(null);

  useEffect(() => {
    let tempArray = [];
    if (product.thumbnail != null && product.preloved != null) {
      let tempImage = {
        original: "",
        thumbnail: "",
        originalHeight: "500px",
      };
      tempImage.original = product.thumbnail;
      tempImage.thumbnail = product.thumbnail;
      tempArray.push(tempImage);
    }

    if (product.video_url != null) {
      let tempImage = {
        renderItem: () => (
          <ReactPlayer url={product.video_url} className="img-fluid video-player" controls={true} />
        ),
        thumbnail: "/assets/images/video-placeholder.png",
      };

      tempArray.push(tempImage);
    }

    if (product.image != null) {
      for (let x = 0; x < product.image.length; x++) {
        let tempImage = {
          original: "",
          thumbnail: "",
          originalHeight: "500px",
          thumbnailHeight: "90px",
          thumbnailClass: "img-fluid",
        };
        tempImage.original = product.image[x].path;
        tempImage.thumbnail = product.image[x].path;
        tempArray.push(tempImage);
      }
    }

    if (product.configurable != null) {
      for (let x = 0; x < product.configurable.length; x++) {
        let tempImage = {
          original: "",
          thumbnail: "",
          originalHeight: "500px",
          thumbnailClass: "img-fluid",
          thumbnailId: product.configurable[x].id,
        };
        tempImage.original = product.configurable[x].thumbnail;
        tempImage.thumbnail = product.configurable[x].thumbnail;
        tempArray.push(tempImage);
      }
    }

    console.log(tempArray);
    setImages([...tempArray]);
  }, [product]);

  useEffect(() => {
    for (let x = 0; x < images.length; x++) {
      if (images[x].thumbnailId) {
        if (images[x].thumbnailId === thumbnailId) {
          setCurrentIndex(x);
        }
      } else {
        setCurrentIndex(0);
      }
    }
  }, [thumbnailId]);

  useEffect(() => {
    refImg.current.slideToIndex(currentIndex);
  }, [currentIndex]);

  return (
    <ImageGallery
      ref={refImg}
      items={images}
      showPlayButton={false}
      renderLeftNav={(onClick, disabled) => (
        <button
          className="image-gallery-icon image-gallery-left-nav p-0 shadow d-none d-md-block"
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "100%",
            backgroundColor: "#ffffff",
            color: "#32BAA5",
            filter: "none",
            margin: "1rem",
          }}
          onClick={onClick}
          disabled={disabled}>
          <FaChevronLeft size={25}></FaChevronLeft>
        </button>
      )}
      renderRightNav={(onClick, disabled) => (
        <button
          className="image-gallery-icon image-gallery-right-nav shadow p-0 d-none d-md-block"
          style={{
            width: "48px",
            height: "48px",
            borderRadius: "100%",
            backgroundColor: "#ffffff",
            color: "#32BAA5",
            filter: "none",
            margin: "1rem",
          }}
          onClick={onClick}
          disabled={disabled}>
          <FaChevronRight size={25}></FaChevronRight>
        </button>
      )}
    />
  );
  // const [gallerySwiper, getGallerySwiper] = useState(null);
  // const [thumbnailSwiper, getThumbnailSwiper] = useState(null);
  // const [mounted, setMounted] = useState(false);
  // useEffect(() => {
  //   setMounted(true);
  // }, []);
  // // effect for swiper slider synchronize
  // useEffect(() => {
  //   if (
  //     gallerySwiper !== null &&
  //     gallerySwiper.controller &&
  //     thumbnailSwiper !== null &&
  //     thumbnailSwiper.controller
  //   ) {
  //     gallerySwiper.controller.control = thumbnailSwiper;
  //     thumbnailSwiper.controller.control = gallerySwiper;
  //   }
  // }, [gallerySwiper, thumbnailSwiper]);
  // // swiper slider settings
  // const gallerySwiperParams = {
  //   getSwiper: getGallerySwiper,
  //   spaceBetween: 10,
  //   loopedSlides: 5,
  //   loop: true,
  //   effect: "fade",
  //   navigation: {
  //     nextEl: ".swiper-button-next.thumbnail",
  //     prevEl: ".swiper-button-prev.thumbnail",
  //   },
  // };
  // const thumbnailSwiperParams = {
  //   getSwiper: getThumbnailSwiper,
  //   spaceBetween: 15,
  //   breakpoints: {
  //     1024: {
  //       slidesPerView: 5,
  //     },
  //     769: {
  //       slidesPerView: 3,
  //     },
  //     576: {
  //       slidesPerView: 3,
  //     },
  //     320: {
  //       slidesPerView: 3,
  //     },
  //   },
  //   loopedSlides: 5,
  //   touchRatio: 0.2,
  //   freeMode: true,
  //   loop: true,
  //   slideToClickedSlide: true,
  //   navigation: {
  //     nextEl: ".swiper-button-next.thumbnail",
  //     prevEl: ".swiper-button-prev.thumbnail",
  //   },
  // };
  // useEffect(() => {}, [mounted]);
  // if (mounted)
  //   return (
  //     <Fragment>
  //       <div className="product-large-image-wrapper">
  //         {product.thumbnail && product.preloved ? (
  //           <LightgalleryProvider group="any" src={product.thumbnail}>
  //             <div className="single-image">
  //               <img
  //                 src={product.thumbnail}
  //                 style={{
  //                   maxWidth: "100%",
  //                   objectFit: "contain",
  //                   maxHeight: "100%",
  //                 }}
  //                 alt=""
  //               />
  //             </div>
  //           </LightgalleryProvider>
  //         ) : (
  //           <LightgalleryProvider>
  //             <Swiper
  //               {...gallerySwiperParams}
  //               activeSlideKey={
  //                 thumbnailId ? thumbnailId : product.image ? product.image[0].id : 0
  //               }>
  //               {product.video_url != null ? (
  //                 <div key={"video"}>
  //                   <LightgalleryItem group="any" src={product.video_url}>
  //                     <button className="enlarge-icon">
  //                       <i className="icon-magnifier-add" />
  //                     </button>
  //                   </LightgalleryItem>
  //                   <div className="single-image">
  //                     <ReactPlayer url={product.video_url} className="img-fluid" />
  //                   </div>
  //                 </div>
  //               ) : (
  //                 <></>
  //               )}
  //               {product.image &&
  //                 product.image.map((single, key) => {
  //                   return (
  //                     <div key={single.id}>
  //                       <LightgalleryItem group="any" src={single.path}>
  //                         <button className="enlarge-icon">
  //                           <i className="icon-magnifier-add" />
  //                         </button>
  //                       </LightgalleryItem>
  //                       <div className="single-image">
  //                         <img src={single.path} className="img-fluid" alt="" />
  //                       </div>
  //                     </div>
  //                   );
  //                 })}
  //               {product.configurable &&
  //                 product.configurable.map((configurable, i) => {
  //                   return (
  //                     <div key={configurable.id}>
  //                       <LightgalleryItem group="any" src={configurable.thumbnail}>
  //                         <button className="enlarge-icon">
  //                           <i className="icon-magnifier-add" />
  //                         </button>
  //                       </LightgalleryItem>
  //                       <div className="single-image">
  //                         <img src={configurable.thumbnail} className="img-fluid" alt="" />
  //                       </div>
  //                     </div>
  //                   );
  //                 })}
  //             </Swiper>
  //           </LightgalleryProvider>
  //         )}
  //       </div>
  //       <div className="product-small-image-wrapper">
  //         <Swiper
  //           {...thumbnailSwiperParams}
  //           activeSlideKey={
  //             thumbnailId ? thumbnailId.toString() : product.image ? product.image[0].id : 0
  //           }>
  //           {product.video_url != null ? (
  //             <div key={"video"}>
  //               <div className="single-image">
  //                 <ReactPlayer
  //                   url={product.video_url}
  //                   style={{
  //                     maxWidth: "100%",
  //                     objectFit: "contain",
  //                     maxHeight: "100%",
  //                   }}
  //                 />
  //               </div>
  //             </div>
  //           ) : (
  //             <></>
  //           )}
  //           {product.image &&
  //             product.image.map((image, i) => {
  //               return (
  //                 <div key={image.id}>
  //                   <div className="single-image">
  //                     <img
  //                       src={image.path}
  //                       style={{
  //                         maxWidth: "100%",
  //                         objectFit: "contain",
  //                         maxHeight: "100%",
  //                       }}
  //                       alt=""
  //                     />
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //           {product.configurable &&
  //             product.configurable.map((configurable, i) => {
  //               return (
  //                 <div key={configurable.id}>
  //                   <div className="single-image">
  //                     <img
  //                       src={configurable.thumbnail}
  //                       style={{
  //                         maxWidth: "100%",
  //                         objectFit: "contain",
  //                         maxHeight: "100%",
  //                       }}
  //                       alt=""
  //                     />
  //                   </div>
  //                 </div>
  //               );
  //             })}
  //         </Swiper>
  //       </div>
  //     </Fragment>
  //   );
  // else return null;
};

export default ImageGalleryBottomThumb;
