import { useEffect, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useTranslation } from "next-i18next";

import PrelovedModal from "./PrelovedModal";
import { getPrelovedProfileProducts } from "../../redux/actions/index";
import { useToasts } from "react-toast-notifications";
import { Row, Col, Card } from "react-bootstrap";
import { AddPreloved } from ".";

export default function PrelovedTable({ setShowPrelovedTable, setEditProduct, editProduct }) {
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { t } = useTranslation('common');

  const [modalConfig, setModalConfig] = useState({ mode: null });

  const {
    loading_profile_preloved_products,
    profile_preloved_products,
    error_profile_preloved_products,
  } = useSelector((state) => state.preloved);

  const { profile_details } = useSelector((state) => state.profile);

  useEffect(() => {
    if (profile_details && profile_details.id)
      dispatch(getPrelovedProfileProducts(profile_details.id));
  }, [profile_details]);

  return (
    <div>
      <PrelovedModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setModalConfig({ mode: null });
        }}
        modalConfig={modalConfig}
        dispatch={dispatch}
        addToast={addToast}
      />
      {edit === false && profile_preloved_products.length != 0 && (
        <table className="table table-responsive">
          <thead>
            <tr>
              <th>{t("Thumbnail")}</th>
              <th>{t("Product")}</th>
              <th>{t("Sold")}</th>
              <th>{t("Status")}</th>
              <th>{t("Price")}</th>
              <th>{t("Action")}</th>
            </tr>
          </thead>
          <tbody>
            {profile_preloved_products &&
              profile_preloved_products.map((product) => (
                <tr key={product.id}>
                  <td>
                    <img src={product.thumbnail} width="40px" height="40px" />
                  </td>
                  <td>{product.name}</td>
                  <td>{product.sold === 1 ? "Sold" : "In market"}</td>
                  <td style={{ color: product.status === 1 ? "#32baa5" : "red" }}>
                    {product.status === 1 ? "Enabled" : "Disabled"}
                  </td>
                  <td>RM {product.price}</td>
                  <td>
                    <AiFillDelete
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        color: "red",
                        marginRight: ".5rem",
                      }}
                      onClick={() => {
                        setModalConfig({
                          id: product.id,
                          mode: "delete",
                        });
                        setShowModal(true);
                      }}
                    />
                    <AiFillEdit
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        color: "#32baa5",
                      }}
                      onClick={() => {
                        //console.log(product);
                        setEditProduct(product);
                        setShowPrelovedTable(false);
                        setEdit(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {edit === false && profile_preloved_products.length === 0 && (
        <Row className="mt-3">
          <Col>
            <Card className="cardShadow text-center py-5">
              <Card.Body className="mx-auto">
                <img src="/assets/images/no_orders.png" />
                <p>{t("There are no product added.")}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {edit && editProduct && (
        <>
          <AddPreloved goBack={setEdit} editData={editProduct} />
        </>
      )}
    </div>
  );
}
