import { Fragment, useState, useEffect } from "react";
import Link from "next/link";
import { Col, Card, Row } from "react-bootstrap";
import ProductModal from "./elements/ProductModal";
import { ProductRating } from "../Product";
import ProductVariant from "./elements/ProductVariants";
import { FaHeart } from "react-icons/fa";
import { BsHeart } from "react-icons/bs";

const ShopProductGridList = ({
  product,
  discountedPrice,
  productPrice,
  cartItem,
  wishlistItem,
  compareItem,
  bottomSpace,
  addToWishlist,
  deleteFromWishlist,
  addToCompare,
  deleteFromCompare,
  addToast,
  cartItems,
  sliderClass,
  location,
  addCartItem,
  user,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const inCart = cartItems.filter((item) => item.product_id === product.id).length > 0;

  let href = `/shop/product/${product.id}`;

  if (product.preloved) href = `/shop/preloved/${product.id}/${product.customer_id}`;

  return (
    <Fragment>
      <Col lg={location == "home" ? 2 : 3} md={location == "home" ? 4 : 4} sm={6} xs={6}>
        <div className={`${sliderClass ? sliderClass : ""} ${bottomSpace ? bottomSpace : ""}`}>
          <Link href={href} passHref>
            <Card
              style={{
                borderRadius: "5px",
                boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.16)",
              }}
              className="h-100 border-0">
              <Card.Img
                variant="top"
                src={product.thumbnail ?? null}
                height="180px"
                style={{ borderRadius: "5px", objectFit: "cover" }}
              />

              <Card.Body>
                <Row className="product-grid__info">
                  <Col>
                    <h6 className="product-title text-truncate">
                      <Link
                        href={href}
                        // as={"/shop/product/" + product.id}
                      >
                        <a>{product.name}</a>
                      </Link>
                    </h6>
                    <div className="product-price text-primary text-truncate fw-bold">
                      {product.discount ? (
                        <Fragment>
                          <span className="price">RM {discountedPrice}</span>
                          <del>RM {productPrice}</del>
                          <span className="on-sale">{product.discount}% Off</span>
                        </Fragment>
                      ) : (
                        <>
                          {(product.type === "configurable" ||
                            product.product_type === "configurable") &&
                          product.price_range ? (
                            <>
                              {product.price_range.min === product.price_range.max ? (
                                <>
                                  <span className="price-currency">RM</span>
                                  <span className="price">{productPrice}</span>
                                </>
                              ) : (
                                <>
                                  <span className="price-currency">RM</span>
                                  <span className="price">{product.price_range.min}</span> -{" "}
                                  <span className="price-currency">RM</span>
                                  <span className="price">{product.price_range.max}</span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <span className="price-currency">RM</span>
                              <span className="price">{productPrice}</span>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    {product.rating !== null ? (
                      product.rating !== 0 ? (
                        <Row>
                          <Col xs={10} sm={10} md={10} lg={10}>
                            <div className="rating-wrap">
                              <ProductRating ratingValue={product.rating} />
                              {/* <span className="rating-num">({product.rating})</span> */}
                            </div>
                          </Col>
                          <Col className="p-0">
                            <div
                              onClick={
                                wishlistItem !== undefined
                                  ? (e) => {
                                      e.preventDefault(),
                                        deleteFromWishlist(wishlistItem.id, addToast);
                                    }
                                  : (e) => {
                                      e.preventDefault(), addToWishlist(product.id, addToast);
                                    }
                              }
                              className={`d-flex justify-content-center ${
                                wishlistItem !== undefined ? "active" : ""
                              }`}>
                              {wishlistItem === undefined ? (
                                <BsHeart color={"#32baa5"}></BsHeart>
                              ) : (
                                <FaHeart color={"#32baa5"}></FaHeart>
                              )}
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col xs={10} sm={10} md={10} lg={10}>
                            <div className="rating-wrap">
                              <ProductRating ratingValue={product.rating} />
                            </div>
                          </Col>
                          <Col className="p-0 d-flex align-items-center justify-content-center ">
                            <div
                              onClick={
                                wishlistItem !== undefined
                                  ? (e) => {
                                      e.preventDefault(),
                                        deleteFromWishlist(wishlistItem.id, addToast);
                                    }
                                  : (e) => {
                                      e.preventDefault(), addToWishlist(product.id, addToast);
                                    }
                              }
                              className={`d-flex justify-content-center ${
                                wishlistItem !== undefined ? "active" : ""
                              }`}>
                              {wishlistItem === undefined ? (
                                <BsHeart color={"#32baa5"}></BsHeart>
                              ) : (
                                <FaHeart color={"#32baa5"}></FaHeart>
                              )}
                            </div>
                          </Col>
                        </Row>
                      )
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Link>
        </div>
      </Col>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        discountedPrice={discountedPrice}
        productPrice={productPrice}
        cartitems={cartItems}
        cartitem={cartItem}
        wishlistitem={wishlistItem}
        compareitem={compareItem}
        addtowishlist={addToWishlist}
        deletefromwishlist={deleteFromWishlist}
        addtocompare={addToCompare}
        deletefromcompare={deleteFromCompare}
        addtoast={addToast}
        addCartItem={addCartItem}
        user={user}
      />
    </Fragment>
  );
};

export default ShopProductGridList;
