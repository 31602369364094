import { useEffect, useState } from "react";
import { getToken } from "../../redux/actions/utils";
import { ProductRating } from "../Product/index";
import { Row, Col, Card } from "react-bootstrap";
import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import moment from "moment";
import parse from "html-react-parser";
import { useTranslation } from "next-i18next";

const ProductDescriptionTab = ({ product }) => {
  const [usersReviews, setUersReviews] = useState(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    const token = getToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    if (product) {
      defaultAxios
        .get("rating/" + product.id.toString(), config)
        .then((response) => {
          console.log("###################", response.data.data);
          setUersReviews(response.data.data);
        })
        .catch((err) => catchError(err));
    }
  }, [product]);
  return (
    <div className="product-description-tab space-pb--50">
      <Row className="mb-3">
        <Col>
          <h4 className="text-primary fw-bold text-underline">{t("Product Description")}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="product-description-tab__details">{parse(product.description)}</div>
        </Col>
      </Row>

      {!product.preloved && (
        <div className="product-description-tab__review">
          <div className="comments">
            <h4 className="product-tab-title text-primary fw-bold my-4">
              {t("Reviews For")} <span>{product.name}</span>
            </h4>
            {usersReviews &&
              usersReviews.review &&
              usersReviews.review.data.length > 0 &&
              usersReviews.review.data.map((rev) => (
                <Row key={rev.id} className="mt-3">
                  <Col>
                    <Card className="border-0 shadow-sm">
                      <Card.Body>
                        <div className="d-flex">
                          {rev?.customer?.avatar && (
                            <div className="flex-shrink-0">
                              <img
                                src={rev?.customer?.avatar}
                                alt="user"
                                width="70px"
                                height="70px"
                                className="rounded-circle"
                              />
                            </div>
                          )}
                          <div className="flex-grow-1 ms-3">
                            <Row>
                              <p className="customer-meta">
                                <span className="review-author">
                                  {rev.anonymous === 0 ? rev?.customer?.name : "Anonymous"}
                                </span>
                                <span className="comment-date">
                                  {moment(rev.created_at ?? "15-12-2021").format("DD MMM YYYY")}
                                </span>
                              </p>
                              <div className="description">
                                <p>{rev.review}</p>
                              </div>
                              <div className="rating-wrap">
                                <div className="rating">
                                  <ProductRating ratingValue={rev.rating} />
                                </div>
                              </div>
                            </Row>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              ))}
            {usersReviews == null && (
              <Row className="my-3">
                <p>{t('No reviews yet')}.</p>
              </Row>
            )}

            {/* <ul className="list-none comment-list mt-4">
              {usersReviews == null && <p>Ops. No reviews found for this product</p>}

              {usersReviews && usersReviews.review && usersReviews.review.data.length == 0 && (
                <p>Ops. No reviews found for this product</p>
              )}
              {usersReviews &&
                usersReviews.review &&
                usersReviews.review.data.length > 0 &&
                usersReviews.review.data.map((rev) => (
                  <li key={rev.id}>
                    <div className="comment-img">
                      <img src={rev.customer_avatar} alt="user" />
                    </div>
                    <div className="comment-block pb-2">
                      <div className="rating-wrap">
                        <div className="rating">
                          <ProductRating ratingValue={rev.rating} />
                        </div>
                      </div>
                      <p className="customer-meta">
                        <span className="review-author">{rev.customer_name}</span>
                        <span className="comment-date">March 5, 2020</span>
                      </p>
                      <div className="description">
                        <p>{rev.review}</p>
                      </div>
                    </div>
                  </li>
                ))}
            </ul> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDescriptionTab;
