import {
  auth,
  checkLinkedWithEmail,
  createCredential,
  createPhoneCredential,
  phoneProvider,
} from "../../firebase/firebase";
import { getTranslation } from "../../utils/helpers";
import * as actionTypes from "./actionTypes";

const initOnboarding = () => {
  return {
    type: actionTypes.INIT_ONBOARDING,
    loading: true,
  };
};

const successOnboarding = (data, currentStep) => {
  return {
    type: actionTypes.SUCCESS_ONBOARDING,
    loading: false,
    user: data,
    currentStep: currentStep + 1,
  };
};

const failOnboarding = (error) => {
  return {
    type: actionTypes.FAIL_ONBOARDING,
    loading: false,
    error: error,
  };
};

const initGetCurrentOnboarding = () => {
  return {
    type: actionTypes.INIT_GET_CURRENT_ONBOARDING,
    loading: true,
  };
};

const successGetCurrentOnboarding = (data, currentStep, completed) => {
  return {
    type: actionTypes.SUCCESS_GET_CURRENT_ONBOARDING,
    loading: false,
    user: data,
    currentStep: currentStep,
    completedOnboarding: completed,
  };
};

const failGetCurrentOnboarding = (error) => {
  return {
    type: actionTypes.FAIL_GET_CURRENT_ONBOARDING,
    loading: false,
    error: error,
  };
};

const initUpdateProfileOnboarding = () => {
  return {
    type: actionTypes.INIT_GET_CURRENT_ONBOARDING,
    loading: true,
  };
};

const successUpdateProfileOnboarding = (data, currentStep) => {
  return {
    type: actionTypes.SUCCESS_GET_CURRENT_ONBOARDING,
    loading: false,
    user: data,
    currentStep: currentStep,
    completedOnboarding: true,
  };
};

const failUpdateProfileOnboarding = (error) => {
  return {
    type: actionTypes.FAIL_GET_CURRENT_ONBOARDING,
    loading: false,
    error: error,
  };
};

const initSendVerificationCode = () => {
  return {
    type: actionTypes.INIT_SEND_VERIFICATION_CODE,
    sendingVerificationCode: true,
  };
};

const successSendVerificationCode = (data) => {
  return {
    type: actionTypes.SUCCESS_SEND_VERIFICATION_CODE,
    sendingVerificationCode: false,
    verificationCode: data,
  };
};

const failSendVerificationCode = (error) => {
  return {
    type: actionTypes.FAIL_SEND_VERIFICATION_CODE,
    sendingVerificationCode: false,
    errorSendVerification: error,
  };
};

const initBindToPhone = () => {
  return {
    type: actionTypes.INIT_BIND_TO_PHONE,
    bindingToPhone: true,
  };
};

const successBindToPhone = (data) => {
  return {
    type: actionTypes.SUCCESS_BIND_TO_PHONE,
    bindingToPhone: false,
    bindToPhone: data,
  };
};

const failBindToPhone = (error) => {
  return {
    type: actionTypes.FAIL_BIND_TO_PHONE,
    bindingToPhone: false,
    errorBindToPhone: error,
  };
};

const initUnbindPhone = () => {
  return {
    type: actionTypes.INIT_UNBIND_PHONE,
    unbinding: true,
  };
};

const successUnbindPhone = (data) => {
  return {
    type: actionTypes.SUCCESS_UNBIND_PHONE,
    unbinding: false,
    unbinded: data,
  };
};

const failUnbindPhone = (error) => {
  return {
    type: actionTypes.FAIL_UNBIND_PHONE,
    unbinding: false,
    unbindError: error,
  };
};

export const updateProfile = (photoUrl, displayName) => {
  return (dispatch) => {
    dispatch(initUpdateProfileOnboarding());

    auth.currentUser
      .updateProfile({
        displayName: displayName,
        photoURL: photoUrl,
      })
      .then(() => {
        dispatch(successUpdateProfileOnboarding(auth.currentUser, 5));
      })
      .catch((error) => {
        dispatch(failUpdateProfileOnboarding(error));
      });
  };
};

export const getCurrentOnboarding = () => {
  return async (dispatch) => {
    dispatch(initGetCurrentOnboarding());

    try {
      const linkedWithEmail = auth.currentUser
        ? await checkLinkedWithEmail(auth.currentUser.email)
        : false;

      if (!linkedWithEmail) {
        dispatch(successGetCurrentOnboarding(auth.currentUser, 1, false));
      } else if (linkedWithEmail && auth.currentUser.emailVerified === false) {
        auth.currentUser.sendEmailVerification();
        dispatch(successGetCurrentOnboarding(auth.currentUser, 2, false));
      } else if (
        linkedWithEmail &&
        (auth.currentUser.displayName === null || auth.currentUser.displayName === "")
      ) {
        dispatch(successGetCurrentOnboarding(auth.currentUser, 4, false));
      } else if (
        linkedWithEmail &&
        auth.currentUser.emailVerified === true &&
        auth.currentUser.displayName
      ) {
        dispatch(successGetCurrentOnboarding(auth.currentUser, 5, true));
      }
    } catch (e) {
      dispatch(failGetCurrentOnboarding(e));
    }
  };
};

export const onboardUser = (email, password, currentStep, addToast) => {
  return async (dispatch) => {
    dispatch(initOnboarding());

    try {
      const linkedWithEmail = await checkLinkedWithEmail(email);
      if (linkedWithEmail) {
        auth.currentUser.sendEmailVerification();
        dispatch(successOnboarding(auth.currentUser, currentStep));
      } else {
        let credential = createCredential(email, password);
        auth.currentUser
          .linkWithCredential(credential)
          .then((userCred) => {
            var user = userCred.user;
            dispatch(successOnboarding(user, currentStep));
          })
          .catch((error) => {
            const errorCode = error.code;
            let errorMessage = "Ops. Something went wrong.";
            switch (errorCode) {
              case "auth/provider-already-linked":
                errorMessage = "Email already linked. Please login.";
                break;
              case "auth/invalid-credential":
                errorMessage = "Email is invalid. Please try again.";
                break;
              case "auth/credential-already-in-use":
                errorMessage = "Credentials already exist. Please login.";
                break;
              case "auth/operation-not-allowed":
                errorMessage = "Ops. This method is not allowed";
                break;
              case "auth/invalid-email":
                errorMessage = "Email is invalid. Please try again.";
                break;
              case "auth/wrong-password":
                errorMessage = "Wrong password. Please try again.";
                break;
              case "auth/invalid-verification-code":
                errorMessage = "Verification code is invalid. Please try again.";
                break;
              case "auth/invalid-verification-id":
                errorMessage = "Verification id is invalid. Please try again.";
                break;
              case "auth/requires-recent-login":
                errorMessage =
                  "This operation is sensitive and requires recent authentication. Log in again before retrying this request.";
                break;
              default:
                errorMessage = "Ops. Something went wrong.";
            }

            addToast(errorMessage, {
              appearance: "error",
              autoDismiss: true,
            });

            dispatch(failOnboarding(error));
          });
      }
    } catch (e) {
      dispatch(failOnboarding(e));
    }
  };
};

export const sendVerificationCode = (phone, verifier, addToast) => {
  return (dispatch) => {
    dispatch(initSendVerificationCode());
    phoneProvider
      .verifyPhoneNumber(phone, verifier)
      .then(function (verificationId) {
        dispatch(successSendVerificationCode(verificationId));
      })
      .catch((e) => {
        const errorCode = e.code;
        let errorMessage = getTranslation("Ops. Something went wrong.");

        addToast(errorMessage, {
          appearance: "error",
          autoDismiss: true,
        });
        dispatch(failSendVerificationCode(e));
      });
  };
};

export const bindToPhone = (verificationCode, addToast) => {
  return (dispatch, getState) => {
    dispatch(initBindToPhone());
    const state = getState();
    const { verificationCode: verificationId } = state.onboardingData;
    if (verificationId) {
      let credential = createPhoneCredential(verificationId, verificationCode);
      auth.currentUser
        .linkWithCredential(credential)
        .then((userCred) => {
          var user = userCred.user;
          dispatch(successBindToPhone(user));
        })
        .catch((error) => {
          const errorCode = error.code;
          let errorMessage = "Ops. Something went wrong.";
          switch (errorCode) {
            case "auth/provider-already-linked":
              errorMessage = getTranslation("Phone already linked. Please login.");
              break;
            case "auth/invalid-credential":
              errorMessage = getTranslation("Phone is invalid. Please try again.");
              break;
            case "auth/credential-already-in-use":
              errorMessage = getTranslation("Credentials already exist. Please login.");
              break;
            case "auth/operation-not-allowed":
              errorMessage = getTranslation("Ops. This method is not allowed");
              break;
            case "auth/invalid-email":
              errorMessage = getTranslation("Phone is invalid. Please try again.");
              break;
            case "auth/wrong-password":
              errorMessage = getTranslation("Wrong password. Please try again.");
              break;
            case "auth/invalid-verification-code":
              errorMessage = getTranslation("Verification code is invalid. Please try again.");
              break;
            case "auth/invalid-verification-id":
              errorMessage = getTranslation("Verification id is invalid. Please try again.");
              break;
            case "auth/requires-recent-login":
              errorMessage = getTranslation(
                "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
              );
              break;
            default:
              errorMessage = getTranslation("Ops. Something went wrong.");
          }

          addToast(errorMessage, {
            appearance: "error",
            autoDismiss: true,
          });
          dispatch(failBindToPhone(error));
        });
    }
  };
};

export const unbindPhone = () => {
  return (dispatch, getState) => {
    dispatch(initUnbindPhone());
    const state = getState();
    const { user } = state.authData;

    if (user) {
      user
        .unlink("phone")
        .then(() => {
          dispatch(successUnbindPhone(true));
        })
        .catch((error) => {
          dispatch(failUnbindPhone(error));
        });
    }
  };
};
