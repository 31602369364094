import { getToken } from "./utils";
import * as actionTypes from "./actionTypes";
import { defaultAxios } from "../../utils";
import { catchError } from "../../utils/helpers";
import { getTranslation } from "../../utils/helpers";

//get cart
const initGetCart = () => {
  return {
    type: actionTypes.INIT_GET_CART,
    loading: true,
  };
};

const successGetCart = (data) => {
  const cart_items = [];
  data.forEach((vendor) => {
    vendor.cart.forEach((cartItem) => {
      const item = { ...cartItem };
      cart_items.push(item);
    });
  });

  return {
    type: actionTypes.SUCCESS_GET_CART,
    loading: false,
    data: data,
    cart_items: cart_items,
  };
};

const failGetCart = (error) => {
  return {
    type: actionTypes.FAIL_GET_CART,
    loading: false,
    error: error,
  };
};

export const getCart = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    dispatch(initGetCart());

    defaultAxios
      .get("cart", config)
      .then((response) => {
        if (response.data.messages) {
          const message = response.data.messages;
          if (message === "Cart is empty.") dispatch(successGetCart([]));
          else throw new Error(message);
        }
        if (response.data.data) dispatch(successGetCart(response.data.data));
      })
      .catch((err) => {
        dispatch(failGetCart(err.message));
        catchError(err);
      });
  };
};

//POST cart item
export const addCartItem = (data, addToast, decrease) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  if (!data.product_configurable_id) data.product_configurable_id = null;

  return (dispatch) => {
    //console.log(data);
    defaultAxios
      .post("cart/", data, config)
      .then((response) => {
        //console.log(response.data);
        let message = getTranslation("Item added to cart successfully");
        let appearance = "success";
        switch (decrease) {
          case "decrease":
            message = getTranslation("Item quantity updated succesfully.");
            appearance = "error";
            break;
          case "remove":
            message = getTranslation("Item removed from cart succesfully.");
            appearance = "error";
            break;
          default:
            break;
        }
        dispatch(getCart());

        if (addToast) {
          addToast(message, {
            appearance: appearance,
            autoDismiss: true,
          });
        }
      })
      .catch((err) => catchError(err));
  };
};

// reset cart:

export const resetCart = () => {
  return { type: actionTypes.RESET_CART };
};

//Clear cart

export const clearCart = () => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return (dispatch) => {
    defaultAxios
      .post("cart/clear", null, config)
      .then((response) => dispatch(getCart()))
      .catch((err) => catchError(err));
  };
};

//checkout:

const initCheckout = () => {
  return {
    type: actionTypes.INIT_CHECKOUT,
    checkout_loading: true,
    checkout_error: null,
  };
};

const successCheckout = (data) => {
  return {
    type: actionTypes.SUCCESS_CHECKOUT,
    checkout_loading: false,
    checkout_error: null,
    checkout_data: data,
  };
};

const failCheckout = (error) => {
  return {
    type: actionTypes.FAIL_CHECKOUT,
    checkout_loading: false,
    checkout_error: error,
    checkout_data: null,
  };
};

export const clearCheckout = () => {
  return (dispatch) => {
    dispatch(successCheckout(null));
  };
};

export const checkout = (selectedCartItems, shippingAddressId) => {
  const token = getToken();

  const config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  const formData = new FormData();

  for (var x = 0; x < selectedCartItems.length; x++) {
    formData.append("cart_id[]", selectedCartItems[x]);
  }

  if (shippingAddressId != null) {
    formData.append("shipping_address_id", shippingAddressId);
  }

  return (dispatch) => {
    dispatch(initCheckout());
    defaultAxios
      .post("cart/checkout2", formData, config)
      .then((response) => dispatch(successCheckout(response.data.data)))
      .catch((err) => {
        if (err.response) {
          dispatch(failCheckout(err.response.data.messages));
        } else {
          dispatch(failCheckout(err.message));
        }
        //dispatch(failCheckout(err.message));
        catchError(err);
      });
  };
};

//delete from cart
export const deleteFromCart = (item, addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast((getTranslation("Removed From Cart")), { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = (addToast) => {
  return (dispatch) => {
    if (addToast) {
      addToast(getTranslation("Removed All From Cart"), {
        appearance: "error",
        autoDismiss: true,
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};
