import { getToken } from "./utils";
import * as actionTypes from "./actionTypes";
import { strapiAxios } from "../../utils";
import { catchError } from "../../utils/helpers";

const initGetPrivacyPolicies = () => {
  return {
    type: actionTypes.INIT_GET_PRIVACY_POLICIES,
    loading: true,
  };
};

const successGetPrivacyPolicies = (data) => {
  return {
    type: actionTypes.SUCCESS_GET_PRIVACY_POLICIES,
    loading: false,
    privacyPolicies: data,
  };
};

const failGetPrivacyPolicies = (error) => {
  return {
    type: actionTypes.FAIL_GET_PRIVACY_POLICIES,
    loading: false,
    error: error,
  };
};

export const getPrivacyPolicies = () => {
  return (dispatch) => {
    dispatch(initGetPrivacyPolicies());

    strapiAxios
      .post("/graphql", {
        query: `query {
            policies(locale : "${global.localStorage?.lang == "ms" ? "ms-MY" : "en"}") {
              id
              title
              content
              created_at
              updated_at
            }
          }`,
      })
      .then((response) => {
        if (response.data.data.policies)
          dispatch(successGetPrivacyPolicies(response.data.data.policies));
      })
      .catch((err) => {
        dispatch(failGetPrivacyPolicies(err.message));
        catchError(err);
      });
  };
};
