import { Container, Row, Col, Carousel, Card, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addParam } from "../../redux/actions";
import { useState } from "react";
import { IoIosSearch, IoIosMenu, IoIosShuffle } from "react-icons/io";
import { useRouter } from "next/router";
import LazyLoad from "react-lazyload";

const HeroSliderFive = ({ dashboardData }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [search, setSearch] = useState("");
  const params = {
    loop: true,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    watchSlidesVisibility: true,
    effect: "fade",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev"
    // },
    // renderPrevButton: () => (
    //   <button className="swiper-button-prev ht-swiper-button-nav">
    //     <FiChevronLeft />
    //   </button>
    // ),
    // renderNextButton: () => (
    //   <button className="swiper-button-next ht-swiper-button-nav">
    //     <FiChevronRight />
    //   </button>
    // )
  };
  return (
    <Container className="my-5">
      <Row className="my-3">
        <Col>
          <div className="product-search-form product-search-form--style-two 	d-md-none">
            <form
              onSubmit={(event) => {
                event.preventDefault();
                dispatch(addParam("search", search));
                router.push("/shop/products");
              }}>
              <div className="input-group">
                <input
                  className="form-control"
                  placeholder="Search Product..."
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                />
                <button type="submit" className={`search-btn`}>
                  <IoIosSearch />
                </button>
              </div>
            </form>
          </div>
        </Col>
      </Row>
      <Row className="gx-3 gy-3">
        <Col lg={12} md={12} sm={12} xs={12}>
          <LazyLoad height={200} offset={100}>
            <Carousel interval={3000}>
              <Carousel.Item>
                <img
                  className="d-block w-100 carousel-image"
                  src={"/assets/images/banner/banner5.png"}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 carousel-image"
                  src={"/assets/images/banner/banner1.png"}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 carousel-image"
                  src={"/assets/images/banner/banner3.png"}
                  alt="Third slide"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100 carousel-image"
                  src={"/assets/images/banner/banner4.png"}
                  alt="Third slide"
                />
              </Carousel.Item>
            </Carousel>
          </LazyLoad>
        </Col>
      </Row>
    </Container>
  );
};

export default HeroSliderFive;
