import { Row, Col, Card, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import { getOrderDetailV2, getOrders } from "../../redux/actions/index";
import { getToken } from "../../redux/actions/utils";
import { defaultAxios } from "../../utils";

import "react-confirm-alert/src/react-confirm-alert.css";
import { catchError } from "../../utils/helpers";
import CancelOrder from "./CancelOrder";
import { useTranslation } from "next-i18next";
import { useToasts } from "react-toast-notifications";

const OrderCard = ({ detailToggle, vendorOrders, orderDetailType }) => {
  const [mobile, setMobile] = useState(false);

  const { profile_details } = useSelector((state) => state.profile);

  const dispatch = useDispatch();

  const { addToast } = useToasts();

  const { t } = useTranslation("common");

  useEffect(() => {
    // Update the document title using the browser API
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "SUCCESS_GET_ORDER_DETAIL",
      orderDetailLoading: false,
      orderDetail: null,
      orderDetailError: null,
    });
  }, [dispatch]);

  const updateOrderHandler = (salesOrderId) => {
    const token = getToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    defaultAxios
      .post("sales/index/complete/order", { _method: "PUT", sales_order_id: salesOrderId }, config)
      .then((response) => {
        dispatch(getOrders(orderDetailType));
      })
      .catch((err) => catchError(err));
  };

  const orderHandler = () => {
    const token = getToken();

    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    const formData = new FormData();

    formData.append("amount", JSON.stringify(vendorOrders.total_payment));
    formData.append("sales_invoice_id", JSON.stringify([vendorOrders.id]));
    formData.append("name", vendorOrders.customer_name);
    formData.append("email", vendorOrders.customer_email);
    formData.append("phone", vendorOrders.customer_phone);

    defaultAxios
      .post("sales/transactionV3/createBill", formData, config)
      .then((response) => {
        window.location.assign(response.data.data.gateway_redirect_url);
      })
      .catch((err) => catchError(err));
  };

  if (vendorOrders) {
    const { vendor_name, item, order_id, vendor_id } = vendorOrders;
    const firstItem = item.length !== 0 ? item[0] : item;
    let totalPrice = 0;
    item.forEach((i) => (totalPrice = totalPrice + i.subtotal));

    return (
      <>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Link href={"/seller/shop-products?seller=" + vendor_id ?? firstItem.vendor_id}>
                  <a className="text-truncate fw-bold mb-0 me-3">
                    {vendor_name ?? firstItem.vendor_name}
                  </a>
                </Link>
              </Col>
              <Col className="d-flex justify-content-end align-items-center text-uppercase text-primary">
                {orderDetailType == "ship"
                  ? vendorOrders.is_cancelled && vendorOrders.is_cancelled === 1
                    ? t("CANCELLED")
                    : vendorOrders.shipping_status != null
                    ? vendorOrders.shipping_status.toUpperCase()
                    : t("PENDING")
                  : orderDetailType == "complete"
                  ? vendorOrders.parcel_status != null
                    ? vendorOrders.parcel_status.toUpperCase()
                    : t("PENDING")
                  : orderDetailType == "rate"
                  ? vendorOrders.rating_status != null
                    ? vendorOrders.rating_status.toUpperCase()
                    : t("PENDING")
                  : orderDetailType == "pay"
                  ? vendorOrders.payment_status != null
                    ? vendorOrders.payment_status.toUpperCase()
                    : t("PENDING")
                  : vendorOrders.order_status != null
                  ? vendorOrders.order_status.toUpperCase()
                  : t("PENDING")}
              </Col>
            </Row>
            <Row className="my-4">
              <Col>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <img
                      src={firstItem.product_thumbnail ?? "/assets/images/no-image-placeholder.png"}
                      alt="Generic placeholder"
                      className="me-3 shadow-sm rounded"
                      style={{
                        width: "7rem",
                        height: "7rem",
                        maxHeight: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                  <div className="flex-grow-1 ms-3 justify-content-between d-flex flex-column">
                    <Row>
                      <Col>
                        <h6 className="fw-bold">{firstItem.product_name}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-between">
                        {firstItem.product_variance != null ? (
                          <span>{`Variation: ${firstItem.product_variance}`}</span>
                        ) : (
                          ""
                        )}
                        <span>x {firstItem.product_quantity}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <p className="text-primary fw-bold">
                          RM{" "}
                          {firstItem.product_price
                            ? (firstItem.product_price ?? 0.0).toFixed(2)
                            : 0.0}
                        </p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="my-2 border-top border-bottom py-3">
              <Col className="d-flex align-items-center" lg={4}>
                {item.length !== 0 ? `${item.length + " " + t("Items")}` : ""}
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <h5 className="fw-normal mb-0 me-3">{t("Order Total")}:</h5>
                <h5 className="text-primary fw-bold mb-0">
                  RM {vendorOrders.total_payment ? vendorOrders.total_payment.toFixed(2) : 0.0}
                </h5>
              </Col>
            </Row>
            <Row className="my-2">
              <Col
                className={
                  mobile == false
                    ? "d-flex justify-content-end"
                    : "d-flex flex-column align-items-end"
                }>
                {orderDetailType === "receive" && (
                  <Button
                    size={"sm"}
                    className={mobile == true ? "mt-2 text-white" : "text-white"}
                    onClick={() => updateOrderHandler(vendorOrders.id)}>
                    {t("Order Received")}
                  </Button>
                )}
                {orderDetailType === "rate" ? (
                  <button
                    className={
                      mobile == true
                        ? "mt-2 btn btn-sm btn-yellow--four"
                        : "btn btn-sm btn-yellow--four"
                    }
                    onClick={() => {
                      dispatch(getOrderDetailV2(vendorOrders.id));
                      detailToggle(vendorOrders, "rate_list");
                    }}>
                    To Rate
                  </button>
                ) : null}
                {orderDetailType === "ship" ? (
                  <Button
                    variant={"outline-danger"}
                    size={"sm"}
                    className={mobile == true ? "ms-0 mt-2" : ""}
                    onClick={() => {
                      detailToggle(vendorOrders, "cancel");
                    }}>
                    Cancel Order
                  </Button>
                ) : (
                  <></>
                )}
                {orderDetailType != "pay" ? (
                  <Button
                    variant={"primary"}
                    size={"sm"}
                    className={mobile == true ? "ms-0 mt-2 text-white" : "text-white"}
                    onClick={() => {
                      dispatch(getOrderDetailV2(vendorOrders.id));
                      detailToggle(vendorOrders, "detail");
                    }}>
                    {t("View Order Details")}
                  </Button>
                ) : (
                  <>
                    <Button
                      variant={"outline-danger"}
                      size={"sm"}
                      className={mobile == true ? "ms-0 mt-2" : ""}
                      onClick={() => {
                        detailToggle(vendorOrders, "cancel");
                      }}>
                      Cancel Order
                    </Button>
                    <Button
                      variant="primary"
                      size={"sm"}
                      className={mobile == true ? "ms-0 mt-2 text-white" : "text-white"}
                      onClick={() => {
                        orderHandler();
                      }}>
                      Pay Now
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    );
  } else return null;
};

export default OrderCard;

//REFUND BUTTON
// <>
//   <Button
//     variant={"outline-danger"}
//     size={"sm"}
//     className={mobile == true ? "ms-0 mt-2" : ""}
//     onClick={() => {
//       detailToggle(vendorOrders, "refund");
//     }}>
//     Refund
//   </Button>
// </>
