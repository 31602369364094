import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { FaCcVisa, FaCcMastercard } from "react-icons/fa";

const PaymentMethod = ({ show, handleClose, type }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {type == "card" ? "Add A New Credit Card" : "Add Bank Account"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {type == "card" ? (
          <Row>
            <Col>
              <Form>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control type="text" placeholder="Name On Card" />
                  </Col>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  as={Row}
                  controlId="formPlaintextPassword"
                >
                  <Col sm="8">
                    <Form.Control type="text" placeholder="Credit Card No." />
                  </Col>
                  <Col sm="4">
                    <Row>
                      <Col>
                        <FaCcVisa
                          size={40}
                          className="me-2 text-primary"
                        ></FaCcVisa>
                        <FaCcMastercard
                          size={40}
                          className="text-primary"
                        ></FaCcMastercard>
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Label>Expires On</Form.Label>
                    <Form.Control placeholder="MM/YY" />
                  </Col>
                  <Col>
                    <Form.Label>CVV</Form.Label>
                    <Form.Control placeholder="000" />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control type="text" placeholder="Address" />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control type="text" placeholder="Postcode" />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col lg={10}>
                    <p>
                      Save Card? <br />I acknowledge that my card information is
                      saved securely on my pcari account and One Time Password
                      (OTP) might not be required for future transactions on
                      pcari.
                    </p>
                  </Col>
                  <Col>
                    <Form.Check type="switch" id="custom-switch" size="lg" />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Form>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Full name in the bank account"
                    />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control type="text" placeholder="Account No." />
                  </Col>
                </Form.Group>
                <Form.Group className="mb-3" as={Row}>
                  <Col>
                    <Form.Control type="text" placeholder="Bank Name" />
                  </Col>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          size="sm"
          className="text-white"
          onClick={handleClose}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethod;
