import * as actionTypes from "./actionTypes";
import {
  auth,
  googleProvider,
  facebookProvider,
  twitterProvider,
  windowsProvider,
} from "../../firebase/firebase";
import { catchError, getTranslation } from "../../utils/helpers";

export const initAuth = () => {
  return {
    type: actionTypes.INIT_AUTH,
    loadingAuth: true,
  };
};

export const successAuth = (user) => {
  return {
    type: actionTypes.SUCCESS_AUTH,
    user: user,
    loadingAuth: false,
    userEmail: localStorage.getItem("user"),
    loggedOut: false,
  };
};

export const failAuth = (error) => {
  return {
    type: actionTypes.FAIL_AUTH,
    error: error,
    loadingAuth: false,
  };
};

export const successLogoutAuth = () => {
  return {
    type: actionTypes.LOGOUT_AUTH,
    user: null,
    loggedOut: true,
    userEmail: localStorage.getItem("user"),
    rememberUser: null,
  };
};

export const logoutAuth = () => {
  return (dispatch) => {
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("idToken");
        localStorage.removeItem("user");
        localStorage.removeItem("uid");
        //
        sessionStorage.removeItem("idToken");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("uid");
        dispatch(successLogoutAuth());
      })
      .catch((error) => {
        catchError(error);
      });
  };
};

export const clearError = () => {
  return {
    type: actionTypes.CLEAR_ERROR,
    error: false,
  };
};

export const startAuth = (email, password, mode, rememberMe, addToast, redirect) => {
  if (mode === "signIn") {
    return (dispatch) => {
      dispatch(initAuth());
      auth
        .signInWithEmailAndPassword(email, password)
        .then((response) => {
          const fireUser = response.user;

          if (fireUser) {
            dispatch({
              type: actionTypes.REMEMBER_USER,
              rememberUser: rememberMe,
            });
            if (rememberMe) localStorage.setItem("idToken", fireUser.getIdToken());
            else sessionStorage.setItem("idToken", fireUser.getIdToken());
            if (!fireUser.emailVerified) {
              fireUser
                .sendEmailVerification()
                .then((res) => {
                  // Email sent.
                })
                .catch(function (error) {
                  let errorTemp = error;
                });
              dispatch(
                failAuth(
                  getTranslation("Your email address is not verified, please check your email")
                )
              );
              addToast(
                getTranslation("Your email address is not verified, please check your email"),
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              dispatch(logoutAuth());
            } else redirect();
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          let errorMessage = getTranslation("Error");
          switch (errorCode) {
            case "auth/wrong-password":
              errorMessage = getTranslation("Please check your password or email.");
              break;
            case "auth/invalid-email":
              errorMessage = getTranslation("Please check your password or email.");
              break;
            case "auth/user-not-found":
              errorMessage = getTranslation("Email does not exist. Please register.");
              break;
            default:
              errorMessage = getTranslation("Error");
          }
          dispatch(failAuth(errorMessage));
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        });
    };
  }
  if (mode === "signUp") {
    return (dispatch) => {
      dispatch(initAuth());
      auth
        .createUserWithEmailAndPassword(email, password)
        .then((response) => {
          const fireUser = response.user;

          if (fireUser) {
            dispatch({
              type: actionTypes.REMEMBER_USER,
              rememberUser: rememberMe,
            });
            if (rememberMe) localStorage.setItem("idToken", fireUser.getIdToken());
            else sessionStorage.setItem("idToken", fireUser.getIdToken());

            if (!fireUser.emailVerified) {
              fireUser
                .sendEmailVerification()
                .then((res) => {
                  // Email sent.
                })
                .catch(function (error) {
                  let errorTemp = error;
                });

              dispatch(
                failAuth(
                  getTranslation("Your email address is not verified, please check your email")
                )
              );
              addToast(
                getTranslation("Your email address is not verified, please check your email"),
                {
                  appearance: "error",
                  autoDismiss: true,
                }
              );
              dispatch(logoutAuth());
            }
          }
        })
        .catch((error) => {
          const errorCode = error.code;
          let errorMessage = getTranslation("Error");
          switch (errorCode) {
            case "auth/email-already-in-use":
              errorMessage = getTranslation("Email already registered. Please login.");
              break;
            case "auth/invalid-email":
              errorMessage = getTranslation("Email is invalid. Please try again.");
              break;
            case "auth/weak-password":
              errorMessage = getTranslation("Please use a strong password.");
              break;
            default:
              errorMessage = getTranslation("Ops. Something went wrong.");
          }
          dispatch(failAuth(errorMessage));
          addToast(errorMessage, { appearance: "error", autoDismiss: true });
        });
    };
  }
  if (mode === "passwordReset") {
    return (dispatch) => {
      auth
        .sendPasswordResetEmail(email)
        .then((response) => {})
        .catch((error) => {
          catchError(error);
        });
    };
  }
  if (mode === "verifyEmail") {
    return (dispatch) => {
      console.log(mode);
    };
  }
};

export const googleAuth = (addToast, redirect) => {
  return (dispatch) => {
    auth
      .signInWithPopup(googleProvider)
      .then((response) => {
        const fireUser = response.user;
        localStorage.setItem("idToken", fireUser.getIdToken());
        //redirect()
        addToast(`${getTranslation("Sign in as") + " " + response.user.email}`, {
          appearance: "success",
          autoDismiss: true,
        });
        redirect();
      })
      .catch((error) => {
        addToast(error.message, { appearance: "error", autoDismiss: true });
      });
  };
};

export const facebookAuth = (addToast, redirect) => {
  return (dispatch) => {
    auth
      .signInWithPopup(facebookProvider)
      .then((response) => {
        const fireUser = response.user;
        localStorage.setItem("idToken", fireUser.getIdToken());
        addToast(`${getTranslation("Sign in as") + " " + response.user.email}`, {
          appearance: "success",
          autoDismiss: true,
        });

        redirect();
      })
      // .then(response => {
      //   const fireUser = response.user
      //   if (fireUser) {
      //     if (!fireUser.emailVerified) {
      //       fireUser.sendEmailVerification().then(res => {
      //         // Email sent.
      //       }).catch(function (error) {
      //         console.log(error)
      //       });

      //       dispatch(addToast('Your email address is not verified, please check your email', { appearance: 'error', autoDismiss: true }));
      //       dispatch(logoutAuth())
      //     }
      //   }
      // })
      .catch((error) => {
        addToast(error.message, { appearance: "error", autoDismiss: true });
      });
  };
};

export const twitterAuth = () => {
  return (dispatch) => {
    auth
      .signInWithPopup(twitterProvider)
      // .then(response => {
      // })
      .catch((error) => {
        console.log(error.message);
        dispatch(failAuth("Error"));
      });
  };
};

export const windowsAuth = () => {
  return (dispatch) => {
    auth
      .signInWithPopup(windowsProvider)
      // .then(response => {
      // })
      .catch((error) => {
        console.log(error.message);
        dispatch(failAuth("Error"));
      });
  };
};

export const resetPassword = (email, addToast, redirect) => {
  return (dispatch) => {
    auth
      .sendPasswordResetEmail(email)
      .then((response) => {
        redirect();
        addToast(getTranslation("Please check your email to reset your password."), {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(failAuth("Error"));
      });
  };
};
