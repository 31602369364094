import { Row, Col } from "react-bootstrap";
import ProductSliderEight from "./ProductSliderEight";

const ProductSliderEightWrapper = ({ title, products, currentPage, type }) => {
  return (
    <div className="product-slider-area space-pb--r70">
      <div className="container">
        <Row>
          <Col lg={12}>
            {/* product slider */}
            <ProductSliderEight
              title={title}
              products={products}
              currentPage={currentPage}
              type={type}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProductSliderEightWrapper;
