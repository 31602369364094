import { DateTime } from "luxon";
import * as Sentry from "@sentry/nextjs";
import localeEn from "../../public/locales/en/common.json";
import localeMs from "../../public/locales/ms/common.json";

export const cleanTimelineDate = (date) => {
  let cleanedDate = DateTime.fromSQL(date).toLocaleString(DateTime.DATETIME_FULL);

  if (cleanedDate == "Invalid DateTime") {
    cleanedDate = DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
  }
  return cleanedDate;
};

export const catchError = (err) => {
  Sentry.captureException(err);
};

export const getTranslation = (value) =>
  global.localStorage?.lang === "ms" ? localeMs[value] : localeEn[value];