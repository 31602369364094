import { useEffect, useState } from "react";
import { Card, Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import PrelovedProductCard from "./PrelovedProductCard";
import OrderDetail from "./PrelovedOrderDetail";
import { prelovedBuys, prelovedSales } from "../../redux/actions/index";
import { useTranslation } from "next-i18next";

export default function PrelovedTabs({ mode }) {
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  const { preloved_sales, preloved_buys } = useSelector((state) => state.preloved);

  const [orderDetailType, setOrderDetailType] = useState("ship");

  const [showDetails, setShowDetails] = useState(false);
  const [detailsItem, setDetailsItem] = useState(null);

  let orders_data = [];
  if (mode === "sales") orders_data = [...preloved_sales];
  else if (mode === "buys") orders_data = [...preloved_buys];

  const showOrderDetails = (item) => {
    setShowDetails(!showDetails);
    setDetailsItem(item);
  };

  useEffect(() => {
    if (mode === "sales") dispatch(prelovedSales(orderDetailType));
    if (mode === "buys") dispatch(prelovedBuys(orderDetailType));
  }, []);

  useEffect(() => {
    if (mode === "sales") dispatch(prelovedSales(orderDetailType));
    if (mode === "buys") dispatch(prelovedBuys(orderDetailType));
  }, [mode]);

  // useEffect(() => {
  //   if (mode === 'sales') orders_data = [...preloved_sales]
  //   else if (mode === 'buys') orders_data = [...preloved_buys]
  // }, [preloved_sales, preloved_buys])
  // console.log(orders_data, mode)
  return (
    <Card className="my-account-content__content orders-tab">
      {showDetails == true ? (
        <OrderDetail
          detailToggle={showOrderDetails}
          detailsItem={detailsItem}
          orderDetailType={orderDetailType}></OrderDetail>
      ) : (
        <Card.Body>
          <Tab.Container
            id="left-tabs-example"
            transition={false}
            defaultActiveKey={orderDetailType}
            onSelect={(selected) => {
              setOrderDetailType(selected);
              if (mode === "sales") dispatch(prelovedSales(selected));
              else if ((mode = "buys")) dispatch(prelovedBuys(selected));
            }}>
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="flex-row justify-content-around">
                  <Nav.Item>
                    <Nav.Link eventKey="ship">{t("To Ship")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="receive">{t("To Receive")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="complete">{t("To Rate")}</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="rate">{t("Completed")}</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="receive">
                    {orders_data.length > 0 &&
                      orders_data.map((vendorOrders) => (
                        <Row className="my-2" key={vendorOrders.id}>
                          <Col>
                            {/* <OrderCard detailToggle={showOrderDetails} vendorOrders={vendorOrders} orderDetailType={orderDetailType}></OrderCard> */}
                            <PrelovedProductCard
                              vendorOrders={vendorOrders}
                              detailToggle={showOrderDetails}
                              orderDetailType={orderDetailType}
                              mode={mode}
                            />
                          </Col>
                        </Row>
                      ))}

                    {orders_data.length == 0 && (
                      <Row className="mt-3">
                        <Col>
                          <Card className="cardShadow text-center py-5">
                            <Card.Body className="mx-auto">
                              <img src="/assets/images/no_orders.png" />
                              <p style={{ textAlign: "center !important" }}>{t("No orders yet.")}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="ship">
                    {orders_data.length > 0 &&
                      orders_data.map((vendorOrders) => {
                        return (
                          <Row className="my-2" key={vendorOrders.id}>
                            <Col>
                              {/* <OrderCard vendorOrders={vendorOrders} detailToggle={showOrderDetails}></OrderCard> */}
                              <PrelovedProductCard
                                vendorOrders={vendorOrders}
                                detailToggle={showOrderDetails}
                                orderDetailType={orderDetailType}
                                mode={mode}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    {orders_data.length == 0 && (
                      <Row className="mt-3">
                        <Col>
                          <Card className="cardShadow text-center py-5">
                            <Card.Body className="mx-auto">
                              <img src="/assets/images/no_orders.png" />
                              <p style={{ textAlign: "center !important" }}>{t("No orders yet.")}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="complete">
                    {orders_data.length > 0 &&
                      orders_data.map((vendorOrders) => (
                        <Row className="my-2" key={vendorOrders.id}>
                          <Col>
                            {/* <OrderCard vendorOrders={vendorOrders} detailToggle={showOrderDetails} orderDetailType={orderDetailType}></OrderCard> */}
                            <PrelovedProductCard
                              vendorOrders={vendorOrders}
                              detailToggle={showOrderDetails}
                              orderDetailType={orderDetailType}
                              mode={mode}
                            />
                          </Col>
                        </Row>
                      ))}
                    {orders_data.length == 0 && (
                      <Row className="mt-3">
                        <Col>
                          <Card className="cardShadow text-center py-5">
                            <Card.Body className="mx-auto">
                              <img src="/assets/images/no_orders.png" />
                              <p style={{ textAlign: "center !important" }}>{t("No orders yet.")}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="rate">
                    {orders_data.length > 0 &&
                      orders_data.map((vendorOrders) => (
                        <Row className="my-2" key={vendorOrders.id}>
                          <Col>
                            <PrelovedProductCard
                              vendorOrders={vendorOrders}
                              detailToggle={showOrderDetails}
                              orderDetailType={orderDetailType}
                              mode={mode}
                            />
                          </Col>
                        </Row>
                      ))}
                    {orders_data.length == 0 && (
                      <Row className="mt-3">
                        <Col>
                          <Card className="cardShadow text-center py-5">
                            <Card.Body className="mx-auto">
                              <img src="/assets/images/no_orders.png" />
                              <p style={{ textAlign: "center !important" }}>{t("No orders yet.")}</p>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      )}
    </Card>
  );
}
